.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.fast {
  animation-duration: .5s;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animated.flash {
  animation-name: flash;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.animated.shake {
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.animated.bounce {
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1)rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }

  10%, 20% {
    -moz-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -moz-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    -moz-transform: scale(1)rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }

  10%, 20% {
    -o-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -o-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    -o-transform: scale(1)rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale(1.1)rotate(-3deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

.animated.tada {
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top;
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }

  40% {
    -moz-transform: rotate(-10deg);
  }

  60% {
    -moz-transform: rotate(5deg);
  }

  80% {
    -moz-transform: rotate(-5deg);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }

  40% {
    -o-transform: rotate(-10deg);
  }

  60% {
    -o-transform: rotate(5deg);
  }

  80% {
    -o-transform: rotate(-5deg);
  }

  100% {
    -o-transform: rotate(0);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

.animated.swing {
  transform-origin: top;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }

  15% {
    -moz-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -moz-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -moz-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -moz-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -moz-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }

  15% {
    -o-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -o-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -o-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -o-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -o-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    -o-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    transform: translateX(20%)rotate(3deg);
  }

  45% {
    transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    transform: translateX(10%)rotate(2deg);
  }

  75% {
    transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.animated.wobble {
  animation-name: wobble;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.1);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }

  50% {
    -o-transform: scale(1.1);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animated.pulse {
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    -webkit-animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    -webkit-animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    -webkit-animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

@-moz-keyframes flip {
  0% {
    -moz-animation-timing-function: ease-out;
    -moz-transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    -moz-animation-timing-function: ease-out;
    -moz-transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    -moz-animation-timing-function: ease-in;
    -moz-transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    -moz-animation-timing-function: ease-in;
    -moz-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    -moz-animation-timing-function: ease-in;
    -moz-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

@-o-keyframes flip {
  0% {
    -o-animation-timing-function: ease-out;
    -o-transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    -o-animation-timing-function: ease-out;
    -o-transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    -o-animation-timing-function: ease-in;
    -o-transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    -o-animation-timing-function: ease-in;
    -o-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    -o-animation-timing-function: ease-in;
    -o-transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

@keyframes flip {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

.animated.flip {
  animation-name: flip;
  backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
}

@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateX(90deg);
  }

  40% {
    -webkit-transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(0);
  }
}

@-moz-keyframes flipInX {
  0% {
    opacity: 0;
    -moz-transform: perspective(400px)rotateX(90deg);
  }

  40% {
    -moz-transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    -moz-transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    -moz-transform: perspective(400px)rotateX(0);
  }
}

@-o-keyframes flipInX {
  0% {
    opacity: 0;
    -o-transform: perspective(400px)rotateX(90deg);
  }

  40% {
    -o-transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    -o-transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    -o-transform: perspective(400px)rotateX(0);
  }
}

@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }
}

.animated.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
}

@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateX(90deg);
  }
}

@-moz-keyframes flipOutX {
  0% {
    opacity: 1;
    -moz-transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: perspective(400px)rotateX(90deg);
  }
}

@-o-keyframes flipOutX {
  0% {
    opacity: 1;
    -o-transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: perspective(400px)rotateX(90deg);
  }
}

@keyframes flipOutX {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.animated.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateY(90deg);
  }

  40% {
    -webkit-transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(0);
  }
}

@-moz-keyframes flipInY {
  0% {
    opacity: 0;
    -moz-transform: perspective(400px)rotateY(90deg);
  }

  40% {
    -moz-transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    -moz-transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    -moz-transform: perspective(400px)rotateY(0);
  }
}

@-o-keyframes flipInY {
  0% {
    opacity: 0;
    -o-transform: perspective(400px)rotateY(90deg);
  }

  40% {
    -o-transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    -o-transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    -o-transform: perspective(400px)rotateY(0);
  }
}

@keyframes flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }
}

.animated.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateY(90deg);
  }
}

@-moz-keyframes flipOutY {
  0% {
    opacity: 1;
    -moz-transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: perspective(400px)rotateY(90deg);
  }
}

@-o-keyframes flipOutY {
  0% {
    opacity: 1;
    -o-transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: perspective(400px)rotateY(90deg);
  }
}

@keyframes flipOutY {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.animated.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animated.fadeIn {
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUp {
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDown {
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRight {
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animated.fadeOut {
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.animated.fadeOutUp {
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.animated.fadeOutDown {
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.animated.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.animated.fadeOutRight {
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.animated.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.animated.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.animated.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.animated.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }

  70% {
    -moz-transform: scale(.9);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }

  70% {
    -o-transform: scale(.9);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes slideOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes slideOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes slideOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes slideOutRight {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes slideOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes slideOutUp {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.animated.bounceIn {
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.animated.bounceInUp {
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }

  80% {
    -moz-transform: translateY(-10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }

  80% {
    -o-transform: translateY(-10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.animated.bounceInDown {
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }

  80% {
    -moz-transform: translateX(-10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }

  80% {
    -o-transform: translateX(-10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.animated.bounceInLeft {
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }

  80% {
    -moz-transform: translateX(10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }

  80% {
    -o-transform: translateX(10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.animated.bounceInRight {
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }
}

@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }

  25% {
    -moz-transform: scale(.95);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -moz-transform: scale(.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }

  25% {
    -o-transform: scale(.95);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -o-transform: scale(.3);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

.animated.bounceOut {
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.animated.bounceOutUp {
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.animated.bounceOutDown {
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.animated.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.animated.bounceOutRight {
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    opacity: 0;
    -webkit-transform: rotate(-200deg);
  }

  100% {
    -webkit-transform-origin: center;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center;
    opacity: 0;
    -moz-transform: rotate(-200deg);
  }

  100% {
    -moz-transform-origin: center;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center;
    opacity: 0;
    -o-transform: rotate(-200deg);
  }

  100% {
    -o-transform-origin: center;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  100% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }
}

.animated.rotateIn {
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.animated.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.animated.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.animated.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.animated.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    opacity: 1;
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform-origin: center;
    opacity: 0;
    -webkit-transform: rotate(200deg);
  }
}

@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center;
    opacity: 1;
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform-origin: center;
    opacity: 0;
    -moz-transform: rotate(200deg);
  }
}

@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center;
    opacity: 1;
    -o-transform: rotate(0);
  }

  100% {
    -o-transform-origin: center;
    opacity: 0;
    -o-transform: rotate(200deg);
  }
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(200deg);
  }
}

.animated.rotateOut {
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.animated.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.animated.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.animated.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.animated.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: 0 0;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
  }

  20%, 60% {
    -webkit-transform-origin: 0 0;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
  }

  40% {
    -webkit-transform-origin: 0 0;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
  }
}

@-moz-keyframes hinge {
  0% {
    -moz-transform-origin: 0 0;
    -moz-animation-timing-function: ease-in-out;
    -moz-transform: rotate(0);
  }

  20%, 60% {
    -moz-transform-origin: 0 0;
    -moz-animation-timing-function: ease-in-out;
    -moz-transform: rotate(80deg);
  }

  40% {
    -moz-transform-origin: 0 0;
    -moz-animation-timing-function: ease-in-out;
    -moz-transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    -moz-transform-origin: 0 0;
    -moz-animation-timing-function: ease-in-out;
    -moz-transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(700px);
  }
}

@-o-keyframes hinge {
  0% {
    -o-transform-origin: 0 0;
    -o-animation-timing-function: ease-in-out;
    -o-transform: rotate(0);
  }

  20%, 60% {
    -o-transform-origin: 0 0;
    -o-animation-timing-function: ease-in-out;
    -o-transform: rotate(80deg);
  }

  40% {
    -o-transform-origin: 0 0;
    -o-animation-timing-function: ease-in-out;
    -o-transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    -o-transform-origin: 0 0;
    -o-animation-timing-function: ease-in-out;
    -o-transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(700px);
  }
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(0);
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(80deg);
  }

  40% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(700px);
  }
}

.animated.hinge {
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0)rotate(0);
  }
}

@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0)rotate(0);
  }
}

@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0)rotate(0);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.animated.rollIn {
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%)rotate(120deg);
  }
}

@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%)rotate(120deg);
  }
}

@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(100%)rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)rotate(120deg);
  }
}

.animated.rollOut {
  animation-name: rollOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    -webkit-transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%)skewX(0);
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    -moz-transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%)skewX(0);
  }
}

@-o-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -o-transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    -o-transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0%)skewX(0);
  }
}

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }
}

.animated.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-duration: .5s;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%)skewX(-30deg);
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%)skewX(-30deg);
  }
}

@-o-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(100%)skewX(-30deg);
  }
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }
}

.animated.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-duration: .25s;
  animation-timing-function: ease-in;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0);
  }

  100% {
    -o-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ui-helper-reset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: 1.3;
  text-decoration: none;
  list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  border-collapse: collapse;
  display: table;
}

.ui-helper-clearfix:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.ui-helper-clearfix {
  min-height: 0;
  display: block;
}

* html .ui-helper-clearfix {
  height: 1%;
}

.ui-helper-zfix {
  opacity: 0;
  filter: Alpha(Opacity= 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
}

.ui-icon {
  text-indent: -99999px;
  background-repeat: no-repeat;
  display: block;
  overflow: hidden;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  z-index: 99999;
  font-size: .1px;
  display: block;
  position: absolute;
}

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  width: 100%;
  height: 7px;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  width: 100%;
  height: 7px;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  height: 100%;
  top: 0;
  right: -5px;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  height: 100%;
  top: 0;
  left: -5px;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  bottom: 1px;
  right: 1px;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  bottom: -5px;
  left: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  top: -5px;
  left: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  top: -5px;
  right: -5px;
}

.ui-selectable-helper {
  z-index: 100;
  border: 1px dotted #000;
  position: absolute;
}

.ui-widget {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.ui-widget .ui-widget {
  font-size: 13px;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: inherit;
}

.ui-widget-content {
  color: #404040;
  background: #fff;
  border: 1px solid #aaa;
}

.ui-widget-header {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  font-weight: bold;
}

.ui-widget-header a {
  color: #222;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  font-weight: normal;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  color: #555;
  text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
  text-decoration: none;
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited {
  color: #333;
  text-decoration: none;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  color: #333;
  border: 1px solid #adadad;
  font-weight: normal;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #333;
  text-decoration: none;
}

.ui-widget :active {
  outline: none;
}

.ui-state-highlight, .ui-state-error, .ui-state-default {
  border-style: solid;
  border-width: 1px;
  position: relative;
}

.ui-state-highlight p, .ui-state-error p, .ui-state-default p {
  margin: 7px 15px;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #2d6987;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a, .ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #953b39;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity= 70);
  font-weight: normal;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity= 35);
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity= 35);
}

.ui-icon {
  background-image: url("ui-icons_222222_256x240.e0268890.png");
  width: 16px;
  height: 16px;
}

.ui-widget-content .ui-icon, .ui-widget-header .ui-icon {
  background-image: url("ui-icons_222222_256x240.e0268890.png");
}

.ui-state-default .ui-icon {
  background-image: url("ui-icons_888888_256x240.40fc9f6e.png");
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon, .ui-state-active .ui-icon {
  background-image: url("ui-icons_454545_256x240.6351cd91.png");
}

.ui-state-highlight .ui-icon {
  background-image: url("ui-icons_2e83ff_256x240.67189f00.png");
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url("ui-icons_f6cf3b_256x240.c8d148ef.png");
}

.ui-icon-carat-1-n {
  background-position: 0 0;
}

.ui-icon-carat-1-ne {
  background-position: -16px 0;
}

.ui-icon-carat-1-e {
  background-position: -32px 0;
}

.ui-icon-carat-1-se {
  background-position: -48px 0;
}

.ui-icon-carat-1-s {
  background-position: -64px 0;
}

.ui-icon-carat-1-sw {
  background-position: -80px 0;
}

.ui-icon-carat-1-w {
  background-position: -96px 0;
}

.ui-icon-carat-1-nw {
  background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
  background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -64px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-first, .ui-icon-seek-start {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-corner-top, .ui-corner-left, .ui-corner-tl {
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

.ui-corner-top, .ui-corner-right, .ui-corner-tr {
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}

.ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}

.ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}

.ui-widget-overlay {
  opacity: .3;
  filter: Alpha(Opacity= 30);
  background: #aaa;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 949 !important;
}

.ui-widget-shadow {
  opacity: .3;
  filter: Alpha(Opacity= 30);
  background: #aaa;
  border-radius: 8px;
  margin: -8px 0 0 -8px;
  padding: 8px;
}

.ui-accordion {
  width: 100%;
}

.ui-accordion .ui-accordion-li-fix {
  display: inline;
}

.ui-accordion .ui-accordion-header-active {
  border-bottom: 0 !important;
}

.ui-accordion .ui-accordion-header {
  cursor: pointer;
  min-height: 0;
  margin-top: 2px;
  padding: .5em .5em .5em .7em;
  display: block;
  position: relative;
}

.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em;
}

.ui-accordion .ui-accordion-noicons {
  padding-left: .7em;
}

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  margin-top: -8px;
  position: absolute;
  top: 50%;
  left: .5em;
}

.ui-accordion .ui-accordion-content {
  border-top: 0;
  margin-top: -3px;
  margin-bottom: 2px;
  padding: 1em 2.2em;
  display: none;
  position: relative;
  top: 1px;
  overflow: auto;
}

.ui-accordion .ui-accordion-content-active {
  display: block;
}

.ui-autocomplete {
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-button {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  border: 1px solid #ccc;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.ui-button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ui-button:focus, .ui-button:hover {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
  text-decoration: none;
}

ui-button.disabled, ui-button[disabled], fieldset[disabled] ui-button, ui-button.disabled:hover, ui-button[disabled]:hover, fieldset[disabled] ui-button:hover, ui-button.disabled:focus, ui-button[disabled]:focus, fieldset[disabled] ui-button:focus, ui-button.disabled:active, ui-button[disabled]:active, fieldset[disabled] ui-button:active, ui-button.disabled.active, ui-button[disabled].active, fieldset[disabled] ui-button.ui-state-active {
  background-color: #fff;
  border-color: #ccc;
}

.ui-btn-lg {
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.ui-btn-sm, .ui-btn-xs {
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.ui-btn-xs {
  padding: 1px 5px;
}

.ui-btn-block {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.ui-btn-block + .ui-btn-block {
  margin-top: 5px;
}

input[type="submit"].ui-btn-block, input[type="reset"].ui-btn-block, input[type="button"].ui-btn-block {
  width: 100%;
}

.ui-button-text-icon-primary .ui-button-icon-primary {
  float: left;
}

.ui-button-text-icon-primary {
  padding: 2px 7px 3px;
}

.ui-button-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.ui-button-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ui-button-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ui-button-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ui-button-inverse {
  color: #fff;
  background-color: #222;
  border-color: #080808;
}

.ui-button-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ui-button-error {
  color: #fff;
  background-color: #c43c35;
  border-color: #882a25;
}

.ui-button-primary:hover, .ui-button-success:hover, .ui-button-info:hover, .ui-button-danger:hover, .ui-button-warning:hover, .ui-button-inverse:hover {
  color: #fff;
  text-decoration: none;
}

.ui-button-primary:hover, .ui-button-primary:focus {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e;
}

.ui-button-success:hover {
  color: #fff;
  background-color: #47a447;
  border-color: #398439;
}

.ui-button-info:hover {
  color: #fff;
  background-color: #39b3d7;
  border-color: #269abc;
}

.ui-button-danger:hover {
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}

.ui-button-warning:hover {
  color: #fff;
  background-color: #ed9c28;
  border-color: #d58512;
}

.ui-button-inverse:hover {
  color: #fff;
  background-color: #363636;
  border-color: #000;
}

.ui-button-icon-only {
  width: 2.2em;
}

.ui-button-icons-only {
  width: 3.4em;
}

button.ui-button-icons-only {
  width: 3.7em;
}

.ui-button-text-icon-primary .ui-button-icon-primary.ui-icon {
  margin-top: 5px;
}

.ui-button .ui-button-text {
  line-height: normal;
  display: block;
}

.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
  text-indent: -9999px;
  padding: .4em;
  display: none;
}

.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 1em .4em 2.1em;
}

.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 2.1em .4em 1em;
}

.ui-button-text-icons .ui-button-text {
  padding-left: 2.1em;
  padding-right: 2.1em;
}

input.ui-button {
  padding: .4em 1em;
}

.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon {
  margin-top: 0;
  margin-bottom: 0;
  top: 50%;
}

.ui-button-icon-only .ui-icon {
  margin-left: -8px;
  margin-right: -6px;
  left: 50%;
}

.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary {
  left: .5em;
}

.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
  right: .5em;
}

.ui-buttonset {
  margin-right: 7px;
}

.ui-buttonset .ui-state-active {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.ui-buttonset .ui-state-active.ui-state-hover {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e;
}

.ui-buttonset .ui-button {
  margin-left: 0;
  margin-right: -.4em;
}

button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ui-menu {
  outline: none;
  margin: 0;
  padding: 2px;
  list-style: none;
  display: block;
}

.ui-menu .ui-menu {
  margin-top: -3px;
  list-style: none;
  position: absolute;
}

.ui-menu .ui-menu-item {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

.ui-menu .ui-menu-divider {
  border-width: 1px 0 0;
  height: 0;
  margin: 5px -2px;
  font-size: 0;
  line-height: 0;
}

.ui-menu .ui-menu-item a {
  min-height: 0;
  padding: 2px .4em;
  font-weight: normal;
  line-height: 1.5;
  text-decoration: none;
  display: block;
}

.ui-menu .ui-menu-item a.ui-corner-all {
  border-radius: 0;
}

.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active, .ui-menu .ui-menu-item a.ui-widget-content {
  color: #fff;
  white-space: nowrap;
  background-color: #428bca;
  border-color: #357ebd;
  margin: 0;
  font-weight: bold;
  display: block;
}

.ui-menu .ui-menu-item a.ui-state-active {
  padding: 1px .4em;
}

.ui-menu .ui-state-disabled {
  margin: .4em 0 .2em;
  font-weight: normal;
  line-height: 1.5;
}

.ui-menu .ui-state-disabled a {
  cursor: default;
}

.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item a {
  padding-left: 2em;
  position: relative;
}

.ui-menu .ui-icon {
  position: absolute;
  top: .2em;
  left: .2em;
}

.ui-menu .ui-menu-icon {
  float: right;
  position: static;
}

.ui-menu {
  width: 200px;
  margin-bottom: 2em;
}

.ui-spinner {
  vertical-align: middle;
  padding: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ui-spinner-input {
  vertical-align: middle;
  background: none;
  border: none;
  margin: .2em 22px .2em .4em;
  padding: 0;
}

.ui-spinner-button {
  text-align: center;
  cursor: default;
  width: 16px;
  height: 50%;
  margin: 0;
  padding: 0;
  font-size: .5em;
  display: block;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.ui-spinner a.ui-spinner-button {
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.ui-spinner .ui-icon {
  margin-top: -8px;
  position: absolute;
  top: 50%;
  left: 0;
}

.ui-spinner-up {
  top: 0;
}

.ui-spinner-down {
  bottom: 0;
}

.ui-spinner .ui-icon-triangle-1-s {
  background-position: -65px -16px;
}

.ui-dialog {
  z-index: 1050;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0000004d;
  border-radius: 6px;
  outline: none;
  width: 300px;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 3px 7px #0000004d;
}

.ui-dialog .ui-dialog-titlebar {
  border: 0 0 0 1px solid;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-color: #fff #fff #ccc;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 15px;
  font-size: 18px;
  text-decoration: none;
  position: relative;
}

.ui-dialog .ui-dialog-title {
  float: left;
  color: #404040;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  font-weight: bold;
  overflow: hidden;
}

.ui-dialog .ui-dialog-titlebar-close {
  text-shadow: 0 1px #fff;
  filter: alpha(opacity= 25);
  -khtml-opacity: .25;
  -moz-opacity: .25;
  opacity: .25;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
  border: none;
  width: 19px;
  height: 18px;
  margin: -20px 0 0;
  padding: 1px;
  font-size: 20px;
  font-weight: bold;
  line-height: 13.5px;
  position: absolute;
  top: 50%;
  right: .3em;
}

.ui-dialog .ui-dialog-titlebar-close span {
  text-indent: 9999px;
  margin: 1px;
  display: block;
}

.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus {
  filter: alpha(opacity= 90);
  -moz-opacity: .9;
  opacity: .9;
  padding: 1px;
}

.ui-dialog .ui-dialog-content {
  background: none;
  border: 0;
  padding: .5em 1em;
  position: relative;
  overflow: auto;
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  background-color: #fff;
  background-image: none;
  border-width: 1px 0 0;
  border-top-style: solid;
  border-top-color: #ddd;
  border-radius: 0 0 6px 6px;
  margin: .5em 0 0;
  padding: 5px 15px;
  box-shadow: inset 0 1px #fff;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}

.ui-dialog .ui-dialog-buttonpane button {
  cursor: pointer;
  margin: .5em .4em .5em 0;
}

.ui-dialog .ui-resizable-se {
  width: 14px;
  height: 14px;
  bottom: 3px;
  right: 3px;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-dialog-buttonpane .ui-dialog-buttonset .ui-button {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.ui-dialog-buttonpane .ui-dialog-buttonset .ui-button.ui-state-hover {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e;
}

.ui-dialog-buttonset .ui-button:not(:first-child) {
  cursor: pointer;
  color: #333;
  -o-transition: .1s linear all;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: all .1s linear;
  display: inline-block;
  overflow: visible;
}

.ui-dialog-buttonset .ui-button:not(:first-child) .ui-state-hover {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
  text-decoration: none;
}

.ui-dialog-buttonset .ui-button.ui-button-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ui-dialog-buttonset .ui-button.ui-button-info.ui-state-hover {
  color: #fff;
  background-color: #39b3d7;
  border-color: #269abc;
}

.ui-dialog-buttonset .ui-button.ui-button-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ui-dialog-buttonset .ui-button.ui-button-success.ui-state-hover {
  color: #fff;
  background-color: #47a447;
  border-color: #398439;
}

.ui-dialog-buttonset .ui-button.ui-button-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ui-dialog-buttonset .ui-button.ui-button-warning.ui-state-hover {
  color: #fff;
  background-color: #ed9c28;
  border-color: #d58512;
}

.ui-dialog-buttonset .ui-button.ui-button-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ui-dialog-buttonset .ui-button.ui-button-danger.ui-state-hover {
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}

.ui-dialog-buttonset .ui-button.ui-button-inverse {
  color: #fff;
  background-color: #222;
  border-color: #080808;
}

.ui-dialog-buttonset .ui-button.ui-button-inverse.ui-state-hover {
  color: #fff;
  background-color: #363636;
  border-color: #000;
}

.ui-slider {
  text-align: left;
  position: relative;
}

.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider .ui-slider-handle {
  z-index: 2;
  cursor: default;
  width: 16px;
  height: 18px;
  position: absolute;
}

.ui-slider .ui-slider-range {
  z-index: 1;
  color: #fff;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff149bdf", endColorstr= "#ff0480be", GradientType= 0);
  box-sizing: border-box;
  -o-transition: width .6s ease;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-color: #0e90d2;
  background-image: linear-gradient(#149bdf, #0480be);
  background-position: 0 0;
  background-repeat: repeat-x;
  border: 0;
  font-size: .7em;
  transition: width .6s;
  display: block;
  position: absolute;
  box-shadow: inset 0 -1px #00000026;
}

.ui-slider-horizontal {
  background: #d5d5d5;
  border: none;
  height: 10px;
}

.ui-slider-horizontal .ui-slider-handle {
  background-color: #fff;
  border-color: #ccc;
  margin-left: -.6em;
  top: -.3em;
}

.ui-slider-horizontal .ui-slider-handle:focus {
  background-color: #fff;
  border-color: #357ebd;
}

.ui-slider-horizontal .ui-slider-handle:hover {
  background-color: #fff;
  border-color: #285e8e;
  outline: 0;
}

.ui-slider-horizontal .ui-slider-handle:active {
  background-color: #428bca;
  border-color: #3276b1;
}

.ui-slider-horizontal .ui-slider-range {
  height: 100%;
  top: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  margin-bottom: -.6em;
  margin-left: 0;
  left: -.3em;
}

.ui-slider-vertical .ui-slider-range {
  width: 100%;
  left: 0;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-tabs {
  border: 0;
  border-radius: 0;
  position: relative;
}

.ui-tabs .ui-tabs-nav {
  background: none;
  border: 0 solid #ddd;
  border-bottom-width: 1px;
  border-radius: 0;
  margin-bottom: 5px;
}

.ui-tabs .ui-tabs-nav li {
  float: left;
  white-space: nowrap;
  background: none;
  border: 0;
  margin-bottom: -1px;
  margin-right: 2px;
  list-style: none;
  position: relative;
  top: 0;
}

.ui-tabs-nav .ui-state-default {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
}

.ui-tabs .ui-tabs-nav li a {
  float: left;
  color: #0069d6;
  background: none;
  border: 1px solid #fff;
  border-bottom-color: #ddd;
  border-radius: 4px 4px 0 0;
  outline: none;
  padding: 8px 12px;
  font-weight: normal;
  text-decoration: none;
}

.ui-tabs .ui-tabs-nav li a:hover {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-bottom-color: #ddd;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #fff;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active:hover {
  cursor: text;
  background: #fff;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-tabs-loading a {
  cursor: text;
}

.ui-tabs .ui-tabs-panel {
  background: none;
  border: 0;
  border-radius: 0;
  margin: 1em 0;
  padding: 1px 0;
  display: block;
}

.ui-tabs .ui-tabs-hide {
  display: none !important;
}

.ui-tabs .ui-tabs-nav li {
  filter: none;
}

.ui-tooltip {
  opacity: .8;
  visibility: visible;
  z-index: 1024;
  color: #fff;
  text-align: center;
  background: #000;
  border: 1px solid #000;
  border-radius: 4px;
  max-width: 200px;
  padding: 3px 8px;
  font-size: 11px;
  text-decoration: none;
  display: block;
  position: absolute;
  box-shadow: inset 0 1px #000;
}

body .ui-tooltip {
  border-width: 1px;
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  color: gray;
  background-color: #f5f5f5;
  border: 0;
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
  position: relative;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  width: 1.8em;
  height: 1.8em;
  position: absolute;
  top: 2px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  margin-top: -8px;
  margin-left: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ui-datepicker .ui-datepicker-title {
  text-align: center;
  margin: 0 2.3em;
  line-height: 1.8em;
}

.ui-datepicker .ui-datepicker-title select {
  margin: 1px 0;
  font-size: 1em;
}

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 49%;
}

.ui-datepicker table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 .4em;
  font-size: .9em;
}

.ui-datepicker th {
  text-align: center;
  border: 0;
  padding: .7em .3em;
  font-weight: bold;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
  text-align: right;
  padding: .2em;
  text-decoration: none;
  display: block;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: .7em 0 0;
  padding: 0 .2em;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  cursor: pointer;
  width: auto;
  margin: .5em .2em .4em;
  padding: .2em .6em .3em;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  left: auto;
  right: 2px;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  left: auto;
  right: 1px;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 1px;
  border-right-width: 0;
}

.ui-datepicker-cover {
  z-index: -1;
  filter: mask();
  width: 200px;
  height: 200px;
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
}

.ui-datepicker th {
  color: gray;
  font-weight: bold;
}

.ui-datepicker-today a:hover {
  color: #fff;
  background-color: gray;
}

.ui-datepicker-today a {
  cursor: pointer;
  background-color: #bfbfbf;
  margin-bottom: 0;
  padding: 0 4px;
}

.ui-datepicker td a {
  border: 0;
  margin-bottom: 0;
}

.ui-datepicker td:hover {
  color: #fff;
}

.ui-datepicker td .ui-state-default {
  color: gray;
  text-align: center;
  filter: none;
  background: none;
  border: 0;
  margin-bottom: 0;
  padding: 5px;
}

.ui-datepicker td .ui-state-highlight {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-color: #a90329;
  border-color: #0000001a #0000001a #00000040;
  border-radius: 0;
}

.ui-datepicker td .ui-state-active {
  color: #333;
  font-size: normal;
  text-shadow: 0;
  background-color: #ebebeb;
  border-color: #0000001a #0000001a #00000040;
  border-radius: 0;
  margin-bottom: 0;
}

.ui-datepicker td .ui-state-hover {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background: #428bca;
  border-color: #0000001a #0000001a #00000040;
  border-radius: 0;
}

.ui-progressbar {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff5f5f5", endColorstr= "#fff9f9f9", GradientType= 0);
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-color: #f7f7f7;
  background-image: linear-gradient(#f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  border: 0;
  border-radius: 0;
  height: 2em;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px #0000001a;
}

.ui-progressbar .ui-progressbar-value {
  color: #fff;
  box-sizing: border-box;
  background-color: #428bca;
  height: 100%;
  margin: 0;
  transition: width .6s;
}

.ui-progressbar .ui-progressbar-overlay {
  -ms-animation: progress-bar-stripes 2s linear infinite;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
  animation: 2s linear infinite progress-bar-stripes;
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}

.ui-toolbar {
  background-color: #f5f5f5;
  background-image: -o-linear-gradient(top, #fff, #f5f5f5);
  background-repeat: repeat-x;
  background-image: linear-gradient(top, #fff, #f5f5f5);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#f5f5f5", GradientType= 0);
  background-image: -o-linear-gradient(top, #fff, #f5f5f5);
  background-image: -webkit-linear-gradient(top, #fff, #f5f5f5);
  background-image: -moz-linear-gradient(top, #fff, #f5f5f5);
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 0 0 18px;
  padding: 7px 14px;
  box-shadow: inset 0 1px #fff;
}

.customfile-button {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  border: 1px solid #ccc;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
}

div.wijmo-wijmenu {
  background-color: #222;
  background-image: -o-linear-gradient(top, #333, #222);
  background-repeat: repeat-x;
  background-image: linear-gradient(top, #333, #222);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#333333", endColorstr= "#222222", GradientType= 0);
  background-image: -o-linear-gradient(top, #333, #222);
  background-image: -webkit-linear-gradient(top, #333, #222);
  background-image: -moz-linear-gradient(top, #333, #222);
  padding: 0 20px;
  box-shadow: 0 1px 3px #00000040, inset 0 -1px #0000001a;
}

.wijmo-wijmenu .ui-state-default {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #bfbfbf;
}

.wijmo-wijmenu .ui-state-default .wijmo-wijmenu-text {
  color: #bfbfbf;
}

.wijmo-wijmenu .ui-state-hover {
  background: #ffffff0d;
}

.wijmo-wijmenu .ui-state-hover .wijmo-wijmenu-text {
  color: #fff;
}

div.wijmo-wijmenu .ui-widget-header h3 {
  margin-top: 1px;
  padding: 0;
  position: relative;
}

.wijmo-wijmenu h3 a {
  color: #fff;
  float: left;
  margin-top: 1px;
  margin-left: -20px;
  padding: 8px 20px 12px;
  font-size: 20px;
  font-weight: 200;
  line-height: 1;
  display: block;
}

.wijmo-wijmenu h3 a:hover {
  color: #fff;
  background-color: #ffffff0d;
  text-decoration: none;
}

.wijmo-wijmenu .ui-widget-header {
  border: 0;
}

.wijmo-wijmenu .wijmo-wijmenu-parent .wijmo-wijmenu-child {
  padding: .3em 0;
}

div.wijmo-wijmenu .wijmo-wijmenu-item .wijmo-wijmenu-child {
  background: #333;
  border: 0;
  border-radius: 0 0 6px 6px;
  width: 160px;
  margin: 0;
  padding: 6px 0;
  box-shadow: 0 2px 4px #0003;
}

div.wijmo-wijmenu .wijmo-wijmenu-item {
  border: 0;
  margin: 0;
}

.wijmo-wijmenu a.wijmo-wijmenu-link {
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 10px 10px 11px;
  line-height: 19px;
}

div.wijmo-wijmenu .wijmo-wijmenu-child .wijmo-wijmenu-link {
  float: none;
  width: auto;
  padding: 4px 15px;
  display: block;
}

div.wijmo-wijmenu .wijmo-wijmenu-child .wijmo-wijmenu-text {
  float: none;
}

.wijmo-wijmenu .wijmo-wijmenu-item .wijmo-wijmenu-child .ui-state-hover {
  background: #191919;
}

.wijmo-wijmenu .wijmo-wijmenu-item .wijmo-wijmenu-separator {
  background-color: #222;
  background-image: none;
  border: 0;
  border-top: 1px solid #444;
  padding: 5px 0;
}

.wijmo-wijmenu .wijmo-wijmenu-item input {
  color: #ffffffbf;
  background-color: #ffffff4d;
  border: 1px solid #111;
  border-radius: 4px;
  width: 100px;
  margin: 5px 10px 0;
  padding: 4px 9px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1;
  -moz-transition: none;
  box-shadow: inset 0 1px 2px #0000001a, 0 1px #ffffff40;
}

.wijmo-wijmenu .wijmo-wijmenu-item input:hover {
  color: #fff;
  background-color: #ffffff80;
}

.wijmo-wijmenu .wijmo-wijmenu-item input:focus {
  color: #404040;
  text-shadow: 0 1px #fff;
  background-color: #fff;
  border: 0;
  outline: 0;
  padding: 5px 10px;
  box-shadow: 0 0 3px #00000026;
}

.wijmo-wijmenu .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  text-shadow: none;
}

.wijmo-wijmenu .ui-state-default {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #bfbfbf;
  filter: none;
}

.ui-button-icon-primary.ui-icon[class^="icon-"]:before, .ui-button-icon-primary.ui-icon[class*=" icon-"]:before, .ui-button-icon-secondary.ui-icon[class^="icon-"]:before, .ui-button-icon-secondary.ui-icon[class*=" icon-"]:before {
  font-size: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: inherit;
  margin-left: 5px;
  display: inline;
  margin-left: 7px !important;
}

.ui-state-default .ui-icon[class*=" icon-"] {
  background: none;
  margin-left: -12px;
}

.flag {
  background: url("flags.f7941b1b.png") no-repeat;
  width: 16px;
  height: 11px;
  margin-top: -.18em;
  display: inline-block;
}

.flag.flag-ad {
  background-position: -16px 0;
}

.flag.flag-ae {
  background-position: -32px 0;
}

.flag.flag-af {
  background-position: -48px 0;
}

.flag.flag-ag {
  background-position: -64px 0;
}

.flag.flag-ai {
  background-position: -80px 0;
}

.flag.flag-al {
  background-position: -96px 0;
}

.flag.flag-am {
  background-position: -112px 0;
}

.flag.flag-an {
  background-position: -128px 0;
}

.flag.flag-ao {
  background-position: -144px 0;
}

.flag.flag-ar {
  background-position: -160px 0;
}

.flag.flag-as {
  background-position: -176px 0;
}

.flag.flag-at {
  background-position: -192px 0;
}

.flag.flag-au {
  background-position: -208px 0;
}

.flag.flag-aw {
  background-position: -224px 0;
}

.flag.flag-az {
  background-position: -240px 0;
}

.flag.flag-ba {
  background-position: 0 -11px;
}

.flag.flag-bb {
  background-position: -16px -11px;
}

.flag.flag-bd {
  background-position: -32px -11px;
}

.flag.flag-be {
  background-position: -48px -11px;
}

.flag.flag-bf {
  background-position: -64px -11px;
}

.flag.flag-bg {
  background-position: -80px -11px;
}

.flag.flag-bh {
  background-position: -96px -11px;
}

.flag.flag-bi {
  background-position: -112px -11px;
}

.flag.flag-bj {
  background-position: -128px -11px;
}

.flag.flag-bm {
  background-position: -144px -11px;
}

.flag.flag-bn {
  background-position: -160px -11px;
}

.flag.flag-bo {
  background-position: -176px -11px;
}

.flag.flag-br {
  background-position: -192px -11px;
}

.flag.flag-bs {
  background-position: -208px -11px;
}

.flag.flag-bt {
  background-position: -224px -11px;
}

.flag.flag-bv {
  background-position: -240px -11px;
}

.flag.flag-bw {
  background-position: 0 -22px;
}

.flag.flag-by {
  background-position: -16px -22px;
}

.flag.flag-bz {
  background-position: -32px -22px;
}

.flag.flag-ca {
  background-position: -48px -22px;
}

.flag.flag-catalonia {
  background-position: -64px -22px;
}

.flag.flag-cd {
  background-position: -80px -22px;
}

.flag.flag-cf {
  background-position: -96px -22px;
}

.flag.flag-cg {
  background-position: -112px -22px;
}

.flag.flag-ch {
  background-position: -128px -22px;
}

.flag.flag-ci {
  background-position: -144px -22px;
}

.flag.flag-ck {
  background-position: -160px -22px;
}

.flag.flag-cl {
  background-position: -176px -22px;
}

.flag.flag-cm {
  background-position: -192px -22px;
}

.flag.flag-cn {
  background-position: -208px -22px;
}

.flag.flag-co {
  background-position: -224px -22px;
}

.flag.flag-cr {
  background-position: -240px -22px;
}

.flag.flag-cu {
  background-position: 0 -33px;
}

.flag.flag-cv {
  background-position: -16px -33px;
}

.flag.flag-cw {
  background-position: -32px -33px;
}

.flag.flag-cy {
  background-position: -48px -33px;
}

.flag.flag-cz {
  background-position: -64px -33px;
}

.flag.flag-de {
  background-position: -80px -33px;
}

.flag.flag-dj {
  background-position: -96px -33px;
}

.flag.flag-dk {
  background-position: -112px -33px;
}

.flag.flag-dm {
  background-position: -128px -33px;
}

.flag.flag-do {
  background-position: -144px -33px;
}

.flag.flag-dz {
  background-position: -160px -33px;
}

.flag.flag-ec {
  background-position: -176px -33px;
}

.flag.flag-ee {
  background-position: -192px -33px;
}

.flag.flag-eg {
  background-position: -208px -33px;
}

.flag.flag-eh {
  background-position: -224px -33px;
}

.flag.flag-england {
  background-position: -240px -33px;
}

.flag.flag-er {
  background-position: 0 -44px;
}

.flag.flag-es {
  background-position: -16px -44px;
}

.flag.flag-et {
  background-position: -32px -44px;
}

.flag.flag-eu {
  background-position: -48px -44px;
}

.flag.flag-fi {
  background-position: -64px -44px;
}

.flag.flag-fj {
  background-position: -80px -44px;
}

.flag.flag-fk {
  background-position: -96px -44px;
}

.flag.flag-fm {
  background-position: -112px -44px;
}

.flag.flag-fo {
  background-position: -128px -44px;
}

.flag.flag-fr {
  background-position: -144px -44px;
}

.flag.flag-ga {
  background-position: -160px -44px;
}

.flag.flag-gb {
  background-position: -176px -44px;
}

.flag.flag-gd {
  background-position: -192px -44px;
}

.flag.flag-ge {
  background-position: -208px -44px;
}

.flag.flag-gf {
  background-position: -224px -44px;
}

.flag.flag-gg {
  background-position: -240px -44px;
}

.flag.flag-gh {
  background-position: 0 -55px;
}

.flag.flag-gi {
  background-position: -16px -55px;
}

.flag.flag-gl {
  background-position: -32px -55px;
}

.flag.flag-gm {
  background-position: -48px -55px;
}

.flag.flag-gn {
  background-position: -64px -55px;
}

.flag.flag-gp {
  background-position: -80px -55px;
}

.flag.flag-gq {
  background-position: -96px -55px;
}

.flag.flag-gr {
  background-position: -112px -55px;
}

.flag.flag-gs {
  background-position: -128px -55px;
}

.flag.flag-gt {
  background-position: -144px -55px;
}

.flag.flag-gu {
  background-position: -160px -55px;
}

.flag.flag-gw {
  background-position: -176px -55px;
}

.flag.flag-gy {
  background-position: -192px -55px;
}

.flag.flag-hk {
  background-position: -208px -55px;
}

.flag.flag-hm {
  background-position: -224px -55px;
}

.flag.flag-hn {
  background-position: -240px -55px;
}

.flag.flag-hr {
  background-position: 0 -66px;
}

.flag.flag-ht {
  background-position: -16px -66px;
}

.flag.flag-hu {
  background-position: -32px -66px;
}

.flag.flag-ic {
  background-position: -48px -66px;
}

.flag.flag-id {
  background-position: -64px -66px;
}

.flag.flag-ie {
  background-position: -80px -66px;
}

.flag.flag-il {
  background-position: -96px -66px;
}

.flag.flag-im {
  background-position: -112px -66px;
}

.flag.flag-in {
  background-position: -128px -66px;
}

.flag.flag-io {
  background-position: -144px -66px;
}

.flag.flag-iq {
  background-position: -160px -66px;
}

.flag.flag-ir {
  background-position: -176px -66px;
}

.flag.flag-is {
  background-position: -192px -66px;
}

.flag.flag-it {
  background-position: -208px -66px;
}

.flag.flag-je {
  background-position: -224px -66px;
}

.flag.flag-jm {
  background-position: -240px -66px;
}

.flag.flag-jo {
  background-position: 0 -77px;
}

.flag.flag-jp {
  background-position: -16px -77px;
}

.flag.flag-ke {
  background-position: -32px -77px;
}

.flag.flag-kg {
  background-position: -48px -77px;
}

.flag.flag-kh {
  background-position: -64px -77px;
}

.flag.flag-ki {
  background-position: -80px -77px;
}

.flag.flag-km {
  background-position: -96px -77px;
}

.flag.flag-kn {
  background-position: -112px -77px;
}

.flag.flag-kp {
  background-position: -128px -77px;
}

.flag.flag-kr {
  background-position: -144px -77px;
}

.flag.flag-kurdistan {
  background-position: -160px -77px;
}

.flag.flag-kw {
  background-position: -176px -77px;
}

.flag.flag-ky {
  background-position: -192px -77px;
}

.flag.flag-kz {
  background-position: -208px -77px;
}

.flag.flag-la {
  background-position: -224px -77px;
}

.flag.flag-lb {
  background-position: -240px -77px;
}

.flag.flag-lc {
  background-position: 0 -88px;
}

.flag.flag-li {
  background-position: -16px -88px;
}

.flag.flag-lk {
  background-position: -32px -88px;
}

.flag.flag-lr {
  background-position: -48px -88px;
}

.flag.flag-ls {
  background-position: -64px -88px;
}

.flag.flag-lt {
  background-position: -80px -88px;
}

.flag.flag-lu {
  background-position: -96px -88px;
}

.flag.flag-lv {
  background-position: -112px -88px;
}

.flag.flag-ly {
  background-position: -128px -88px;
}

.flag.flag-ma {
  background-position: -144px -88px;
}

.flag.flag-mc {
  background-position: -160px -88px;
}

.flag.flag-md {
  background-position: -176px -88px;
}

.flag.flag-me {
  background-position: -192px -88px;
}

.flag.flag-mg {
  background-position: -208px -88px;
}

.flag.flag-mh {
  background-position: -224px -88px;
}

.flag.flag-mk {
  background-position: -240px -88px;
}

.flag.flag-ml {
  background-position: 0 -99px;
}

.flag.flag-mm {
  background-position: -16px -99px;
}

.flag.flag-mn {
  background-position: -32px -99px;
}

.flag.flag-mo {
  background-position: -48px -99px;
}

.flag.flag-mp {
  background-position: -64px -99px;
}

.flag.flag-mq {
  background-position: -80px -99px;
}

.flag.flag-mr {
  background-position: -96px -99px;
}

.flag.flag-ms {
  background-position: -112px -99px;
}

.flag.flag-mt {
  background-position: -128px -99px;
}

.flag.flag-mu {
  background-position: -144px -99px;
}

.flag.flag-mv {
  background-position: -160px -99px;
}

.flag.flag-mw {
  background-position: -176px -99px;
}

.flag.flag-mx {
  background-position: -192px -99px;
}

.flag.flag-my {
  background-position: -208px -99px;
}

.flag.flag-mz {
  background-position: -224px -99px;
}

.flag.flag-na {
  background-position: -240px -99px;
}

.flag.flag-nc {
  background-position: 0 -110px;
}

.flag.flag-ne {
  background-position: -16px -110px;
}

.flag.flag-nf {
  background-position: -32px -110px;
}

.flag.flag-ng {
  background-position: -48px -110px;
}

.flag.flag-ni {
  background-position: -64px -110px;
}

.flag.flag-nl {
  background-position: -80px -110px;
}

.flag.flag-no {
  background-position: -96px -110px;
}

.flag.flag-np {
  background-position: -112px -110px;
}

.flag.flag-nr {
  background-position: -128px -110px;
}

.flag.flag-nu {
  background-position: -144px -110px;
}

.flag.flag-nz {
  background-position: -160px -110px;
}

.flag.flag-om {
  background-position: -176px -110px;
}

.flag.flag-pa {
  background-position: -192px -110px;
}

.flag.flag-pe {
  background-position: -208px -110px;
}

.flag.flag-pf {
  background-position: -224px -110px;
}

.flag.flag-pg {
  background-position: -240px -110px;
}

.flag.flag-ph {
  background-position: 0 -121px;
}

.flag.flag-pk {
  background-position: -16px -121px;
}

.flag.flag-pl {
  background-position: -32px -121px;
}

.flag.flag-pm {
  background-position: -48px -121px;
}

.flag.flag-pn {
  background-position: -64px -121px;
}

.flag.flag-pr {
  background-position: -80px -121px;
}

.flag.flag-ps {
  background-position: -96px -121px;
}

.flag.flag-pt {
  background-position: -112px -121px;
}

.flag.flag-pw {
  background-position: -128px -121px;
}

.flag.flag-py {
  background-position: -144px -121px;
}

.flag.flag-qa {
  background-position: -160px -121px;
}

.flag.flag-re {
  background-position: -176px -121px;
}

.flag.flag-ro {
  background-position: -192px -121px;
}

.flag.flag-rs {
  background-position: -208px -121px;
}

.flag.flag-ru {
  background-position: -224px -121px;
}

.flag.flag-rw {
  background-position: -240px -121px;
}

.flag.flag-sa {
  background-position: 0 -132px;
}

.flag.flag-sb {
  background-position: -16px -132px;
}

.flag.flag-sc {
  background-position: -32px -132px;
}

.flag.flag-scotland {
  background-position: -48px -132px;
}

.flag.flag-sd {
  background-position: -64px -132px;
}

.flag.flag-se {
  background-position: -80px -132px;
}

.flag.flag-sg {
  background-position: -96px -132px;
}

.flag.flag-sh {
  background-position: -112px -132px;
}

.flag.flag-si {
  background-position: -128px -132px;
}

.flag.flag-sk {
  background-position: -144px -132px;
}

.flag.flag-sl {
  background-position: -160px -132px;
}

.flag.flag-sm {
  background-position: -176px -132px;
}

.flag.flag-sn {
  background-position: -192px -132px;
}

.flag.flag-so {
  background-position: -208px -132px;
}

.flag.flag-somaliland {
  background-position: -224px -132px;
}

.flag.flag-sr {
  background-position: -240px -132px;
}

.flag.flag-ss {
  background-position: 0 -143px;
}

.flag.flag-st {
  background-position: -16px -143px;
}

.flag.flag-sv {
  background-position: -32px -143px;
}

.flag.flag-sx {
  background-position: -48px -143px;
}

.flag.flag-sy {
  background-position: -64px -143px;
}

.flag.flag-sz {
  background-position: -80px -143px;
}

.flag.flag-tc {
  background-position: -96px -143px;
}

.flag.flag-td {
  background-position: -112px -143px;
}

.flag.flag-tf {
  background-position: -128px -143px;
}

.flag.flag-tg {
  background-position: -144px -143px;
}

.flag.flag-th {
  background-position: -160px -143px;
}

.flag.flag-tj {
  background-position: -176px -143px;
}

.flag.flag-tk {
  background-position: -192px -143px;
}

.flag.flag-tl {
  background-position: -208px -143px;
}

.flag.flag-tm {
  background-position: -224px -143px;
}

.flag.flag-tn {
  background-position: -240px -143px;
}

.flag.flag-to {
  background-position: 0 -154px;
}

.flag.flag-tr {
  background-position: -16px -154px;
}

.flag.flag-tt {
  background-position: -32px -154px;
}

.flag.flag-tv {
  background-position: -48px -154px;
}

.flag.flag-tw {
  background-position: -64px -154px;
}

.flag.flag-tz {
  background-position: -80px -154px;
}

.flag.flag-ua {
  background-position: -96px -154px;
}

.flag.flag-ug {
  background-position: -112px -154px;
}

.flag.flag-um {
  background-position: -128px -154px;
}

.flag.flag-us {
  background-position: -144px -154px;
}

.flag.flag-uy {
  background-position: -160px -154px;
}

.flag.flag-uz {
  background-position: -176px -154px;
}

.flag.flag-va {
  background-position: -192px -154px;
}

.flag.flag-vc {
  background-position: -208px -154px;
}

.flag.flag-ve {
  background-position: -224px -154px;
}

.flag.flag-vg {
  background-position: -240px -154px;
}

.flag.flag-vi {
  background-position: 0 -165px;
}

.flag.flag-vn {
  background-position: -16px -165px;
}

.flag.flag-vu {
  background-position: -32px -165px;
}

.flag.flag-wales {
  background-position: -48px -165px;
}

.flag.flag-wf {
  background-position: -64px -165px;
}

.flag.flag-ws {
  background-position: -80px -165px;
}

.flag.flag-ye {
  background-position: -96px -165px;
}

.flag.flag-yt {
  background-position: -112px -165px;
}

.flag.flag-za {
  background-position: -128px -165px;
}

.flag.flag-zanzibar {
  background-position: -144px -165px;
}

.flag.flag-zm {
  background-position: -160px -165px;
}

.flag.flag-zw {
  background-position: -176px -165px;
}

.flag + span {
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 150px;
  margin-top: -3px;
  display: inline-block;
  overflow: hidden;
}

@media (width >= 768px) and (width <= 979px) {
  .flag + span {
    max-width: 30px !important;
  }
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-none {
  cursor: none;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-help {
  cursor: help;
}

.cursor-progress {
  cursor: progress;
}

.cursor-wait {
  cursor: wait;
}

.cursor-cell {
  cursor: cell;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-text {
  cursor: text;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-alias {
  cursor: alias;
}

.cursor-copy {
  cursor: copy;
}

.cursor-move {
  cursor: move;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.full-screen #fullscreen > :first-child > a, .fullscreen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5a5a5a), to(#686868));
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#5A5A5A", endColorstr= "#686868", GradientType= 0);
  color: #fff;
  background-image: -moz-linear-gradient(top, #5a5a5a 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5a5a5a), to(#686868));
  background-image: linear-gradient(#5a5a5a 0%, #686868 100%);
  border-color: #494949;
  box-shadow: inset 0 0 3px 1px #00000026;
}

.-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

.ui-widget-overlay {
  background: #aaa;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 949 !important;
}

.slider {
  margin-top: 0;
  margin-bottom: 30px;
}

.slider.slider-vertical {
  margin-bottom: 0;
  margin-right: 5px;
}

.ui-menu {
  z-index: 1;
  background: #fff;
  border: 1px solid #0003;
  width: 155px;
  padding: 2px;
  box-shadow: 0 2px 4px #1e1e6440;
}

.ui-menu .ui-state-disabled {
  cursor: default;
  color: #999 !important;
  background: none !important;
  margin: .4em 0 .2em !important;
  font-weight: normal !important;
}

.ui-menu .ui-menu-item a .ui-menu-icon {
  text-indent: 0;
  width: auto;
  height: auto;
  inset: 0 auto auto;
}

.ui-icon-carat-1-e:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  display: inline;
}

.ui-tabs .ui-tabs-nav li a {
  color: #838383;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-bottom-color: #ddd;
  border-radius: 0;
}

.ui-tabs .ui-tabs-nav li {
  margin-right: 3px;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a {
  font-weight: bold;
  box-shadow: 0 -2px #57889c;
  border-top-width: 0 !important;
  margin-top: 1px !important;
}

.ui-tabs .ui-tabs-panel {
  border: 1px solid #ddd;
  border-top: none;
  margin: 0;
  padding: 10px 13px;
}

.ui-tabs .ui-tabs-nav {
  margin-bottom: 0;
}

.ui-accordion .ui-accordion-header {
  background-color: #fbfbfb;
  font-size: 15px;
  padding: 10px 15px 10px 36px !important;
}

.ui-accordion .ui-accordion-header, .ui-accordion .ui-accordion-content {
  border-radius: 0;
}

.ui-accordion .ui-accordion-content {
  padding: 15px;
}

.ui-accordion-header-active + .ui-accordion-content {
  border-top: 1px solid #ddd;
}

.ui-accordion .ui-accordion-header {
  margin-top: -2px;
}

.ui-accordion .ui-accordion-header, .ui-accordion-content {
  border-color: #ddd !important;
}

.ui-accordion-header-icon {
  text-align: center;
  line-height: normal;
  text-indent: 0 !important;
  background-image: none !important;
  margin-top: -6px !important;
  font-size: 14px !important;
  left: 12px !important;
}

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
  text-align: center;
  margin-top: 2px;
}

.ui-datepicker-next > :first-child, .ui-datepicker-prev > :first-child {
  text-indent: 0 !important;
}

.ui-datepicker .ui-datepicker-next span, .ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}

.noUi-target * {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
}

.noUi-base {
  z-index: 1;
  border: 1px solid #bfbfbf;
  width: 100%;
  max-width: 100%;
  height: 14px;
  max-height: 100%;
  position: relative;
}

.noUi-origin-lower {
  background: #72a8d7;
}

.noUi-handle {
  cursor: default;
  background: #858585;
  border: 1px solid #fff;
  width: 16px;
  height: 30px;
  margin: -8px 0 0 -8px;
}

.noUi-handle:hover {
  background: #6c6c6c;
}

.noUi-active {
  cursor: default;
  background: #686868;
  border: none;
  width: 18px;
  height: 32px;
  margin: -9px 0 0 -8px;
  box-shadow: 1px 1px 1px #0000001a;
}

.noUi-active:after {
  box-sizing: border-box;
  content: "";
  border: 1px solid #ddd;
  height: 100%;
  display: block;
}

.noUi-connect {
  background: #3276b1;
}

.noUi-background {
  background: #eee;
  border: none;
}

.noUi-origin {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.noUi-origin-upper {
  background: inherit !important;
}

.noUi-z-index {
  z-index: 10;
}

.noUi-vertical {
  width: 10px;
  height: 100%;
}

.noUi-vertical .noUi-origin {
  bottom: 0;
  left: 0;
}

.noUi-vertical .noUi-handle {
  margin: -8px 0 0 -3px;
}

.noUi-target[disabled] .noUi-base {
  background: #999;
}

.noUi-target[disabled] .noUi-connect {
  background: #bbb;
}

.noUi-state-tap .noUi-origin {
  transition: left .3s, top .3s;
}

.ui-spinner-input {
  height: 30px;
  padding: 6px 24px 6px 12px;
  margin: 0 !important;
}

.ui-spinner-input.spinner-left {
  padding: 6px 12px 6px 24px;
}

.ui-spinner {
  width: 100%;
}

.ui-spinner-input.spinner-left + .ui-spinner-button, .ui-spinner-input.spinner-left + .ui-spinner-button + .ui-spinner-button {
  left: 0;
  right: auto;
}

.ui-spinner-up, .ui-spinner-down {
  background: #739e73;
  border-radius: 0;
}

.ui-spinner-up:hover, .ui-spinner-up:active, .ui-spinner-up:focus {
  background: #5b835b;
}

.ui-spinner-down:hover, .ui-spinner-down:active, .ui-spinner-down:focus {
  background: #77021d;
}

.ui-spinner-down {
  background: #c2032f;
}

.ui-spinner-up:before, .ui-spinner-down:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  color: #fff;
  margin-top: 1px;
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.ui-spinner-down:before {
  content: "";
}

.ui-spinner-up > :first-child, .ui-spinner-down > :first-child {
  display: none;
}

.ui-spinner-button {
  width: 19px;
}

.ui-spinner-input.spinner-both {
  padding: 6px 30px;
}

.ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
  left: 0;
  right: auto;
}

.ui-spinner-input.spinner-both + .ui-spinner-button, .ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
  width: 25px;
  height: 30px;
}

.ui-spinner-input.spinner-both + .ui-spinner-up:before, .ui-spinner-input.spinner-both + .ui-spinner-up + .ui-spinner-down:before {
  margin-top: 8px;
}

.ui-dialog {
  border: 1px solid #0003;
  box-shadow: 0 5px 15px #00000080;
}

.widget-header > :first-child {
  margin: 13px 0;
}

.ui-widget-overlay {
  z-index: 999;
}

.ui-dialog .ui-dialog-titlebar {
  background: #fff;
  border-bottom-color: #eee;
  padding: 0 10px;
}

.ui-dialog .ui-dialog-title {
  margin: 0;
}

.ui-dialog .ui-dialog-titlebar-close {
  margin-top: -16px;
  margin-right: 4px;
}

.ui-dialog-titlebar-close:before {
  content: "";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.ui-dialog .ui-dialog-buttonpane button {
  margin: 0 .4em 0 0;
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: right;
  border-top: 1px solid #eee;
  margin-top: 13px;
  padding: 19px 15px 20px;
}

.ui-autocomplete {
  z-index: 905 !important;
}

.the-price {
  background-color: #dcdcdc2b;
  margin: 0;
  padding: 20px;
  box-shadow: 0 1px #dcdcdc, inset 0 1px #fff;
}

.the-price h1 {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

.subscript {
  color: #999;
  font-size: 21px;
}

.ribbon {
  position: absolute;
  top: -4px;
  right: 9px;
}

.pricing-big {
  border: none !important;
}

.pricing-big .panel-heading {
  border-radius: 5px 5px 0 0;
}

.pricing-big .panel-title {
  text-transform: capitalize;
  text-align: center;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 20px 0;
  font-size: 18px;
  font-weight: bold;
}

.pricing-big .the-price {
  text-align: center;
  color: #fff;
  background: #333;
  padding: 20px 0;
  font-size: 24px;
  font-weight: bold;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.pricing-big .subscript {
  color: #999;
  margin-left: 5px;
  font-size: 18px;
}

.pricing-big .panel-footer {
  border-radius: 0 0 5px 5px;
  background-color: #f2f2f2 !important;
}

.pricing-big .panel-footer > div {
  padding: 15px 0 10px;
  font-size: 13px;
  display: block;
}

.pricing-big .panel-footer .btn {
  border-radius: 3px;
  padding: 15px 0;
  font-size: 14px;
  font-weight: bold;
}

.pricing-big .panel-footer .btn span {
  font-style: italic;
  font-weight: normal;
}

.pricing-big table {
  text-align: left;
  background: #fafafa;
  height: 230px;
  padding: 20px 15px;
}

.price-features {
  background: #fafafa;
  min-height: 230px;
  padding: 20px 15px;
  font-size: 13.5px;
}

.price-features li + li {
  margin-top: 10px;
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  z-index: 2000;
  -o-transition: width 1s;
  background: #3276b1;
  height: 2px;
  transition: width 1s;
  position: fixed;
  top: 0;
  left: 0;
}

.pace .pace-progress-inner {
  opacity: 1;
  width: 100px;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(3deg)translate(0, -4px);
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
}

.pace .pace-activity {
  z-index: 2000;
  -ms-animation: pace-spinner .4s linear infinite;
  border: 2px solid #0000;
  border-color: #29d #0000 #0000 #29d;
  border-radius: 10px;
  width: 14px;
  height: 14px;
  animation: .4s linear infinite pace-spinner;
  display: block;
  position: fixed;
  top: 63px;
  right: 40px;
}

#chat-container {
  z-index: 2;
  -o-transition-duration: .25s;
  background: #fff;
  border-left: 1px solid #cdcecf;
  width: 250px;
  height: 270px;
  transition-duration: .25s;
  position: absolute;
  top: 0;
  right: -251px;
}

#chat-container *, #chat-container :after, #chat-container :before {
  box-sizing: content-box;
}

#chat-container input[type="text"] {
  box-sizing: border-box;
}

#chat-container:hover, #chat-container:hover .chat-list-open-close {
  border-color: #a7a7a7;
}

#chat-container.open {
  top: 0;
  right: 0;
}

#chat-container.open .chat-list-open-close i:before {
  content: "" !important;
}

#chat-container.open .chat-list-open-close b {
  display: none;
}

#chat-container .chat-list-open-close {
  cursor: pointer;
  color: #868686;
  text-align: center;
  background: #f2f4f8;
  border: 1px solid #cdcecf;
  border-radius: 50% 0 0 50%;
  width: 25px;
  height: 25px;
  padding: 3px 1px 3px 5px;
  font-size: 21px;
  line-height: 22px;
  display: block;
  position: absolute;
  top: 7%;
  left: -32px;
  box-shadow: inset 0 .2em #0000000d;
  border-right: 1px solid #f2f4f8 !important;
}

#chat-container .chat-list-open-close b {
  text-align: center;
  vertical-align: middle;
  color: #fff;
  background: #ed1c24;
  border: 1px solid #fff;
  border-radius: 3px;
  width: 10px;
  height: 10px;
  font-size: 9px;
  font-weight: normal;
  line-height: 9px;
  position: absolute;
  top: 3px;
  right: 0;
}

#chat-container .chat-list-body {
  box-sizing: border-box;
  background: #f2f4f8;
  height: 217px;
  padding: 0;
  display: block;
  overflow: hidden scroll;
}

#chat-container #chat-users {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

#chat-container #chat-users li {
  margin-bottom: 3px;
  margin-left: 5px;
}

#chat-container #chat-users li a {
  color: #3d3d3d;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 3px 8px 3px 3px;
  font-size: 13px;
  line-height: 33px;
  display: block;
  text-decoration: none !important;
}

#chat-container #chat-users li a:hover {
  background: #e0e4ee;
}

#chat-container #chat-users li a img {
  width: 33px;
  height: auto;
  margin-right: 10px;
}

#chat-container #chat-users li a i {
  color: #acacac;
  font-size: 8px;
  font-style: normal;
  line-height: 34px;
}

#chat-container #chat-users li a i.last-online {
  letter-spacing: -1px;
  font-size: 12px;
}

#chat-container .chat-list-footer {
  box-sizing: border-box;
  border-top: 1px solid #ccc;
  padding: 10px;
  overflow: hidden;
}

#chat-container .chat-list-footer input[type="text"] {
  border: 1px solid #bababa !important;
}

#chat-container .control-group {
  padding: 0;
}

.chat-body {
  background: -o-linear-gradient(top, #fafafa 0%, #fff 100%);
  background: -ms-linear-gradient(top, #fafafa 0%, #fff 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fafafa", endColorstr= "#ffffff", GradientType= 0);
  box-sizing: border-box;
  box-shadow: inset 2px 2px 5px #0000000a;
  background: -moz-linear-gradient(top, #fafafa 0%, #fff 100%);
  background: -o-linear-gradient(top, #fafafa 0%, #fff 100%);
  background: linear-gradient(#f5fcff 0%, #fff 100%);
  border: 1px solid #fff;
  border-top: none;
  height: 270px;
  padding: 10px;
  display: block;
  overflow: hidden scroll;
  -moz-box-shadow: inset -2px -2px 5px #0000000a;
}

.chat-body ul {
  margin: 0;
  padding: 0;
}

.chat-body li.message {
  margin: 20px 20px 0;
  display: block;
  position: relative;
}

.chat-body li.message img {
  border-left: 4px solid #fff;
  display: inline-block;
  position: absolute;
}

.chat-body li.message img.online {
  border-left-color: #00a300;
}

.chat-body li.message img.offline {
  border-left-color: #ddd;
}

.chat-body li.message img.busy {
  border-left-color: #a90329;
}

.chat-body li.message img.away {
  border-left-color: #ffc40d;
}

.chat-body li.message .message-text {
  vertical-align: top;
  box-sizing: border-box;
  margin-left: 65px;
  padding: 0;
  line-height: normal;
  display: inline-block;
}

.chat-body li.message .message-text time {
  color: #afafaf;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  top: 1px;
  right: 0;
}

.chat-body li.message .message-text .chat-file {
  text-align: right;
  box-sizing: border-box;
  background: #0000000d;
  border: 1px dotted #ddd;
  border-radius: 4px;
  margin: 3px 0;
  padding: 4px;
  display: block;
}

.chat-body li.message .message-text .chat-file:nth-child(3) {
  margin-top: 15px;
}

.chat-body li.message .message-text .chat-file.row-fluid [class*="span"] {
  min-height: 10px !important;
}

.chat-body li.message .message-text .chat-file b {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  width: 250px;
  font-style: italic;
  overflow: hidden;
}

.chat-body li.message .message-text a.username {
  vertical-align: top;
  letter-spacing: -1px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  display: block;
  text-decoration: none !important;
}

.chat-body li:first-child {
  margin-top: 10px;
}

.profile-message {
  overflow: hidden;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: none !important;
  height: auto !important;
}

.profile-message li.message.message-reply {
  background: #f7f7f7;
  margin: 3px 0 0 20px;
  padding: 10px;
  margin-left: 85px !important;
}

.profile-message li.message.message-reply img {
  width: 35px !important;
}

.profile-message li.message.message-reply .message-text {
  margin-left: 45px !important;
}

.profile-message .wall-comment-reply {
  margin-bottom: 20px;
  margin-left: 85px;
}

.chat-footer {
  box-sizing: border-box;
  background: #f8f8f8e6;
  border-top: 1px solid #0000001a;
  padding: 0 10px 15px;
  position: relative;
}

.textarea-div {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
  margin: 10px 0 0;
}

.typearea {
  cursor: text;
  border-bottom: 1px solid #eee;
  max-height: 90px;
  padding: 5px 25px 5px 5px;
  position: relative;
  overflow: hidden auto;
}

.typearea textarea {
  box-sizing: border-box;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  resize: none;
  resize: none;
  outline: none;
  width: 100%;
  min-height: 61px;
  margin: 0;
  padding: 0;
  transition: height .2s;
  overflow: hidden;
  border-color: #0000 !important;
}

textarea.clone {
  visibility: hidden;
  position: absolute;
}

.textarea-controls {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  height: 43px;
  padding: 5px;
  line-height: 33px;
  display: block;
  overflow: hidden;
}

.textarea-controls > label {
  margin: 3px 10px 0;
}

.textarea-controls .smart-form .checkbox {
  padding-left: 22px !important;
}

@media (width >= 768px) and (width <= 880px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}

@media only screen and (width >= 320px) and (width <= 479px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}

@media (width <= 480px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}

.smart-form {
  color: #666;
  outline: none;
  margin: 0;
  position: relative;
}

.smart-form .btn {
  box-sizing: border-box;
}

.smart-form .radio + .radio, .smart-form .checkbox + .checkbox {
  margin-top: 0;
}

.smart-form footer {
  background: #f8f8f8e6;
  border-top: 1px solid #0000001a;
  padding: 7px 14px 15px;
  display: block;
}

.smart-form footer .btn {
  float: right;
  cursor: pointer;
  height: 31px;
  margin: 10px 0 0 5px;
  padding: 0 22px;
  font: 300 15px / 29px Open Sans, Helvetica, Arial, sans-serif;
}

.smart-form footer:after {
  content: "";
  clear: both;
  display: table;
}

.smart-form legend {
  padding-top: 15px;
}

.smart-form header {
  color: #232323;
  background: #fff;
  border-bottom: 1px dashed #0003;
  margin: 10px 14px 0;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.smart-form fieldset {
  background: #ffffffe6;
  border: none;
  padding: 25px 14px 5px;
  display: block;
  position: relative;
}

.smart-form fieldset + fieldset {
  border-top: 1px solid #0000001a;
}

.smart-form section {
  margin-bottom: 15px;
  position: relative;
}

.smart-form .label {
  color: #333;
  text-align: left;
  white-space: normal;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  display: block;
}

.smart-form .label .col {
  margin: 0;
  padding-top: 7px;
}

.smart-form .input, .smart-form .select, .smart-form .textarea, .smart-form .radio, .smart-form .checkbox, .smart-form .toggle, .smart-form .button {
  font-weight: 400;
  display: block;
  position: relative;
}

.smart-form .input input, .smart-form .select select, .smart-form .textarea textarea {
  box-sizing: border-box;
  color: #404040;
  -ms-appearance: normal;
  appearance: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  outline: none;
  width: 100%;
  height: 32px;
  padding: 5px 10px;
  font: 13px / 16px Open Sans, Helvetica, Arial, sans-serif;
  display: block;
}

.smart-form .note, .note {
  color: #999;
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
}

.note {
  margin-top: 2px;
  font-size: 10px;
}

.smart-form .note a {
  font-size: 13px;
}

.smart-form .input-file .button {
  float: none;
  height: 22px;
  margin: 0;
  padding: 0 14px;
  font-size: 13px;
  line-height: 22px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.smart-form .input-file .button:hover {
  box-shadow: none;
}

.smart-form .input-file .button input {
  cursor: pointer;
  opacity: 0;
  padding: 0;
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
}

.smart-form .select i {
  background: #fff;
  width: 5px;
  height: 11px;
  position: absolute;
  top: 10px;
  right: 11px;
  box-shadow: 0 0 0 9px #fff;
}

.smart-form .select i:after, .smart-form .select i:before {
  content: "";
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  right: 0;
}

.smart-form .select i:after {
  border-top: 4px solid #404040;
  bottom: 0;
}

.smart-form .select i:before {
  border-bottom: 4px solid #404040;
  top: 0;
}

.smart-form .select-multiple select {
  height: auto;
}

.smart-form .textarea textarea {
  resize: none;
  height: auto;
}

.smart-form .textarea-resizable textarea {
  resize: vertical;
}

.smart-form .textarea-expandable textarea {
  height: 31px;
}

.smart-form .textarea-expandable textarea:focus {
  height: auto;
}

.smart-form .radio, .smart-form .checkbox {
  color: #404040;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 4px;
  padding-left: 25px;
  font-size: 13px;
  line-height: 25px;
}

.smart-form .radio:last-child, .smart-form .checkbox:last-child {
  margin-bottom: 0;
}

.smart-form .radio input, .smart-form .checkbox input {
  position: absolute;
  left: -9999px;
}

.smart-form .radio input + i:after, .smart-form .checkbox input + i:after {
  opacity: 0;
  -o-transition: opacity .1s;
  transition: opacity .1s;
  position: absolute;
}

.smart-form .radio i, .smart-form .checkbox i {
  background: #fff;
  border-style: solid;
  border-width: 1px;
  outline: none;
  width: 17px;
  height: 17px;
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
}

.smart-form .radio i {
  border-radius: 50%;
}

.smart-form .radio input + i:after {
  content: "";
  border-radius: 50%;
  width: 9px;
  height: 9px;
  top: 3px;
  left: 3px;
}

.smart-form .checkbox input + i:after {
  content: "";
  text-align: center;
  width: 15px;
  height: 15px;
  font: bold 16px / 19px "Font Awesome 5 Pro";
  top: -1px;
  left: 0;
}

.smart-form .checkbox input:checked:hover + i:after {
  content: "";
}

.smart-form .checkbox input:checked:disabled:hover + i:after {
  content: "";
}

.smart-form .radio input:checked + i:after, .smart-form .checkbox input:checked + i:after {
  opacity: 1;
}

.smart-form .inline-group {
  margin: 0 -15px -4px 0;
}

.smart-form .inline-group:after {
  content: "";
  clear: both;
  display: table;
}

.smart-form .inline-group .radio, .smart-form .inline-group .checkbox {
  float: left;
  margin-right: 30px;
}

.smart-form .inline-group .radio:last-child, .smart-form .inline-group .checkbox:last-child {
  margin-bottom: 4px;
}

.smart-form .toggle {
  color: #404040;
  cursor: pointer;
  margin-bottom: 4px;
  padding-right: 61px;
  font-size: 15px;
  line-height: 25px;
}

.smart-form .toggle:last-child {
  margin-bottom: 0;
}

.smart-form .toggle input {
  position: absolute;
  left: -9999px;
}

.smart-form .toggle input:checked + i:after {
  content: attr(data-swchon-text);
  text-align: right;
}

.smart-form .toggle input:checked + i:before {
  right: 36px;
}

.smart-form .toggle i {
  content: "";
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  width: 49px;
  height: 17px;
  display: block;
  position: absolute;
  top: 4px;
  right: 0;
}

.smart-form .toggle i:after {
  content: attr(data-swchoff-text);
  text-align: left;
  color: #5f5f5f;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  position: absolute;
  top: 2px;
  left: 8px;
  right: 8px;
}

.smart-form .toggle i:before {
  content: "";
  z-index: 1;
  opacity: 1;
  -o-transition: right .2s;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  transition: right .2s;
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
}

.smart-form .rating {
  color: #404040;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 25px;
}

.smart-form .rating:last-child {
  margin-bottom: 0;
}

.smart-form .rating input {
  position: absolute;
  left: -9999px;
}

.smart-form .rating label {
  float: right;
  cursor: pointer;
  height: 17px;
  margin-top: 5px;
  padding: 0 2px;
  font-size: 17px;
  line-height: 17px;
  display: block;
}

.smart-form .button {
  float: right;
  color: #fff;
  cursor: pointer;
  border: 0;
  outline: none;
  height: 31px;
  margin: 10px 0 0 5px;
  padding: 0 25px;
  font: 300 15px / 31px Open Sans, Helvetica, Arial, sans-serif;
  text-decoration: none;
  overflow: hidden;
}

.smart-form .icon-append, .smart-form .icon-prepend {
  text-align: center;
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  top: 5px;
}

.smart-form .icon-append {
  border-left-style: solid;
  border-left-width: 1px;
  padding-left: 3px;
  right: 5px;
}

.smart-form .icon-prepend {
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 3px;
  left: 5px;
}

.smart-form .input .icon-prepend + input, .smart-form .textarea .icon-prepend + textarea {
  padding-left: 37px;
}

.smart-form .input .icon-append + input, .smart-form .textarea .icon-append + textarea {
  padding-right: 37px;
}

.smart-form .input .icon-prepend + .icon-append + input, .smart-form .textarea .icon-prepend + .icon-append + textarea {
  padding-left: 37px;
}

.smart-form .row {
  margin: 0 -15px;
}

.smart-form .row:after {
  content: "";
  clear: both;
  display: table;
}

.smart-form .col {
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.smart-form .col-1 {
  width: 8.33%;
}

.smart-form .col-2 {
  width: 16.66%;
}

.smart-form .col-3 {
  width: 25%;
}

.smart-form .col-4 {
  width: 33.33%;
}

.smart-form .col-5 {
  width: 41.66%;
}

.smart-form .col-6 {
  width: 50%;
}

.smart-form .col-8 {
  width: 66.67%;
}

.smart-form .col-9 {
  width: 75%;
}

.smart-form .col-10 {
  width: 83.33%;
}

@media screen and (width <= 600px) {
  .smart-form .col {
    float: none;
    width: 100%;
  }
}

.smart-form .select select {
  padding: 5px;
}

.smart-form .tooltip {
  z-index: 99999;
  color: #fff;
  opacity: 0;
  -o-transition: margin .3s, opacity .3s;
  background: #000000e6;
  padding: 2px 8px 3px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  transition: margin .3s, opacity .3s;
  position: absolute;
  left: -9999px;
}

.smart-form .tooltip:after {
  content: "";
  position: absolute;
}

.smart-form .input input:focus + .tooltip, .smart-form .textarea textarea:focus + .tooltip {
  opacity: 1;
}

.smart-form .tooltip-top-right {
  margin-bottom: 15px;
  bottom: 100%;
}

.smart-form .tooltip-top-right:after {
  border-top: 4px solid #000000e6;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 100%;
  right: 11px;
}

.smart-form .input input:focus + .tooltip-top-right, .smart-form .textarea textarea:focus + .tooltip-top-right {
  margin-bottom: 5px;
  left: auto;
  right: 0;
}

.smart-form .tooltip-top-left {
  margin-bottom: 15px;
  bottom: 100%;
}

.smart-form .tooltip-top-left:after {
  border-top: 4px solid #000000e6;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 100%;
  left: 11px;
}

.smart-form .input input:focus + .tooltip-top-left, .smart-form .textarea textarea:focus + .tooltip-top-left {
  margin-bottom: 5px;
  left: 0;
  right: auto;
}

.smart-form .tooltip-right {
  white-space: nowrap;
  margin-left: 15px;
  top: 4px;
}

.smart-form .tooltip-right:after {
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-right: 4px solid #000000e6;
  top: 6px;
  right: 100%;
}

.smart-form .input input:focus + .tooltip-right, .smart-form .textarea textarea:focus + .tooltip-right {
  margin-left: 5px;
  left: 100%;
}

.smart-form .tooltip-left {
  white-space: nowrap;
  margin-right: 15px;
  top: 4px;
}

.smart-form .tooltip-left:after {
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-left: 4px solid #000000e6;
  top: 6px;
  left: 100%;
}

.smart-form .input input:focus + .tooltip-left, .smart-form .textarea textarea:focus + .tooltip-left {
  margin-right: 5px;
  left: auto;
  right: 100%;
}

.smart-form .tooltip-bottom-right {
  margin-top: 15px;
  top: 100%;
}

.smart-form .tooltip-bottom-right:after {
  border-bottom: 4px solid #000000e6;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  bottom: 100%;
  right: 11px;
}

.smart-form .input input:focus + .tooltip-bottom-right, .smart-form .textarea textarea:focus + .tooltip-bottom-right {
  margin-top: 5px;
  left: auto;
  right: 0;
}

.smart-form .tooltip-bottom-left {
  margin-top: 15px;
  top: 100%;
}

.smart-form .tooltip-bottom-left:after {
  border-bottom: 4px solid #000000e6;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  bottom: 100%;
  left: 11px;
}

.smart-form .input input:focus + .tooltip-bottom-left, .smart-form .textarea textarea:focus + .tooltip-bottom-left {
  margin-top: 5px;
  left: 0;
  right: auto;
}

.smart-form .input input, .smart-form .select select, .smart-form .textarea textarea, .smart-form .radio i, .smart-form .checkbox i, .smart-form .toggle i, .smart-form .icon-append, .smart-form .icon-prepend {
  -o-transition: border-color .3s;
  border-color: #bdbdbd;
  transition: border-color .3s;
}

.smart-form .toggle i:before {
  background-color: #3276b1;
}

.smart-form .rating label {
  color: #ccc;
  -o-transition: color .3s;
  transition: color .3s;
}

.smart-form .button {
  opacity: .8;
  -o-transition: opacity .2s;
  background-color: #3276b1;
  transition: opacity .2s;
}

.smart-form .button.button-secondary {
  background-color: #b3b3b3;
}

.smart-form .icon-append, .smart-form .icon-prepend {
  color: #a2a2a2;
}

.smart-form .input:hover input, .smart-form .select:hover select, .smart-form .textarea:hover textarea, .smart-form .radio:hover i, .smart-form .checkbox:hover i, .smart-form .toggle:hover i {
  border-color: #5d98cc;
}

.smart-form .rating input + label:hover, .smart-form .rating input + label:hover ~ label {
  color: #3276b1;
}

.smart-form .button:hover {
  opacity: 1;
}

.smart-form .radio:hover i, .smart-form .checkbox:hover i, .smart-form .toggle:hover i {
  box-shadow: inset 0 1px 1px #0000001a;
}

.smart-form .radio:active i, .smart-form .checkbox:active i, .smart-form .toggle:active i {
  background: #f0f0f0;
  box-shadow: inset 0 1px 1px #0000001a;
}

.smart-form .input input:focus, .smart-form .select select:focus, .smart-form .textarea textarea:focus, .smart-form .radio input:focus + i, .smart-form .checkbox input:focus + i, .smart-form .toggle input:focus + i {
  border-color: #3276b1;
}

.smart-form .radio input + i:after {
  background-color: #3276b1;
}

.smart-form .checkbox input + i:after {
  color: #3276b1;
}

.smart-form .radio input:checked + i, .smart-form .checkbox input:checked + i, .smart-form .toggle input:checked + i {
  border-color: #3276b1;
}

.smart-form .rating input:checked ~ label {
  color: #3276b1;
}

.smart-form .state-error input, .smart-form .state-error select, .smart-form .state-error textarea, .smart-form .radio.state-error i, .smart-form .checkbox.state-error i, .smart-form .toggle.state-error i {
  background: #fff0f0;
  border-color: #a90329;
}

.smart-form .toggle.state-error input:checked + i {
  background: #fff0f0;
}

.smart-form .state-error + em {
  color: #d56161;
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  font-style: normal;
  line-height: 15px;
  display: block;
}

.smart-form .rating.state-error + em {
  margin-top: -4px;
  margin-bottom: 4px;
}

.smart-form .state-error select + i {
  background: #fff0f0;
  box-shadow: 0 0 0 9px #fff0f0;
}

.state-error .icon-append, .state-error .icon-prepend {
  color: #ed1c24;
}

.smart-form .state-success input, .smart-form .state-success select, .smart-form .state-success textarea, .smart-form .radio.state-success i, .smart-form .checkbox.state-success i, .smart-form .toggle.state-success i {
  background: #f0fff0;
  border-color: #7dc27d;
}

.smart-form .toggle.state-success input:checked + i {
  background: #f0fff0;
}

.smart-form .note-success {
  color: #6fb679;
}

.smart-form .state-success select + i {
  background: #f0fff0;
  box-shadow: 0 0 0 9px #f0fff0;
}

.smart-form .input.state-disabled input, .smart-form .select.state-disabled, .smart-form .textarea.state-disabled, .smart-form .radio.state-disabled, .smart-form .checkbox.state-disabled, .smart-form .toggle.state-disabled, .smart-form .button.state-disabled {
  cursor: default !important;
  opacity: .6 !important;
}

.smart-form .input.state-disabled:hover input, .smart-form .select.state-disabled:hover select, .smart-form .textarea.state-disabled:hover textarea, .smart-form .radio.state-disabled:hover i, .smart-form .checkbox.state-disabled:hover i, .smart-form .toggle.state-disabled:hover i {
  border-color: #e5e5e5 !important;
}

.smart-form .state-disabled.radio input:checked + i, .smart-form .state-disabled.checkbox input:checked + i, .smart-form .state-disabled.toggle input:checked + i, .smart-form .state-disabled.checkbox input + i:after, .smart-form .state-disabled.radio input + i:after {
  color: #333 !important;
  border-color: #e5e5e5 !important;
}

.smart-form .state-disabled.radio input + i:after {
  background-color: #333;
}

.smart-form .message {
  color: #6fb679;
  display: none;
}

.smart-form .message i {
  border: 1px solid #6fb679;
  border-radius: 50%;
  width: 81px;
  height: 81px;
  margin: 0 auto 20px;
  font-size: 30px;
  line-height: 81px;
  display: block;
}

.smart-form.submited fieldset, .smart-form.submited footer {
  display: none;
}

.smart-form.submited .message {
  text-align: center;
  background: #ffffffe6;
  padding: 25px 30px;
  font: 300 18px / 27px Open Sans, Helvetica, Arial, sans-serif;
  display: block;
}

.smart-form .ui-widget-content .ui-spinner-input {
  border: 0;
  height: 29px;
  margin: 0;
  box-shadow: none !important;
}

.smart-form-modal {
  z-index: 1;
  width: 400px;
  display: none;
  position: fixed;
}

.smart-form-modal-overlay {
  background: #000000b3;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}

.ui-timepicker-div dl {
  text-align: left;
}

.ui-timepicker-div dl dt {
  float: left;
  clear: left;
  padding: 0 0 0 5px;
}

.ui-timepicker-div dl dd {
  margin: 17px 10px 12px 40%;
}

.ui-timepicker-div td {
  font-size: 90%;
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-timepicker-rtl {
  direction: rtl;
}

.ui-timepicker-rtl dl {
  text-align: right;
  padding: 0 5px 0 0;
}

.ui-timepicker-rtl dl dt {
  float: right;
  clear: right;
}

.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}

.smart-form input.input-lg {
  height: 44px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
}

.smart-form input.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.smart-form input.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.smart-form select.input-sm {
  height: 30px;
  font-size: 12px;
  line-height: 15px;
}

.smart-form select.input-sm + i {
  height: 10px;
}

.smart-form select.input-lg {
  height: 44px;
  font-size: 17px;
  line-height: 22px;
}

.smart-form select.input-lg + i {
  height: 11px;
  top: 17px;
}

.smart-form .checkbox input + input[type="hidden"] + i:after {
  content: "";
  text-align: center;
  width: 15px;
  height: 15px;
  font: 16px / 19px "Font Awesome 5 Pro";
  top: -1px;
  left: 1px;
}

.smart-form .checkbox input:checked:hover + input[type="hidden"] + i:after {
  content: "";
}

.smart-form .checkbox input:checked:disabled:hover + input[type="hidden"] + i:after {
  content: "";
}

.smart-form .checkbox input:checked + input[type="hidden"] + i:after {
  opacity: 1;
}

.smart-form .toggle input:checked + input[type="hidden"] + i:after {
  content: attr(data-swchon-text);
  text-align: right;
}

.smart-form .toggle input:checked + input[type="hidden"] + i:before {
  right: 36px;
}

.error-box {
  max-width: 900px;
  margin: 0 auto;
}

.error-text {
  color: #fff;
  letter-spacing: -4px;
  text-shadow: 0 1px #ccc, 0 2px #bfbfbf, 0 3px #bbb, 0 4px #b9b9b9, 0 5px #aaa, 0 6px 1px #0000001a, 0 0 5px #0000001a, 0 1px 3px #0000004d, 0 3px 5px #0003, 0 5px 10px #00000040, 0 10px 10px #0003, 0 20px 20px #00000026;
  margin-bottom: 30px;
  font-size: 700%;
  font-weight: 400;
}

.error-search {
  width: 300px;
  margin: 0 auto 20px;
}

.error-icon-shadow {
  text-shadow: 0 1px #803838, 0 2px #a85d5d, 0 3px #b86565, 0 4px #d86f6f, 0 5px #b3a5a5, 0 6px 1px #8333331a, 0 0 5px #661e1e1a, 0 1px 3px #5f0f0f4d, 0 3px 5px #64171733, 0 5px 10px #721e1e40, 0 10px 10px #5e0f0f33, 0 20px 20px #79262626;
}

.display-image {
  margin-top: -60px;
  margin-right: 20px;
}

.terms-body {
  max-height: 400px;
  overflow: auto;
}

@media (width >= 768px) and (width <= 880px) {
  #extr-page #header {
    padding: 0 5px !important;
  }
}

@media only screen and (width >= 0) and (width <= 679px) {
  #extr-page #header {
    padding: 0 5px !important;
  }

  #extr-page #header #logo {
    margin-top: 22px;
  }

  #extr-page #header #logo img {
    width: 135px;
  }
}

.dd {
  max-width: 600px;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
  display: block;
  position: relative;
}

.dd-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item, .dd-empty, .dd-placeholder {
  min-height: 20px;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  display: block;
  position: relative;
}

.dd-handle {
  color: #333;
  background: #fbfbfb;
  border: 1px solid #cfcfcf;
  margin: 5px 0;
  padding: 7px 15px;
  font-size: 15px;
  text-decoration: none;
  display: block;
}

.dd-handle:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-item > button {
  cursor: pointer;
  float: left;
  text-indent: 100%;
  white-space: nowrap;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
  border: 0;
  width: 25px;
  height: 20px;
  margin: 7px 10px;
  padding: 0;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 22px !important;
}

.dd-item > button:before {
  content: "";
  text-align: center;
  text-indent: 0;
  color: #0091d9;
  width: 100%;
  display: block;
  position: absolute;
}

.dd-item > button[data-action="collapse"]:before {
  content: "";
  color: #a90329;
}

.dd-placeholder, .dd-empty {
  box-sizing: border-box;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  min-height: 30px;
  margin: 5px 0;
  padding: 0;
}

.dd-empty {
  background-color: #eee;
  background-image: linear-gradient(45deg, #fff 25%, #0000 25% 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, #0000 25% 75%, #fff 75%, #fff);
  background-position: 0 0, 30px 30px;
  background-size: 60px 60px;
  border: 1px dashed #bbb;
  min-height: 100px;
}

.dd-dragel {
  pointer-events: none;
  z-index: 9999;
  position: absolute;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px #0000001a;
}

.nestable-lists {
  clear: both;
  border: 2px solid #bfbfbf;
  border-left: 0;
  border-right: 0;
  width: 100%;
  padding: 30px 0;
  display: block;
}

#nestable-menu {
  margin: 10px 0 20px;
  padding: 0;
}

#nestable-output, #nestable2-output {
  width: 100%;
  padding: 5px;
}

@media only screen and (width >= 700px) {
  .dd {
    float: left;
    width: 100%;
  }

  .dd + .dd {
    margin-left: 2%;
  }
}

.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

.dd3-content {
  color: #333;
  background: #fbfbfb;
  border: 1px solid #cfcfcf;
  margin: 5px 0;
  padding: 6px 10px 8px 40px;
  font-size: 15px;
  text-decoration: none;
  display: block;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 38px;
}

.dd3-handle {
  cursor: move;
  text-indent: 100%;
  white-space: nowrap;
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  border: 1px solid #aaa;
  width: 30px;
  margin: 0;
  padding: 8px 15px;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dd3-handle:before {
  content: "";
  text-align: center;
  text-indent: 0;
  color: #818181;
  width: 100%;
  font-weight: normal;
  line-height: 28px;
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
}

.dd3-handle:hover:before {
  color: #c27616;
}

.dd-handle:hover + .dd-list .dd-handle, .dd-handle:hover {
  border: 1px solid #faa937;
  color: #333 !important;
  background: #fddfb3 !important;
}

.dd-handle > span {
  color: #777;
  font-size: 13px;
}

.dd-dragel > .dd-item > .dd-handle {
  border-left-width: 4px;
}

.dd-dragel > li.dd-item.dd3-item {
  z-index: 999;
  position: relative;
  top: 10px;
  left: 10px;
}

#calendar-container {
  position: relative;
}

html .fc, .fc table {
  font-size: 12px;
}

.dt-header.calender-spacer {
  height: 46px;
  display: block;
}

.jarviswidget #calendar {
  margin-top: -18px;
}

.fc-widget-header, .fc-widget-content {
  border-right: none;
}

tr.fc-first th.fc-first, tr td.fc-first {
  border-left: none;
}

tr.fc-last td {
  border-bottom: none;
}

.jarviswidget .fc-header-title h2 {
  text-shadow: 0 1px #fff;
  margin-top: -12px;
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}

.fc-event-time, .fc-event-title {
  box-sizing: border-box;
  padding: 3px 0 2px 3px;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
}

#calendar-buttons {
  position: absolute;
  top: 5px;
  right: 14px;
}

td.fc-other-month, .dropzone {
  background-image: -moz-linear-gradient(135deg, #00000008 25%, #0000 25% 50%, #00000008 50% 75%, #0000 75%, #0000);
  background-image: -ms-linear-gradient(135deg, #00000008 25%, transparent 25%, transparent 50%, #00000008 50%, #00000008 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(135deg, #00000008 25%, #0000 25% 50%, #00000008 50% 75%, #0000 75%, #0000);
  background-color: #fafcfd;
  background-image: linear-gradient(135deg, #00000008 25%, #0000 25% 50%, #00000008 50% 75%, #0000 75%, #0000);
  background-size: 16px 16px;
}

.fc-corner-right .fc-event-inner {
  padding-right: 15px;
}

.btn-select-tick i {
  display: none;
}

.btn-select-tick .btn:hover i {
  opacity: .3;
  display: block;
}

.btn-select-tick .active i {
  display: block;
  opacity: 1 !important;
}

#external-events > li {
  margin: 6px 4px 6px 0;
  display: inline-block;
}

#external-events > li > :first-child {
  cursor: move;
  padding: 5px 10px 10px;
  display: block;
}

#external-events > li > :first-child:after {
  color: #ffffffb3;
  content: attr(data-description);
  text-transform: lowercase;
  margin: 7px 0;
  font-size: 11px;
  font-weight: 400;
  line-height: 0;
  display: block;
}

.table-wrap {
  overflow: auto;
}

.inbox-body {
  position: relative;
}

.inbox-body .table-wrap {
  background: #fff;
  margin-left: 200px;
  padding: 10px 14px 7px;
  position: relative;
  overflow-x: hidden;
}

.inbox-body.no-content-padding {
  background: #fff;
  margin-top: 0;
  overflow: hidden;
}

.inbox-nav-bar {
  background: #fff;
  height: 70px;
  margin-bottom: 0;
  padding: 20px 14px;
}

.inbox-nav-bar .page-title {
  vertical-align: middle;
  width: 196px;
  margin: 0;
  line-height: 33px;
  display: inline-block;
}

.inbox-footer {
  position: absolute;
  bottom: -53px;
}

.inbox-paging, .inbox-footer .btn-group {
  margin-left: 10px;
}

#inbox-table {
  border-top: none;
  font-size: 13px;
  border-left-color: #0000 !important;
  border-right-color: #0000 !important;
}

#inbox-table tbody tr:hover {
  cursor: pointer;
  background: #e4e4e4;
}

#inbox-table tr td {
  border-left: none;
  border-right: none;
  line-height: 26px;
  padding: 6px 4px 7px !important;
}

#inbox-table .inbox-table-icon {
  padding-left: 15px !important;
}

#inbox-table tbody tr th {
  overflow: hidden;
}

#inbox-table.table tbody > tr > td {
  border-color: #fff !important;
}

#inbox-table .radio, #inbox-table .checkbox {
  margin-top: -1px;
  margin-bottom: 0;
}

.inbox-table-icon, .inbox-data-attachment {
  text-align: left;
  width: 28px;
  padding-left: 12px !important;
  padding-right: 0 !important;
}

.inbox-data-from {
  width: 200px;
}

.inbox-data-from > :first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  display: block;
  overflow: hidden;
}

.inbox-data-date {
  width: 80px;
  padding-left: 7px !important;
  padding-right: 0 !important;
}

.inbox-data-message > :first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #8a8a8a;
  width: 100%;
  height: 27px;
  overflow: hidden;
}

.inbox-data-message > :first-child > :first-child:after {
  content: " - ";
}

.inbox-data-message > :first-child span {
  color: #111;
}

.inbox-data-message > :first-child span.label {
  color: #fff;
}

.unread td {
  background: #fff;
}

.unread .inbox-data-message > :first-child > :first-child, .unread .inbox-data-from > :first-child, .unread .inbox-data-date > :first-child {
  font-weight: bold;
}

tr.highlight td, tr.unread.highlight td {
  color: #333;
  background: #ffc !important;
}

.inbox-checkbox-triggered {
  display: inline-block;
}

.inbox-checkbox-triggered > .btn-group {
  margin-right: 10px;
}

.inbox-checkbox-triggered > .btn-group .btn {
  padding-left: 14px;
  padding-right: 14px;
}

.inbox-side-bar {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  width: 200px;
  height: 100%;
  padding: 10px 0 10px 14px;
  display: block;
  position: absolute;
  overflow: hidden auto;
}

.inbox-side-bar::-webkit-scrollbar {
  width: 0 !important;
}

.inbox-side-bar h6 {
  text-transform: uppercase;
  color: #838383;
  padding: 0 15px;
  font-size: 11px;
  font-weight: normal;
  display: block;
}

.inbox-side-bar h6 a {
  margin-top: -2px;
  font-size: 14px;
}

.inbox-side-bar h6 .tooltip {
  text-transform: none !important;
}

.inbox-side-bar > .btn {
  margin-bottom: 35px;
}

.inbox-side-bar .input-group {
  margin-bottom: 25px;
}

#compose-mail-mini {
  margin-left: 4px;
}

.inbox-space {
  width: 185px;
  display: block;
}

.inbox-space > .progress {
  margin-top: 5px;
}

.inbox-menu-lg {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.inbox-menu-lg li {
  width: 100%;
  display: block;
}

.inbox-menu-lg li a {
  color: #333;
  padding: 6px 15px 7px;
  font-size: 13px;
  display: block;
}

.inbox-menu-lg li a:hover {
  background: #f4f4f4;
  text-decoration: none;
}

.inbox-menu-lg li.active a {
  color: #3276b1;
  background: #f0f0f0;
  border-bottom: 1px solid #e7e7e7;
  font-weight: bold;
}

.inbox-menu-sm {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.inbox-menu-sm li {
  width: 100%;
  display: block;
}

.inbox-menu-sm li a {
  color: #333;
  padding: 8px 15px 10px;
  font-size: 13px;
  display: block;
}

.inbox-menu-sm li a:hover {
  background: #f4f4f4;
  text-decoration: none;
}

.inbox-menu-sm.active a {
  color: #3276b1;
  background: #f0f0f0;
  border-bottom: 1px solid #e7e7e7;
  font-weight: bold;
}

.email-open-header {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #bfbfbf;
  margin: -10px 0 0;
  padding: 15px 3px;
  font-size: 20px;
}

.email-open-header > span {
  letter-spacing: normal;
  text-transform: uppercase;
  vertical-align: middle;
  background: #acacac;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: normal;
  line-height: 33px;
}

.inbox-info-bar {
  border-bottom: 1px solid #bfbfbf;
  padding: 10px 0;
}

.inbox-info-bar img {
  vertical-align: middle;
  border-left: 3px solid #fff;
  width: 35px;
  height: auto;
  margin-left: 2px;
  margin-right: 7px;
  display: inline-block;
}

.inbox-message, .inbox-download {
  border-bottom: 1px solid #bfbfbf;
  padding: 15px 4px;
}

.inbox-download-list {
  margin: 5px 0 0;
  padding: 0;
  list-style: none;
}

.inbox-download-list .inbox-download-list li {
  vertical-align: top;
  margin: 0 5px 0 0;
  display: inline-block;
}

.inbox-download-list .inbox-download-list li > :first-child {
  width: 150px;
  margin-bottom: 0;
  overflow: hidden;
}

.inbox-download-list .inbox-download-list li > :first-child > :first-child {
  text-align: center;
  color: #d6d6d6;
  display: block;
}

.inbox-download-list .inbox-download-list li > :first-child > :first-child > .fa {
  font-size: 150px;
}

.inbox-download-list .inbox-download-list li > :first-child > :first-child > img {
  max-width: 120px;
}

.inbox-download-list .inbox-download-list li > :first-child:hover {
  background: #fff;
  border-color: silver;
}

.inbox-compose-footer {
  background: #f5f5f5;
  border-bottom: 1px solid #a9a9a9;
  padding: 10px;
}

.inbox-info-bar, .inbox-message, .inbox-download, .inbox-compose-footer {
  margin-right: 240px;
  position: relative;
}

.email-infobox {
  border-bottom: 1px solid #bfbfbf;
  width: 180px;
  padding-top: 15px;
  padding-bottom: 0;
  display: block;
  position: absolute;
  top: 65px;
  right: 15px;
}

.inbox-info-bar .form-group {
  margin: 0;
}

.inbox-info-bar .form-group input, .inbox-info-bar .select2-container-multi .select2-choices {
  border-color: #fff !important;
}

.inbox-info-bar .select2-choices > div {
  display: none;
}

.inbox-info-bar .col-md-1, .inbox-info-bar .col-md-11 {
  padding-left: 0;
  padding-right: 0;
}

.fileinput {
  padding-top: 3px;
}

.hidden {
  display: none;
}

.inbox-info-bar em {
  text-align: right;
  font-style: normal;
  position: absolute;
  top: 6px;
  right: 20px;
}

.email-reply-text > div {
  color: #a9a9a9;
  border-left: 1px solid #d6d6d6;
  margin-left: 50px;
  padding-left: 10px;
}

.inbox-body .note-editor .note-editable {
  height: 100% important;
}

.email-reply-text > :first-child {
  padding-left: 45px;
}

@media (width <= 1280px) {
  .inbox-info-bar, .inbox-message, .inbox-compose-footer {
    margin-right: 0;
  }

  .email-infobox {
    display: none;
  }
}

@media (width <= 1024px) {
  .inbox-side-bar {
    display: none !important;
  }

  .inbox-body .table-wrap {
    margin-left: 0 !important;
  }

  .inbox-info-bar .col-md-1, .inbox-info-bar .col-md-11 {
    padding-left: 26px;
    padding-right: 26px;
  }
}

#inbox-content [data-event="showHelpDialog"], #inbox-content [data-event="codeview"], #inbox-content .note-insert {
  display: none !important;
}

#inbox-content [data-event="fullscreen"] {
  border-radius: 2px;
}

#inbox-content .note-toolbar .btn-sm {
  padding: 2px 6px 3px;
  font-size: 14px;
}

#inbox-content .note-toolbar .note-para .dropdown-menu {
  min-width: 180px;
}

.profile-pic {
  text-align: right;
}

.profile-pic > img {
  text-align: right;
  z-index: 4;
  border: 5px solid #fff;
  border-radius: 0%;
  max-width: 100px;
  margin-bottom: -30px;
  display: inline-block;
  position: relative;
  top: -30px;
  left: 10px;
}

.profile-carousel .carousel-inner {
  max-height: 150px;
}

.friends-list li {
  margin-bottom: 10px;
  margin-right: 3px;
  padding: 0;
}

.friends-list img {
  border: 1px solid #fff;
  outline: 1px solid #bfbfbf;
  width: 35px;
}

.profile-link-btn {
  color: #999;
  font-size: 14px;
}

time.icon {
  background-color: #fff;
  border-radius: 7px;
  width: 35px;
  height: 43px;
  margin: 3px auto;
  font-size: 14px;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px #bdbdbd, 0 2px #fff, 0 3px #eee9e9, 0 4px #fff, 0 5px #fff, 0 0 0 1px #d3d3d3;
}

time.icon * {
  text-align: center;
  width: 100%;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  display: block;
}

time.icon strong {
  color: #fff;
  background-color: #3a3633;
  border-radius: 6px 6px 0 0;
  padding: 2px 0;
  position: absolute;
  top: 0;
  box-shadow: 0 2px #3a3633;
}

time.icon em {
  color: #3276b1;
  position: absolute;
  bottom: 3px;
}

time.icon span {
  letter-spacing: -.05em;
  color: #2f2f2f;
  width: 100%;
  padding-top: 21px;
  font-size: 15px;
}

.user {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
  margin: 0 15px 15px 0;
  display: block;
  overflow: hidden;
}

.user img {
  float: left;
  width: 35px;
  margin-right: 5px;
}

.user .email {
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.search-results {
  padding: 18px 5px;
}

.search-results + .search-results {
  border-top: 1px dashed #e3e3e3;
}

.search-results > :first-child {
  margin-bottom: 4px;
  font-weight: 400;
}

.search-results > :first-child a {
  text-decoration: underline;
}

.search-results .url {
  font-size: 14px;
  font-style: normal;
}

.search-results img {
  width: 80px;
  margin-top: 4px;
  margin-right: 4px;
  display: inline-block;
}

.search-results > div {
  vertical-align: top;
  display: inline-block;
}

.search-results .note {
  margin: 0;
  line-height: normal;
}

.search-results .note a {
  color: #333;
  text-decoration: none !important;
}

.search-results .note a:hover {
  color: #ed1c24;
}

.todo-group-title {
  color: #999;
  background: #fafafa;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 31px;
}

.todo {
  min-height: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.todo > li {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAQCAYAAADagWXwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RkQ1OEY4NTM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RkQ1OEY4NjM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRGRDU4RjgzMzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRGRDU4Rjg0Mzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+pTD+wgAAACtJREFUeNpimDBhwn8gYABhdDYjiIELMDHgAUwTJ06Ea0VnjxpLO2MBAgwAGYZLegQbQ3UAAAAASUVORK5CYII=") 1px 11px no-repeat;
  border-bottom: 1px solid #e7e7e7;
  margin: 0 5px;
  display: block;
  position: relative;
  overflow: hidden;
}

.todo > li:last-child, .todo > li:only-child {
  border-bottom: none;
}

.todo > li > :first-child {
  vertical-align: top;
  border-right: 1px solid #ffe1eb;
  width: 20px;
  height: 100%;
  padding: 6px 11px 6px 18px;
  display: block;
  position: absolute;
}

.todo > li > :first-child:hover {
  cursor: move;
}

.todo > li > p {
  color: #333;
  border-left: 1px solid #ffe1eb;
  height: 100%;
  min-height: 37px;
  margin-bottom: 0;
  margin-left: 52px;
  padding: 8px 0 6px 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
}

.todo > li > p > :first-child {
  color: #999;
  margin-top: -5px;
  margin-bottom: 4px;
}

.todo > li > p > .date {
  color: #bfbfbf;
}

.todo > li > p > span {
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  display: block;
}

.todo > li.complete {
  background: none;
}

.todo > li.complete > :first-child:hover {
  cursor: default;
}

.todo > li.complete > * {
  font-style: italic;
  text-decoration: line-through;
}

.todo > li.ui-sortable-helper {
  background: #71843f1a;
  border-top: 1px solid #eee;
}

.smart-timeline {
  position: relative;
}

.smart-timeline-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.smart-timeline-list:after {
  content: " ";
  z-index: 1;
  background-color: #eee;
  width: 2px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 95px;
}

.smart-timeline-list li {
  margin: 0;
  padding: 15px 0;
  position: relative;
}

.smart-timeline-list li:hover {
  background-color: #f9f9f9;
}

.smart-timeline-list > li:hover {
  background-color: #f4f4f4;
}

.smart-timeline-icon {
  color: #fff;
  text-align: center;
  z-index: 100;
  background: #3276b1;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 2px;
  font-size: 14px;
  line-height: 28px;
  position: absolute;
  top: 10px;
  left: 80px;
}

.smart-timeline-icon > img {
  border: 2px solid #3276b1;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-top: -2px;
  margin-left: -2px;
}

.smart-timeline-time {
  float: left;
  text-align: right;
  width: 70px;
}

.smart-timeline-time > small {
  font-style: italic;
}

.smart-timeline-content {
  margin-left: 123px;
}

.table-forum thead tr {
  background: none !important;
}

.table-forum thead tr th {
  border-bottom-color: #f1f1f1;
  font-size: 17px;
  font-weight: normal;
}

.table-forum tr td {
  border-top-style: dashed !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.table-forum tr td h4 {
  font-size: 15px;
  font-weight: 700;
}

.table-forum tr td h4 small {
  margin-top: 2px;
  font-size: 13px;
  display: block;
}

.table-forum tr td > i {
  margin-top: 4px;
  padding-left: 10px;
}

.table-forum tr.closed h4 > a {
  color: #848484;
  cursor: not-allowed;
  text-decoration: line-through;
}

.table-forum tr.closed h4 > a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.forum-attachment {
  margin-top: 20px;
  display: block;
}

.ajax-loading-error {
  text-align: center;
  margin-top: 70px;
  font-size: 23px;
  font-weight: bold;
  display: block;
}

.ajax-notifications .ajax-loading-error {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}

.ajax-notifications .ajax-loading-animation {
  text-align: center;
  margin-top: 70px;
  font-size: 15px;
}

.project-members a {
  border-radius: 0;
  margin: 0 3px 0 0;
  display: inline-block;
  overflow: hidden;
}

.smart-rtl.project-members a {
  margin: 0 0 0 3px;
}

.project-members img {
  border-radius: 0;
  width: 25px;
}

.projects-table > tbody > tr > td {
  padding: 7px 10px 3px !important;
}

html {
  background: #fff url("mybg.ded1100f.png");
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  direction: ltr;
  background: #fff url("mybg.ded1100f.png");
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body.mobile-view-activated.hidden-menu {
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden !important;
}

a:hover, a:active, a:focus, button, button:active, button:focus, object, embed {
  outline: 0;
}

input::-moz-focus-inner {
  outline: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.page-title {
  margin: 12px 0 28px;
}

.page-title span {
  color: #333;
  vertical-align: 1px;
  font-size: 16px;
  display: inline-block;
}

label {
  font-weight: normal;
}

:focus {
  outline: 0 !important;
}

a, input, button {
  -ms-touch-action: none !important;
}

textarea:focus, select:focus, .uneditable-input:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
  outline: 0;
  outline: thin dotted \9 ;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.input-sm, .input-lg, .input-xs, .form-control {
  border-radius: 0 !important;
}

.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.btn-xs {
  padding: 1px 5px;
}

.btn-sm {
  padding: 6px 10px 5px;
}

.btn-lg {
  padding: 10px 16px;
}

.no-space {
  margin: 0;
}

.no-space > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
  margin: 0 !important;
}

#content {
  padding: 10px 14px;
  position: relative;
}

body.container {
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0 2px 70px #00000073;
}

body.container.hidden-menu:not(.mobile-view-activated) {
  overflow: hidden;
}

body.container.modal-open {
  padding: 0 !important;
}

h1 {
  letter-spacing: -1px;
  margin: 10px 0;
  font-size: 24px;
}

h1 small {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 300;
}

.lead {
  font-size: 19px;
}

h2 {
  letter-spacing: -1px;
  margin: 20px 0;
  font-size: 22px;
  line-height: normal;
}

h3 {
  margin: 20px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  display: block;
}

h4 {
  line-height: normal;
}

h5 {
  margin: 10px 0;
  font-size: 17px;
  font-weight: 300;
  line-height: normal;
}

h6 {
  margin: 10px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
}

.row-seperator-header {
  color: #646464;
  border-bottom: none;
  margin: 15px 14px 20px;
  font-size: 20px;
  font-weight: 400;
  display: block;
}

.center-canvas, .center-child-canvas > canvas {
  margin: 0 auto !important;
  display: block !important;
}

.bordered {
  border: 1px solid #0003;
}

.bordered:hover {
  border: 1px solid #0006;
}

aside {
  background: -o-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -ms-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -moz-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -o-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  min-height: 100%;
  display: block;
}

#header {
  z-index: 905;
  background-image: -moz-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-color: #f3f3f3;
  background-image: linear-gradient(#f3f3f3, #e2e2e2);
  background-repeat: repeat-x;
  height: 49px;
  margin: 0;
  padding: 0 13px 0 0;
  display: block;
  position: relative;
}

#header > div {
  vertical-align: middle;
  float: left;
  height: 49px;
  display: inline-block;
}

#header > div.open {
  font-weight: bold;
}

.fixed-header #header {
  z-index: 905;
  border-bottom: 1px solid #0000004d;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: -11px 12px 23px #0000001a;
}

.fixed-header.fixed-ribbon #header {
  box-shadow: none;
  border-bottom: none;
}

.container.fixed-header #header {
  max-width: 1164px;
}

.fixed-header #header .ajax-dropdown {
  z-index: 906;
}

.fixed-header #shortcut {
  position: fixed;
}

.fixed-header #main {
  margin-top: 49px;
}

#logo-group > span {
  float: left;
  height: 39px;
  display: inline-block;
}

#logo-group span#activity {
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  color: #c4bab6;
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  margin: 10px 0 0;
  padding: 2px;
  font-size: 19px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  cursor: default !important;
  text-decoration: none !important;
}

#logo-group span#activity:hover {
  border: 1px solid #bfbfbf;
  transition: all;
  box-shadow: inset 0 0 4px 1px #00000014;
  cursor: pointer !important;
}

#logo {
  width: 175px;
  margin-top: 8px;
  margin-left: 9px;
  display: inline-block;
}

#logo img {
  height: auto;
  padding-left: 10px;
}

#activity b.badge {
  cursor: pointer;
  color: #fff;
  text-align: center;
  background: #0091d9;
  border-radius: 50%;
  padding: 2px 4px 3px;
  font-size: 10px;
  font-weight: bold;
  line-height: normal;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -5px;
  box-shadow: inset 1px 1px #0000001a, inset 0 -1px #00000012;
}

#activity.active .badge {
  background: #006fa6 !important;
}

#project-context, .project-context {
  padding: 7px 13px 0;
  display: inline-block;
  position: relative;
}

#project-context > span, .project-context > span {
  display: block;
}

#project-context > :first-child, .project-context > :first-child {
  color: #bfbfbf;
  text-transform: uppercase;
  text-shadow: 0 0 1px #fff;
  text-align: left;
  padding-left: 0;
  font-size: 10px;
  font-weight: bold;
  display: block;
}

#project-selector, .project-selector {
  white-space: nowrap;
  text-align: left;
  color: #555;
  cursor: pointer;
  background: none;
  border: none;
  max-width: 97%;
  padding: 0;
  font-size: 14px;
}

#project-selector:hover, .project-selector:hover {
  color: #333;
}

.header-search {
  position: relative;
}

.header-search.pull-right {
  margin-left: 6px;
}

.header-search > input {
  box-sizing: border-box;
  color: #404040;
  -ms-appearance: normal;
  appearance: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 0;
  outline: none;
  width: 100%;
  min-width: 400px;
  height: 30px;
  margin-top: 10px;
  padding: 0 10px;
  line-height: normal;
  display: block;
}

.header-search > button {
  color: #6d6a69;
  z-index: 2;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 30px;
  position: absolute;
  top: 10px;
  right: 0;
}

#search-mobile {
  display: none;
}

#cancel-search-js {
  z-index: 3;
  color: #fff;
  text-align: center;
  background: #a90329;
  width: 30px;
  height: 29px;
  padding: 0;
  font-size: 17px;
  line-height: 29px;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none !important;
}

#header > :first-child, aside {
  width: 220px;
}

#left-panel {
  z-index: 904;
  padding-top: 49px;
  position: absolute;
  top: 0;
  left: 0;
}

#main {
  min-height: 500px;
  margin-left: 220px;
  padding: 0 0 52px;
  position: relative;
}

#ribbon {
  background: #474544;
  min-height: 40px;
  padding: 0 13px;
  position: relative;
}

#ribbon .breadcrumb {
  vertical-align: top;
  background: none;
  margin: 0;
  display: inline-block;
  padding: 11px 34px 11px 0 !important;
}

#ribbon .breadcrumb a, #ribbon .breadcrumb {
  color: #bbb !important;
  text-decoration: none !important;
}

#ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
  color: #e4e4e4;
}

.fixed-ribbon #ribbon {
  z-index: 901;
  position: fixed;
  top: 49px;
  left: 220px;
  right: 0;
}

.container.fixed-ribbon #ribbon {
  width: 944px;
  left: 590px;
}

.fixed-ribbon #content {
  padding-top: 50px;
}

.minified.fixed-ribbon #ribbon {
  left: 45px;
}

.hidden-menu.fixed-ribbon #ribbon {
  left: 10px;
}

.fixed-navigation #left-panel {
  height: 100%;
  position: fixed;
}

.fixed-navigation nav {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-bottom: 49px;
  overflow-y: auto;
}

.fixed-navigation nav ul {
  width: 114%;
  height: 100%;
  padding-right: 15px;
  position: relative;
  overflow: hidden scroll;
}

.mobile-detected.fixed-navigation nav ul {
  padding-right: 30px !important;
}

.minified.fixed-navigation nav {
  -webkit-overflow-scrolling: none;
  height: auto;
  padding-bottom: 0;
  overflow: visible;
}

.minified.fixed-navigation nav ul {
  width: 100%;
  padding-right: 0;
  overflow: visible;
}

nav ul li a:active {
  background: #616161 !important;
}

nav ul {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: .5em;
  list-style: none;
  position: relative;
}

nav ul .active > a {
  position: relative;
  color: #fff !important;
}

nav ul li.active > a:before {
  content: "";
  color: #eee;
  width: 27px;
  height: 27px;
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  line-height: normal;
  display: block;
  position: absolute;
  right: -21px;
}

nav ul li.active.open > a:before {
  content: "";
}

nav ul > li:last-child.open {
  border-bottom: 1px solid #ffffff26;
}

nav ul li a {
  color: #c0bbb7;
  padding: 10px 10px 10px 11px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  display: block;
  position: relative;
  text-decoration: none !important;
}

nav ul > li > ul > li > a, nav ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

nav ul li a:focus {
  color: #c9c9c9;
}

nav ul li a:hover {
  color: #fff;
  text-decoration: none;
}

nav ul li a.inactive, nav ul li a.inactive:hover {
  opacity: .5;
  cursor: not-allowed;
  color: #c0bbb7;
}

nav ul b {
  float: right;
  margin-top: 2px;
  font-size: 11px;
}

nav ul span.menu-item-parent {
  margin: 0;
  padding: 0;
  display: inline-block;
}

nav ul li.open > a, nav ul li.open > a b {
  color: #fff !important;
}

nav ul > li > a b {
  top: 10px;
  right: 10px;
  position: absolute !important;
}

nav ul > li > a > i {
  text-align: center;
  width: 15px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}

nav ul > li > a > i > em {
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background: #ed1c24;
  border: 1px solid #ffffff1a;
  border-radius: 50%;
  min-width: 13px;
  max-height: 13px;
  padding: 2px;
  font-size: 9px;
  font-style: normal;
  font-weight: bold;
  line-height: 8px;
  text-decoration: none;
  display: block;
  position: absolute;
  top: -8px;
  right: -6px;
}

nav ul li li {
  border-bottom: none;
  position: relative;
}

nav ul > li > ul:before {
  content: "";
  z-index: 1;
  border-left: 1px solid #7a7a7a;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 23px;
}

nav ul ul li:before {
  content: "";
  z-index: 1;
  border-top: 1px solid #7a7a7a;
  width: 8px;
  display: block;
  position: absolute;
  top: 16px;
  left: 23px;
}

nav ul ul ul li:before {
  content: "";
  border-top: 1px solid #0000;
  width: 18px;
  display: block;
  position: absolute;
  top: 17px;
  left: 10px;
}

nav ul ul li a i {
  text-align: center !important;
  vertical-align: 0 !important;
  width: 18px !important;
  font-size: 14px !important;
  line-height: 1 !important;
}

nav ul > li:hover > ul:before, nav ul > li:hover > ul > li:before {
  border-color: #acacac !important;
}

nav ul ul {
  background: #45454599;
  margin: 0;
  padding: 7px 0;
  display: none;
}

nav ul ul ul {
  background: none;
  padding: 0;
}

nav ul ul li {
  margin: 0;
  padding: 0;
}

nav ul ul li > a {
  outline: 0;
  padding-left: 42px;
  font-size: 14px;
  font-weight: normal;
}

nav ul ul li > a:hover {
  color: #fff;
  background-color: #454545cc;
}

nav ul ul ul li a {
  color: #bfbfbf;
  padding: 8px 10px 8px 60px;
  font-size: 14px;
}

nav ul ul ul li a:hover {
  color: #fff;
}

nav ul ul ul ul li a {
  padding-left: 80px;
}

nav ul ul ul ul ul li a {
  padding-left: 100px;
}

nav ul ul ul ul ul ul li a {
  padding-left: 120px;
}

nav ul ul b {
  color: #b3b3b3;
}

nav ul ul > li:hover b {
  color: #d5d9e2;
}

.header-dropdown-list {
  float: right;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  position: relative;
}

.header-dropdown-list > li {
  padding-right: 5px;
  display: inline-block;
}

.header-dropdown-list > li > .dropdown-toggle {
  margin-top: 16px;
  display: block;
}

.smart-accordion-default.panel-group {
  margin-bottom: 0;
}

.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}

.smart-accordion-default.panel-group .panel-heading {
  padding: 0;
}

.smart-accordion-default.panel-group .panel-title a {
  padding: 10px 15px;
  display: block;
  text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
  border-radius: 0;
}

.smart-accordion-default .panel-default > .panel-heading {
  background-color: #fcfcfc;
}

.smart-accordion-default .panel-default {
  border-color: #c3c3c3;
}

.smart-accordion-default .panel-title > a > :first-child, .smart-accordion-default .panel-title > a.collapsed > .fa {
  display: none;
}

.smart-accordion-default .panel-title > a.collapsed > :first-child {
  display: inline-block;
}

.no-padding .smart-accordion-default > div {
  border-left: none !important;
  border-right: none !important;
}

.no-padding .smart-accordion-default > div:first-child {
  border-top: none !important;
}

.no-padding .smart-accordion-default > div:last-child {
  border-bottom: none !important;
}

.onoffswitch-container {
  margin-top: 4px;
  margin-left: 7px;
  display: inline-block;
}

.onoffswitch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  vertical-align: middle;
  width: 50px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  cursor: pointer;
  box-sizing: content-box;
  border: 1px solid #b3b3b3;
  border-color: #adadad #b3b3b3 #9e9e9e;
  border-radius: 50px;
  display: block;
  overflow: hidden;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  display: block;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  color: #fff;
  box-sizing: border-box;
  width: 50%;
  height: 15px;
  padding: 0;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
}

.onoffswitch-inner:before {
  content: attr(data-swchon-text);
  text-shadow: 0 -1px #333;
  color: #fff;
  text-align: left;
  background-color: #3276b1;
  padding-left: 7px;
  box-shadow: inset 0 2px 6px #00000080, 0 1px 2px #0000000d;
}

.onoffswitch-inner:after {
  content: attr(data-swchoff-text);
  text-shadow: 0 -1px #fff;
  color: #555;
  text-align: right;
  background-color: #fff;
  padding-right: 7px;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.onoffswitch-switch {
  box-sizing: content-box;
  background: #f4f4f4 -moz-linear-gradient(top, #fff, #eee);
  background: #f4f4f4 linear-gradient(#fff, #eee) repeat-x;
  border: 1px solid #9a9a9a;
  border-radius: 50px;
  width: 19px;
  height: 19px;
  margin: -2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  box-shadow: 1px 1px 4px #0000004d;
}

.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch:before, .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "";
  color: #a90329;
  text-align: center;
  text-shadow: 0 -1px #fff;
  font-family: "Font Awesome 5 Pro";
  font-size: 10px;
  font-weight: bold;
  line-height: 19px;
  display: block;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "";
  color: #57889c;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
  display: block;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}

.onoffswitch-switch:hover {
  background-color: #eee;
}

.onoffswitch-switch:active {
  background-color: #eee;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:after, .onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-inner:before {
  text-shadow: 0 1px #fff;
  color: #333;
  background: #bfbfbf;
}

.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-switch, .onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  background-image: -moz-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-color: #f4f4f4;
  background-image: linear-gradient(#bfbfbf, #f4f4f4);
  box-shadow: none !important;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label, .onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-label {
  border-color: #ababab #999 #878787 !important;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  border-color: #3276b1 #2a6395 #255681;
}

.onoffswitch + span, .onoffswitch-title {
  vertical-align: middle;
  margin-top: -5px;
  display: inline-block;
}

.form-control {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.form hr {
  border-color: #0000001a;
  margin: 20px -13px;
}

.form fieldset {
  background: #ffffffe6;
  border: none;
  display: block;
  position: relative;
}

fieldset {
  position: relative;
}

.form-actions {
  text-align: right;
  background: #f9f9f9e6;
  border-top: 1px solid #0000001a;
  margin: 25px -13px -13px;
  padding: 13px 14px 15px;
  display: block;
}

.well .form-actions {
  margin-bottom: -19px;
  margin-left: -19px;
  margin-right: -19px;
}

.well.well-lg .form-actions {
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -24px;
}

.well.well-sm .form-actions {
  margin-bottom: -9px;
  margin-left: -9px;
  margin-right: -9px;
}

.popover-content .form-actions {
  border-radius: 0 0 3px 3px;
  margin: 0 -14px -9px;
  padding: 9px 14px;
}

.no-padding .form .form-actions {
  text-align: right;
  background: #f8f8f8e6;
  border-top: 1px solid #0000001a;
  margin: 25px 0 0;
  padding: 13px 14px 15px;
  display: block;
}

.form header, legend, .bootstrap-duallistbox-container label {
  color: #222;
  background: #fff;
  border-bottom: 1px dashed #0003;
  margin: 25px 0 20px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.no-padding .form header {
  margin: 25px 14px 0;
}

.form header:first-child {
  margin-top: 10px;
}

legend {
  background: none;
  margin-top: 0;
  font-weight: 400;
}

.input-group-addon {
  -o-transition: border-color .3s;
  -o-transition: background-color .3s;
  border-radius: 0;
  padding: 6px 10px;
  transition: background-color .3s;
}

.input-group-addon .fa {
  font-size: 14px;
}

.input-group-addon .fa-lg, .input-group-addon .fa-2x {
  font-size: 2em;
}

.input-group-addon .fa-3x, .input-group-addon .fa-4x, .input-group-addon .fa-5x {
  font-size: 30px;
}

input[type="text"]:focus + .input-group-addon {
  color: #fff;
  background-color: #72a0ce;
  border-color: #0091d9;
}

.has-warning input[type="text"], .has-warning input[type="text"] + .input-group-addon {
  border-color: #dbab57;
}

.has-warning input[type="text"] + .input-group-addon {
  color: #c09853;
  background-color: #faf2cc;
}

.has-warning input[type="text"]:focus, .has-warning input[type="text"]:focus + .input-group-addon {
  border-color: #dbab57;
}

.has-warning input[type="text"]:focus + .input-group-addon {
  color: #fff;
  background-color: #e1c118;
}

.has-error .input-group-addon {
  color: #b94a48;
  background: #f2dede;
  border-color: #b94a48;
}

.has-error input[type="text"], .has-error input[type="text"] + .input-group-addon {
  border-color: #b94a48;
}

.has-error input[type="text"] + .input-group-addon {
  color: #b94a48;
  background-color: #f2dede;
}

.has-error input[type="text"]:focus, .has-error input[type="text"]:focus + .input-group-addon {
  border-color: #c05e5e;
}

.has-error input[type="text"]:focus + .input-group-addon {
  color: #fff;
  background-color: #c05e5e;
}

.has-success input[type="text"]:focus + .input-group-addon {
  color: #fff;
  background-color: #468847;
  border-color: #468847;
}

.form fieldset .form-group:last-child, .form fieldset .form-group:last-child .note, .form .form-group:last-child, .form .form-group:last-child .note {
  margin-bottom: 0;
}

.note {
  color: #999;
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
}

.input-icon-right {
  position: relative;
}

.input-icon-right > i, .input-icon-left > i {
  color: #bfbfbf;
  font-size: 16px;
  position: absolute;
  top: 30%;
  right: 10px;
}

.input-icon-left > i {
  left: 24px;
  right: auto;
}

.input-icon-right .form-control {
  padding-right: 27px;
}

.input-icon-left .form-control {
  padding-left: 29px;
}

input[type="text"].ui-autocomplete-loading, input[type="password"].ui-autocomplete-loading, input[type="datetime"].ui-autocomplete-loading, input[type="datetime-local"].ui-autocomplete-loading, input[type="date"].ui-autocomplete-loading, input[type="month"].ui-autocomplete-loading, input[type="time"].ui-autocomplete-loading, input[type="week"].ui-autocomplete-loading, input[type="number"].ui-autocomplete-loading, input[type="email"].ui-autocomplete-loading, input[type="url"].ui-autocomplete-loading, input[type="search"].ui-autocomplete-loading, input[type="tel"].ui-autocomplete-loading, input[type="color"].ui-autocomplete-loading {
  background-position: 99%;
  background-repeat: no-repeat;
  padding-right: 27px;
  background-image: url("select2-spinner.ff68bdb0.gif") !important;
}

.input-group-addon .checkbox, .input-group-addon .radio {
  min-height: 0;
  padding-top: 0;
  margin-right: 0 !important;
}

.input-group-addon label input[type="checkbox"].checkbox + span, .input-group-addon label input[type="radio"].radiobox + span, .input-group-addon label input[type="radio"].radiobox + span:before, .input-group-addon label input[type="checkbox"].checkbox + span:before {
  margin-right: 0;
}

.input-group-addon .onoffswitch, .input-group-addon .onoffswitch-label {
  margin: 0;
}

.timeline-seperator {
  text-align: center;
  color: #999;
  margin: 20px 0 0;
  display: block;
}

.timeline-seperator > :first-child {
  background: #fff;
  border: 1px dashed #0000001a;
  border-radius: 4px;
  padding: 4px 5px;
  font-size: 11px;
}

.timeline-seperator .btn, .timeline-seperator .btn + .dropdown-menu {
  margin-right: 15px;
}

.timeline-seperator:after {
  content: " ";
  border-bottom: 1px dashed #0000001a;
  margin: -8px 20px 0;
  display: block;
}

.alert {
  color: #675100;
  border-width: 0 0 0 5px;
  border-radius: 0;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 10px;
}

.alert .close {
  line-height: 20px;
  top: 0;
  right: -5px;
}

.alert-heading {
  font-weight: 600;
}

.alert-danger {
  color: #fff;
  text-shadow: none;
  background-color: #c26565;
  border-color: #953b39;
}

.alert-warning {
  color: #826430;
  background-color: spin(#efd9b3, 8);
  border-color: #dfb56c;
}

.alert-success {
  color: #356635;
  background-color: spin(#c4e0c4, -20);
  border-color: #8ac38b;
}

.alert-info {
  color: #305d8c;
  background-color: #d6dde7;
  border-color: #9cb4c5;
}

.well {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  position: relative;
  box-shadow: 0 1px 1px #ececec;
}

.well.well-clean {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #0000;
}

.well.well-glass {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background-color: #ffffff40;
  border-color: #0000;
}

.well.well-light {
  background: #fff;
  border: 1px solid #e2e2e2;
}

.widget-body .well {
  margin-bottom: 0;
}

.well.transparent {
  background: none !important;
  border: none !important;
}

.jarviswidget.well.transparent > div, .jarviswidget.well.transparent > div .widget-body {
  padding: 0 !important;
}

.well[class^="bg-"], .well[class*=" bg-"] {
  border: 1px solid #555 !important;
}

.nav-tabs > li > a .badge {
  opacity: .5;
  min-width: 17px;
  margin-left: 5px;
  padding: 3px 5px;
  font-size: 11px;
  font-weight: normal;
}

.nav-tabs > li > a > .fa {
  opacity: .5;
}

.tabs-left .nav-tabs > li > a .badge {
  margin-left: 0;
  margin-right: 5px;
}

.nav-tabs > li > a .label {
  opacity: .5;
  margin-left: 5px;
  font-size: 11px;
  display: inline-block;
}

.nav-tabs > li.active > a .badge, .nav-tabs > li.active > a .label, .nav-tabs > li.active > a > .fa {
  opacity: 1;
}

.nav-tabs > li > a {
  color: #333;
  border-radius: 0;
}

.nav-tabs > li.active > a {
  font-weight: bold;
  box-shadow: 0 -2px #57889c;
  border-top-width: 0 !important;
  margin-top: 1px !important;
}

.tabs-left .nav-tabs > li.active > a {
  box-shadow: -2px 0 #57889c;
  border-top-width: 1px !important;
  border-left: none !important;
  margin-left: 1px !important;
}

.tabs-left .nav-pills > li.active > a {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  border: none !important;
}

.tabs-right .nav-tabs > li.active > a {
  box-shadow: 2px 0 #57889c;
  border-top-width: 1px !important;
  border-right: none !important;
  margin-right: 1px !important;
}

.tabs-below .nav-tabs > li.active > a {
  box-shadow: 0 2px #57889c;
  border-top: none !important;
  border-bottom-width: 0 !important;
  margin-top: 0 !important;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li, .tabs-left > .nav-pills > li, .tabs-right > .nav-pills > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a, .tabs-left > .nav-pills > li > a, .tabs-right > .nav-pills > li > a {
  min-width: 74px;
  margin-bottom: 3px;
  margin-right: 0;
}

.tabs-left > .nav-tabs, .tabs-left > .nav-pills {
  float: left;
  border-right: 1px solid #ddd;
  margin-right: 19px;
}

.tabs-left > .nav-pills {
  border-right: none;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
}

.tabs-left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {
  border-color: #eee #d5d5d5 #eee #eee;
}

.tabs-left > .nav-tabs .active > a, .tabs-left > .nav-tabs .active > a:hover, .tabs-left > .nav-tabs .active > a:focus {
  border-color: #d5d5d5 #0000 #d5d5d5 #ddd;
}

.tabs-left > .tab-content {
  margin-left: 109px;
}

.tabs-right > .nav-tabs {
  float: right;
  border-left: 1px solid #ddd;
  margin-left: 19px;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
}

.tabs-right > .nav-tabs > li > a:hover, .tabs-right > .nav-tabs > li > a:focus {
  border-color: #eee #eee #eee #ddd;
}

.tabs-right > .nav-tabs .active > a, .tabs-right > .nav-tabs .active > a:hover, .tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd #0000;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
}

.tab-content > .active, .pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: #0000;
}

.tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover, .tabs-below > .nav-tabs > .active > a:focus {
  border-color: #0000 #ddd #ddd;
}

.nav-tabs.bordered {
  background: #fff;
  border: 1px solid #ddd;
}

.nav-tabs.bordered > :first-child a {
  border-left-width: 0 !important;
}

.nav-tabs.bordered + .tab-content {
  border: 1px solid #ddd;
  border-top: none;
}

.tabs-pull-right.nav-tabs > li, .tabs-pull-right.nav-pills > li {
  float: right;
}

.tabs-pull-right.nav-tabs > li:first-child > a, .tabs-pull-right.nav-pills > li:first-child > a {
  margin-right: 1px;
}

.tabs-pull-right.bordered.nav-tabs > li:first-child > a, .tabs-pull-right.bordered.nav-pills > li:first-child > a {
  border-right-width: 0;
  margin-right: 0;
  border-left-width: 1px !important;
}

.jarviswidget > header > .nav-tabs.pull-left > li:first-child a {
  border-left-width: 0 !important;
}

a:link, .btn, button {
  -webkit-tap-highlight-color: fade(#a90329, 50%);
}

.login-info, .login-info *, #logo-group *, .minified .menu-item-parent {
  box-sizing: content-box;
}

.hidden-desktop {
  display: none !important;
}

.dropdown-menu-xs {
  min-width: 37px;
}

.dropdown-menu-xs > li > a {
  padding: 3px 10px;
}

.dropdown-menu-xs > li > a:hover i {
  color: #fff !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  margin-top: -6px;
  margin-left: -1px;
  top: 0;
  left: 100%;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  content: " ";
  float: right;
  border: 5px solid #0000;
  border-left-color: #bfbfbf;
  border-right-width: 0;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  display: block;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  margin-left: 10px;
  left: -100%;
}

.table.has-tickbox thead tr th:first-child {
  width: 18px;
}

.table.has-tickbox.smart-form thead tr th:first-child .radio, .table.has-tickbox.smart-form thead tr th:first-child .checkbox, .table.has-tickbox.smart-form tbody tr td:first-child .radio, .table.has-tickbox.smart-form tbody tr td:first-child .checkbox {
  padding: 0 !important;
}

.table thead tr, .fc-border-separate thead tr {
  background-color: #eee;
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -ms-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#fafafa));
  background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  font-size: 12px;
}

.fc-border-separate thead tr th {
  padding: 4px;
  line-height: 1.42857;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-width: 1px;
}

.no-padding > .table-bordered, .no-padding > .table-responsive > .table-bordered {
  border: none !important;
}

.no-padding > .table-bordered thead tr th:first-child, .no-padding > .table-bordered thead tr td:first-child, .no-padding > .table-bordered tbody tr td:first-child, .no-padding > .table-responsive > .table-bordered thead tr th:first-child, .no-padding > .table-responsive > .table-bordered thead tr td:first-child, .no-padding > .table-responsive > .table-bordered tbody tr td:first-child {
  border-left-width: 0 !important;
}

.no-padding > .table-bordered thead tr th:last-child, .no-padding > .table-bordered tbody tr td:last-child, .no-padding > .table-responsive > .table-bordered thead tr th:last-child, .no-padding > .table-responsive > .table-bordered tbody tr td:last-child {
  border-right-width: 0 !important;
}

.no-padding > .table-bordered tbody tr:last-child td, .no-padding > .table-responsive > .table-bordered tbody tr:last-child td {
  border-bottom-width: 0 !important;
}

.no-padding .table-responsive .table {
  margin-bottom: 0;
}

.no-padding .note-editable .table-bordered {
  border: 1px solid #ddd !important;
}

.smart-form.table td .radio i, .smart-form.table td .checkbox i, .smart-form.table th .radio i, .smart-form.table th .checkbox i {
  top: 0 !important;
}

.table-condensed.table > tbody > tr > td, .table-condensed.table > tbody > tr > th, .table-condensed.table > tfoot > tr > td, .table-condensed.table > tfoot > tr > th, .table-condensed.table > thead > tr > td, .table-condensed.table > thead > tr > th {
  padding: 5px 10px !important;
}

.show-stats .progress {
  border-radius: 0;
  height: 7px;
  margin-top: 3px;
  margin-bottom: 15px;
}

.show-stats {
  padding-top: 6px !important;
}

.show-stat-buttons {
  float: left;
  width: 100%;
}

.show-stats .show-stat-buttons > :first-child {
  padding-right: 5px;
}

.show-stats .show-stat-buttons > :last-child {
  padding-left: 5px;
}

.show-stat-microcharts {
  margin-left: -10px;
  margin-right: -10px;
}

.show-stat-microcharts > div {
  height: 65px;
  padding: 7px 9px;
  overflow: hidden;
  border-top: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  margin-top: 10px !important;
}

.show-stat-microcharts > :last-child {
  border-right: none !important;
}

.show-stat-microcharts .sparkline {
  opacity: .7;
  margin-top: 10px;
  margin-right: 8px;
}

.show-stat-microcharts .sparkline:hover {
  opacity: 1;
}

.smaller-stat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.smaller-stat li {
  margin-top: 3px;
  margin-right: 0;
}

.smaller-stat li:first-child {
  margin-top: 8px;
}

.smaller-stat span.label {
  text-align: left;
  opacity: .5;
  cursor: default;
  width: 40px;
  display: block;
}

.smaller-stat span.label:hover {
  opacity: 1;
}

.login-info {
  color: #fff;
  border: 0 solid #0000;
  border-top-width: 1px;
  border-bottom: 1px solid #525151;
  width: 100%;
  height: 39px;
  font-size: 12px;
  display: block;
  box-shadow: inset 1px 1px #0000001a, inset 0 -1px #00000012;
  margin: 0 !important;
}

.login-info a {
  color: #c0bbb7;
  margin-top: 6px;
  display: inline-block;
  text-decoration: none !important;
}

.login-info a span {
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 150px;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.login-info a span + i {
  vertical-align: middle;
}

.login-info a:hover {
  color: #fff;
}

.login-info > span {
  border-bottom: 1px solid #1a1817;
  height: 38px;
  padding: 0 10px;
  display: block;
}

.login-info img {
  vertical-align: middle;
  border-left: 3px solid #fff;
  width: 25px;
  height: auto;
  margin-top: 1px;
  margin-left: 0;
  margin-right: 5px;
  display: inline-block;
}

img.online, img.busy, img.offline, img.away {
  border-left: 3px solid #fff;
}

img.online {
  border-left-color: #40ac2b !important;
}

img.busy {
  border-left-color: #ed1c24 !important;
}

img.offline {
  border-left-color: #ddd !important;
}

img.away {
  border-left-color: #ffc40d !important;
}

.client-form header {
  background: #f8f8f8e6;
  border-bottom-style: solid;
  border-bottom-color: #0000001a;
  margin: 0;
  padding: 15px 13px;
}

.minified .inbox-badge {
  background: #ed1c24;
  border-radius: 50%;
  min-width: 13px;
  padding: 2px;
  font-size: 9px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.minifyme {
  color: #a8a8a8;
  text-align: center;
  cursor: pointer;
  background: #454545;
  border-bottom: 1px solid #302f2f;
  border-radius: 5px 0 0 5px;
  width: 36px;
  height: 28px;
  margin-top: 7px;
  padding: 0;
  font-size: 19px;
  transition: all .1s linear;
  display: block;
  position: absolute;
  right: 0;
}

.minifyme:hover {
  color: #e4e4e4;
  background: #646464;
  width: 40px;
  padding-right: 5px;
  right: 0;
}

.minified .minifyme {
  color: #a8a8a8;
  border-radius: 0;
  width: 100%;
  height: 34px;
  margin: 0;
  font-size: 23px;
  position: relative;
  right: 0;
}

.minified .minifyme .fa:before {
  content: "";
}

.minified .login-info a span, .minified .login-info a i {
  display: none;
}

.minified .login-info span {
  text-align: center;
  padding: 0 5px;
}

.minified .login-info img {
  width: 30px;
  margin: -2px 0 0;
}

.minified .login-info {
  border-bottom: 1px solid #181818;
  height: 38px;
}

.minified #left-panel {
  width: 45px;
}

.minified #main {
  margin-left: 45px;
}

.minified #left-panel, .minified nav ul > li {
  overflow: visible;
}

.minified nav ul > ul {
  overflow: hidden scroll;
  background: #ed1c24 !important;
  min-height: 100px !important;
  max-height: 180px !important;
}

.minified nav ul > li, .minified nav ul > li a {
  position: relative;
}

.minified nav ul > li > a {
  padding: 10px 11px;
  display: block;
}

.minified nav ul > li > a .fa.fa-fw {
  text-align: center;
  width: auto;
  padding: 0;
  display: block;
}

.minified nav ul > li > a > i {
  text-align: center;
  margin: 0;
  display: block;
}

.minified nav ul > li.active > a:before {
  content: "";
}

.minified nav ul ul li a {
  padding-left: 14px;
}

.minified nav ul ul li li a {
  padding-left: 25px;
}

.minified nav ul ul li:before, .minified nav ul > li > ul:before {
  left: 12px;
}

.minified nav ul > li > a .menu-item-parent, .minified nav ul > li > a > b {
  display: none;
}

.minified nav ul > li > a > .menu-item-parent {
  color: #333;
  z-index: 3;
  background-color: #f5f5f5;
  border-top: 1px solid #d8d4d4;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #bfbfbf;
  width: 186px;
  height: 38px;
  padding-left: 12px;
  line-height: 38px;
  display: none;
  position: absolute;
  top: -3px;
  left: 40px;
  box-shadow: 2px 1px 2px #0003;
}

.minified nav ul > li > ul {
  z-index: 5;
  background: #454545;
  border: 1px solid #bfbfbf;
  width: 199px;
  min-height: 180px;
  margin-top: -3px;
  position: absolute;
  left: 40px;
  overflow: hidden;
  box-shadow: 1px 1px 2px #0003;
  display: none !important;
}

.minified nav ul > li > ul > li > ul {
  border: none;
  width: 197px;
  margin-left: -12px;
  position: relative;
  left: 13px;
}

.minified nav ul > li:hover > a > .menu-item-parent, .minified nav ul > li:hover > ul {
  display: block !important;
}

.minified nav ul > li > ul > li {
  background-color: #454545e6;
}

.minified nav ul > li > ul > li > ul > li {
  background-color: #484b50;
}

.minified nav ul > li {
  border-top: 1px solid #525151;
  border-bottom: 1px solid #1a1817;
}

.minified nav ul > li > ul:before {
  border-left: none;
}

.minified nav ul ul li:before {
  border: none;
}

.slimScrollDiv, .slimScrollDiv > :first-child {
  overflow: hidden;
}

.minified .slimScrollDiv, .minified .slimScrollDiv > :first-child {
  overflow: visible !important;
}

.minified .slimScrollBar, .minified .slimScrollRail {
  display: none;
}

.minified nav ul > li > ul > li > a, .minified nav ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

.minified nav ul > li > ul > li > ul > li a {
  padding-top: 6px;
  padding-bottom: 6px;
}

.minified nav ul ul ul ul li a {
  padding-left: 45px;
}

.minified nav ul ul ul ul ul li a {
  padding-left: 62px;
}

.minified nav ul ul ul ul ul ul li a {
  padding-left: 82px;
}

.pagination.pagination-alt > li > a {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  margin-left: -1px;
}

.pagination.pagination-alt > li:first-child > a {
  padding-left: 0;
}

.pagination > li > a, .pagination > li > span {
  box-shadow: inset 0 -2px #0000000d;
}

.disabled {
  color: #fff;
}

.btn-default.disabled {
  color: #999;
}

.btn {
  border-radius: 2px;
  box-shadow: inset 0 -2px #0000000d;
}

.btn:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.btn.btn-ribbon {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff777777", endColorstr= "#ff666666", GradientType= 0);
  color: #fff;
  vertical-align: middle;
  float: left;
  cursor: pointer;
  background-image: -moz-linear-gradient(top, #777, #666);
  background-color: #707070;
  background-image: linear-gradient(#777, #666);
  background-repeat: repeat-x;
  border: none;
  height: 20px;
  margin: 0 8px 0 0;
  padding: 0 5px;
  line-height: 20px;
  display: block;
}

.btn.btn-ribbon > i {
  font-size: 111%;
}

.ribbon-button-alignment {
  padding-top: 10px;
  display: inline-block;
}

.ribbon-button-alignment.pull-right > .btn.btn-ribbon {
  margin: 0 0 0 8px;
}

.panel-purple {
  border-color: #6e587a;
}

.panel-purple > .panel-heading {
  color: #fff;
  background-color: #6e587a;
  border-color: #6e587a;
}

.panel-greenLight {
  border-color: #71843f;
}

.panel-greenLight > .panel-heading {
  color: #fff;
  background-color: #71843f;
  border-color: #71843f;
}

.panel-greenDark {
  border-color: #496949;
}

.panel-greenDark > .panel-heading {
  color: #fff;
  background-color: #496949;
  border-color: #496949;
}

.panel-darken {
  border-color: #333;
}

.panel-darken > .panel-heading {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
}

.panel-pink {
  border-color: #ac5287;
}

.panel-pink > .panel-heading {
  color: #fff;
  background-color: #ac5287;
  border-color: #ac5287;
}

.panel-green {
  border-color: #356e35;
}

.panel-green > .panel-heading {
  color: #fff;
  background-color: #356e35;
  border-color: #356e35;
}

.panel-blueLight {
  border-color: #92a2a8;
}

.panel-blueLight > .panel-heading {
  color: #fff;
  background-color: #92a2a8;
  border-color: #92a2a8;
}

.panel-pinkDark {
  border-color: #a8829f;
}

.panel-pinkDark > .panel-heading {
  color: #fff;
  background-color: #a8829f;
  border-color: #a8829f;
}

.panel-redLight {
  border-color: #a65858;
}

.panel-redLight > .panel-heading {
  color: #fff;
  background-color: #a65858;
  border-color: #a65858;
}

.panel-red {
  border-color: #a90329;
}

.panel-red > .panel-heading {
  color: #fff;
  background-color: #a90329;
  border-color: #a90329;
}

.panel-teal {
  border-color: #568a89;
}

.panel-teal > .panel-heading {
  color: #fff;
  background-color: #568a89;
  border-color: #568a89;
}

.panel-orange {
  border-color: #c79121;
}

.panel-orange > .panel-heading {
  color: #fff;
  background-color: #c79121;
  border-color: #c79121;
}

.panel-blueDark {
  border-color: #4c4f53;
}

.panel-blueDark > .panel-heading {
  color: #fff;
  background-color: #4c4f53;
  border-color: #4c4f53;
}

.panel-magenta {
  border-color: #6e3671;
}

.panel-magenta > .panel-heading {
  color: #fff;
  background-color: #6e3671;
  border-color: #6e3671;
}

.panel-blue {
  border-color: #57889c;
}

.panel-blue > .panel-heading {
  color: #fff;
  background-color: #57889c;
  border-color: #57889c;
}

.panel-footer > .btn-block {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.btn-circle {
  text-align: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 18px;
}

.btn-circle.btn-lg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 30px;
}

.btn-circle.btn-xl {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 10px 15px;
  font-size: 24px;
  line-height: 50px;
}

.btn-metro {
  margin: 0 0 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-metro > span {
  vertical-align: bottom;
  text-transform: uppercase;
  margin-top: 10px;
  display: block;
}

.btn-metro > span.label {
  position: absolute;
  top: 0;
  right: 0;
}

.btn-label {
  background: #00000026;
  border-radius: 3px 0 0 3px;
  padding: 6px 12px;
  display: inline-block;
  position: relative;
  left: -12px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-link {
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 14px;
}

#left-panel {
  transition: all .25s cubic-bezier(.1, .57, .1, 1);
  -webkit-transform: translate(0)translateZ(0);
}

.chart-small, .chart-large, .chart-xl {
  overflow: hidden;
}

.chart-small {
  width: 100%;
  height: 100px;
}

.chart-large {
  width: 100%;
  height: 235px;
}

.chart-xl {
  width: 100%;
  height: 297px;
}

.flotTip {
  z-index: 9999;
  color: #333;
  -khtml-border-radius: 4px;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  box-shadow: 0 1px 8px #0000001a;
}

.flotTip span {
  color: #38812d;
  font-weight: bold;
}

.legendLabel span {
  margin: 0 5px;
  display: block;
}

.legendColorBox {
  vertical-align: top;
  padding-top: 5px;
  padding-left: 10px;
}

.legendColorBox div > div {
  box-sizing: content-box;
  border-radius: 50%;
  width: 4px;
  height: 4px;
}

.morris-hover.morris-default-style {
  color: #666;
  text-align: center;
  background: #fffc;
  border: 2px solid #e6e6e6cc;
  border-radius: 10px;
  padding: 6px;
  font-family: sans-serif;
  font-size: 12px;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  margin: .25em 0;
  font-weight: bold;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: .1em 0;
}

.morris-hover {
  z-index: 903;
  position: absolute;
}

.fixed-page-footer .morris-hover {
  z-index: 900;
}

.chart {
  height: 220px;
  margin: 20px 5px 10px 10px;
  overflow: hidden;
}

.has-legend {
  margin-top: 30px !important;
}

.has-legend-unique {
  margin-top: 19px !important;
}

.icon-color-good {
  color: #40ac2b;
}

.icon-color-bad {
  color: #ed1c24;
}

.percent-sign:after, .degree-sign:after {
  content: " %";
  line-height: normal;
}

.degree-sign:after {
  content: " Â°F";
}

.hidden-menu #left-panel {
  z-index: 903;
  left: -210px;
}

.hidden-menu #main {
  margin-left: 10px;
}

.hidden-menu #left-panel:hover {
  left: 0;
}

.hidden-menu .minifyme, .minified #hide-menu {
  display: none;
}

.btn-header.pull-right {
  margin-left: 6px;
}

.btn-header a > span {
  height: 30px;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  display: inline-block;
}

.btn-header > :first-child > a {
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  color: #6d6a69;
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  min-width: 30px;
  height: 30px;
  margin: 10px 0 0;
  padding: 2px;
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  cursor: default !important;
  text-decoration: none !important;
}

.btn-header > :first-child > a:hover {
  color: #222;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  transition: all;
  box-shadow: inset 0 0 4px 1px #00000014;
}

.btn-header > :first-child > a:active {
  background-color: #e8e8e8;
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -ms-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#e8e8e8", endColorstr= "#ededed", GradientType= 0);
  background-image: -moz-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: linear-gradient(#e8e8e8 0%, #ededed 100%);
  box-shadow: inset 0 0 3px 1px #00000026;
}

.hidden-menu #hide-menu > :first-child > a {
  background-color: #e8e8e8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5a5a5a), to(#686868));
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#5A5A5A", endColorstr= "#686868", GradientType= 0);
  color: #fff;
  background-image: -moz-linear-gradient(top, #5a5a5a 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5a5a5a), to(#686868));
  background-image: linear-gradient(#5a5a5a 0%, #686868 100%);
  border-color: #494949;
  box-shadow: inset 0 0 3px 1px #00000026;
}

.hidden-menu #hide-menu > :first-child > a:active, .full-screen #fullscreen > :first-child > a:active {
  box-shadow: inset 0 0 6px 1px #0003;
}

.ajax-dropdown > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}

.ajax-dropdown {
  z-index: 905;
  background: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  width: 344px;
  height: 435px;
  padding: 10px;
  display: none;
  position: absolute;
  top: 48px;
  left: 16px;
  box-shadow: 0 2px 4px #1e1e6440;
}

.ajax-dropdown:after, .ajax-dropdown:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
}

.ajax-dropdown:after {
  border-width: 7px;
  border-color: #fff0 #fff0 #fff;
  margin-left: -7px;
  left: 50%;
}

.ajax-dropdown:before {
  border-width: 8px;
  border-color: #83838300 #83838300 #838383;
  margin-left: -8px;
  left: 50%;
}

.ajax-dropdown .btn-group .btn {
  text-transform: capitalize;
  font-weight: bold;
}

.ajax-dropdown .btn-group > :nth-child(2) {
  border-left-width: 0;
  border-right-width: 0;
}

.ajax-dropdown .btn-group > :last-child {
  border-right-width: 1px !important;
}

.ajax-dropdown .btn-group .btn:active {
  top: 0;
  left: 0;
}

.ajax-notifications {
  background: #e9e9e9;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  height: 365px;
  margin-left: -10px;
  margin-right: -10px;
  display: block;
  overflow: auto;
}

.ajax-notifications .alert.alert-transparent {
  color: #757575;
  background-color: #0000;
  border-color: #0000;
  margin: 13px 10px;
}

.ajax-notifications .alert > :first-child {
  margin-bottom: 10px;
  font-weight: 400;
}

.ajax-dropdown > :last-child {
  padding: 5px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  display: block;
}

.ajax-dropdown .fa-4x.fa-border {
  text-align: center;
  color: #d1d1d1;
  border-width: 3px;
  border-color: #d1d1d1;
  border-radius: 50%;
  width: 46px;
  margin: 0 auto;
  display: block;
}

.dropdown-large {
  position: static !important;
}

.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0;
}

.dropdown-menu-large > li > ul {
  margin: 0;
  padding: 0;
}

.dropdown-menu-large > li > ul > li {
  list-style: none;
}

.dropdown-menu-large > li > ul > li > a {
  clear: both;
  color: #333;
  white-space: normal;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu-large > li ul > li > a:hover, .dropdown-menu-large > li ul > li > a:focus {
  color: #262626;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu-large .disabled > a, .dropdown-menu-large .disabled > a:hover, .dropdown-menu-large .disabled > a:focus {
  color: #999;
}

.dropdown-menu-large .disabled > a:hover, .dropdown-menu-large .disabled > a:focus {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  cursor: not-allowed;
  background-color: #0000;
  background-image: none;
  text-decoration: none;
}

.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}

@media (width <= 768px) {
  .dropdown-menu-large {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }

  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }

  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}

.pagination-xs > li > a, .pagination-xs > li > span {
  padding: 4px 9px;
  font-size: 12px;
}

.notification-body, .notification-body:before, .notification-body:after {
  box-sizing: border-box !important;
}

.notification-body {
  margin: 0;
  padding: 0 0 0 3px;
  list-style: none;
}

.notification-body > li {
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}

.notification-body > li > span {
  white-space: normal;
  background: #fff;
  min-height: 25px;
  padding: 8px 10px 13px;
  display: block;
  overflow: hidden;
}

.notification-body > li > span:hover {
  color: #667f8f;
  background: #f0f4f7;
}

.notification-body > li a.msg {
  padding-left: 50px !important;
}

.notification-body > li a, .notification-body > li a span {
  display: block;
  position: relative;
  overflow: hidden;
}

.notification-body > li a, .notification-body > li a:hover {
  text-decoration: none;
}

.notification-body > li:hover .progress {
  box-shadow: 0 1px #0000, inset 0 0 0 1px #a7a7a7;
  -webkit-box-shadow: 0 1px #0000, inset 0 0 0 1px #ccc;
  background: #fff;
  -moz-box-shadow: 0 1px #0000, inset 0 0 0 1px #fff;
}

.notification-body > li:hover .text-muted {
  color: #333;
}

.notification-body .from {
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  margin-bottom: 6px;
  margin-right: 60px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  overflow: hidden;
}

.notification-body .unread .from {
  font-weight: bold;
}

.notification-body .unread {
  background: #ffffe0;
}

.notification-body time {
  color: #058dc7;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  top: 3px;
  right: 0;
}

.notification-body .msg-body, .notification-body .subject {
  color: #a0a0a0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-height: 35px;
  font-size: 13px;
  overflow: hidden;
}

.notification-body .subject {
  color: #333;
  max-height: 22px;
  font-size: 13px;
}

.bootstrapWizard {
  width: 100%;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

.bootstrapWizard a:hover, .bootstrapWizard a:active, .bootstrapWizard a:focus {
  text-decoration: none;
}

.bootstrapWizard li {
  float: left;
  text-align: center;
  width: 25%;
  padding-left: 0;
  display: block;
}

.bootstrapWizard li:before {
  content: "";
  z-index: 1;
  border-top: 3px solid #55606e;
  width: 100%;
  font-size: 0;
  display: block;
  position: relative;
  top: 11px;
  right: 1px;
  overflow: hidden;
}

.bootstrapWizard li:first-child:before {
  max-width: 50%;
  left: 50%;
}

.bootstrapWizard li:last-child:before {
  width: 50%;
  max-width: 50%;
}

.bootstrapWizard li.complete .step {
  background: #0aa66e;
  border: 3px solid #55606e;
  padding: 1px 6px;
}

.bootstrapWizard li .step i {
  font-size: 10px;
  font-weight: normal;
  position: relative;
  top: -1.5px;
}

.bootstrapWizard li .step {
  color: #fff;
  text-align: center;
  z-index: 2;
  background: #b2b5b9;
  border: 3px solid #0000;
  border-radius: 50%;
  padding: 7px 13px;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  transition: all .1s linear;
  display: inline;
  position: relative;
}

.bootstrapWizard li.active .step, .bootstrapWizard li.active.complete .step {
  color: #fff;
  background: #0091d9;
  border: 3px solid #55606e;
  border-radius: 50%;
  padding: 7px 13px;
  font-size: 15px;
  font-weight: bold;
}

.bootstrapWizard li.complete .title, .bootstrapWizard li.active .title {
  color: #2b3d53;
}

.bootstrapWizard li .title {
  color: #bfbfbf;
  table-layout: fixed;
  text-align: center;
  word-wrap: break-word;
  z-index: 104;
  max-width: 100%;
  font-size: 13px;
  line-height: 15px;
  display: block;
  position: relative;
  top: 20px;
}

.wizard-actions {
  width: 100%;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

.wizard-actions li {
  display: inline;
}

.tab-content.transparent {
  background-color: #0000;
}

.fuelux .wizard {
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 4px #0001;
}

.fuelux .wizard:before, .fuelux .wizard:after {
  content: "";
  line-height: 0;
  display: table;
}

.fuelux .wizard:after {
  clear: both;
}

.fuelux .wizard ul {
  width: 4000px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.fuelux .wizard ul.previous-disabled li.complete {
  cursor: default;
}

.fuelux .wizard ul.previous-disabled li.complete:hover {
  color: #356e35;
  cursor: default;
  background: #f3f4f5;
}

.fuelux .wizard ul.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}

.fuelux .wizard ul li {
  float: left;
  color: #999;
  cursor: default;
  background: #ededed;
  height: 46px;
  margin: 0;
  padding: 0 20px 0 30px;
  font-size: 16px;
  line-height: 46px;
  position: relative;
}

.fuelux .wizard ul li .chevron {
  z-index: 1;
  border: 24px solid #0000;
  border-left: 14px solid #d4d4d4;
  border-right: 0;
  display: block;
  position: absolute;
  top: 0;
  right: -14px;
}

.fuelux .wizard ul li .chevron:before {
  content: "";
  border: 24px solid #0000;
  border-left: 14px solid #ededed;
  border-right: 0;
  display: block;
  position: absolute;
  top: -24px;
  right: 1px;
}

.fuelux .wizard ul li.complete {
  color: #468847;
  background: #f3f4f5;
}

.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: #e7eff8;
}

.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}

.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}

.fuelux .wizard ul li.active {
  color: #3a87ad;
  background: #f1f6fc;
}

.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}

.fuelux .wizard ul li .badge {
  margin-right: 8px;
}

.fuelux .wizard ul li:first-child {
  border-radius: 4px 0 0 4px;
  padding-left: 20px;
}

.fuelux .wizard .actions {
  z-index: 2;
  float: right;
  vertical-align: middle;
  background-color: #eee;
  border-left: 1px solid #d4d4d4;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 46px;
  position: absolute;
  right: 0;
}

.fuelux .wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}

.fuelux .wizard .actions .btn-prev i {
  margin-right: 5px;
}

.fuelux .wizard .actions .btn-next i {
  margin-left: 5px;
}

.fuelux .step-content .step-pane {
  display: none;
}

.fuelux .step-content .active {
  display: block;
}

.fuelux .step-content .active .btn-group .active {
  display: inline-block;
}

.jarvismetro-tile {
  float: left;
  cursor: pointer;
  color: #fff;
  font-smooth: always;
  background-color: #fff;
  border: 1px dotted #c5c5c5;
  width: 100px;
  height: 70px;
  margin: 0 10px 20px 0;
  padding: 5px;
  font-weight: 300;
  text-decoration: none;
  display: block;
  position: relative;
  box-shadow: inset 0 0 1px #ffc;
}

.jarvismetro-tile:hover {
  z-index: 10;
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}

.jarvismetro-tile.big-cubes {
  width: 120px;
  height: 120px;
}

.jarvismetro-tile.double {
  width: 249px;
}

.jarvismetro-tile:active {
  top: 1px;
  left: 1px;
}

.jarvismetro-tile .iconbox {
  text-align: center;
}

.jarvismetro-tile .iconbox i {
  height: 75px;
  margin: 15px auto 0;
  display: block;
}

.jarvismetro-tile .iconbox span {
  text-align: left;
  display: block;
}

.jarvismetro-tile .iconbox span > span {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  bottom: -3px;
  right: -3px;
}

#shortcut .selected {
  position: relative;
  border: 1px solid #0091d9 !important;
}

#shortcut .selected:before {
  content: "";
  color: #fff;
  z-index: 2;
  font-family: "Font Awesome 5 Pro";
  display: block;
  position: absolute;
  top: 3px;
  right: 4px;
}

#shortcut .selected:after {
  content: "";
  z-index: 1;
  border-top: 35px solid #0091d9;
  border-left: 35px solid #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

#shortcut {
  z-index: 907;
  color: #fff;
  box-sizing: border-box;
  background-color: #000000d9;
  width: 100%;
  height: auto;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#shortcut ul {
  border-bottom: 1px solid #423f3f;
  margin: 0;
  padding: 15px 20px 10px;
  list-style: none;
  box-shadow: 0 4px 10px #0000004d;
}

#shortcut ul li {
  width: auto;
  display: inline-block;
}

#shortcut ul li .jarvismetro-tile {
  border: none;
  border-radius: 0;
  margin: 0 3px 3px;
}

#shortcut ul li .jarvismetro-tile:hover {
  color: #fff;
  text-decoration: none;
}

#shortcut ul li .jarvismetro-tile:active, #shortcut ul li .jarvismetro-tile:focus {
  top: 0;
  left: 0;
}

.shortcut-on #response-btn {
  display: none !important;
}

.shortcut-on #main .navbar, .shortcut-on #left-bar .navbar {
  border: none !important;
}

.arrow-box-up:after, .arrow-box-up:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
}

.arrow-box-up:after {
  border-width: 7px;
  border-color: #fff0 #fff0 #fff;
  margin-left: -7px;
  left: 50%;
}

.arrow-box-up:before {
  border-width: 8px;
  border-color: #83838300 #83838300 #838383;
  margin-left: -8px;
  left: 50%;
}

.arrow-box-up-right:after, .arrow-box-up-right:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
}

.arrow-box-up-right:after {
  border-width: 7px;
  border-color: #fff0 #fff0 #fff;
  margin-left: -7px;
  right: 10px;
}

.arrow-box-up-right:before {
  border-width: 8px;
  border-color: #83838300 #83838300 #838383;
  margin-left: -8px;
  right: 9px;
}

.tooltip-inner {
  border-radius: 0;
  padding: 5px 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  width: 10px;
  height: 10px;
}

.custom-scroll::-webkit-scrollbar:hover {
  background-color: #e9e9e9;
  border: 1px solid #dbdbdb;
}

.custom-scroll::-webkit-scrollbar-button:start:decrement {
  background: none;
  height: 0;
  display: block;
}

.custom-scroll::-webkit-scrollbar-button:end:increment {
  background: none;
  height: 0;
  display: block;
}

.custom-scroll::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid #0000;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background-color: #0000;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #0003;
  background-clip: padding-box;
  border: none;
  box-shadow: inset 1px 1px #0000001a, inset 0 -1px #00000012;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical {
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background-color: #bfbfbf;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb:horizontal {
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background-color: #bfbfbf;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  background-color: #00000070;
  box-shadow: inset 1px 1px 3px #00000054;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #959595;
}

.twitter-typeahead .tt-query, .twitter-typeahead .tt-hint {
  height: 32px;
  margin-bottom: 0;
  padding: 6px 12px;
}

.tt-dropdown-menu {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #0003;
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  box-shadow: 0 5px 10px #0003;
}

.tt-suggestion {
  padding: 3px 20px;
  display: block;
}

.tt-suggestion.tt-is-under-cursor {
  color: #fff;
  background-color: #0081c2;
}

.tt-suggestion.tt-is-under-cursor a {
  color: #fff;
}

.tt-suggestion p {
  margin: 0;
}

.google_maps {
  width: 100%;
  height: 350px;
  position: relative;
}

.google_maps * {
  box-sizing: content-box;
}

.google_maps img {
  max-width: none;
}

.header-btn {
  margin-top: 5px;
}

.cke_top {
  background: #f8f8f8e6 !important;
}

.multiselect-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
}

.multiselect-container .input-group {
  margin: 5px;
}

.multiselect-container > li {
  padding: 0;
}

.multiselect-container > li > a.multiselect-all label {
  font-weight: bold;
}

.multiselect-container > li > label.multiselect-group {
  height: 100%;
  margin: 0;
  padding: 3px 20px;
  font-weight: bold;
}

.multiselect-container > li > a > label {
  cursor: pointer;
  height: 100%;
  margin: 0;
  font-weight: normal;
}

.multiselect-container > li > a > label.radio, .multiselect-container > li > a > label.checkbox {
  margin: 0;
}

.multiselect-container > li > a > label > input[type="checkbox"] {
  margin-bottom: 5px;
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.input-group .icon-addon .form-control {
  border-radius: 0;
}

.icon-addon {
  color: #555;
  display: block;
  position: relative;
}

.icon-addon:after, .icon-addon:before {
  content: " ";
  display: table;
}

.icon-addon:after {
  clear: both;
}

.icon-addon.addon-md .glyphicon, .icon-addon .glyphicon, .icon-addon.addon-md .fa, .icon-addon .fa {
  z-index: 2;
  text-align: center;
  width: 20px;
  margin-left: -2.5px;
  padding: 10px 0;
  position: absolute;
  top: 1px;
  left: 10px;
}

.icon-addon.addon-lg .form-control {
  height: 46px;
  padding: 10px 16px 10px 40px;
  font-size: 18px;
  line-height: 1.33;
}

.input-group-lg .icon-addon.addon-lg + .input-group-btn > .btn {
  height: 46px;
}

.icon-addon.addon-sm .form-control {
  height: 30px;
  padding: 5px 10px 5px 28px;
  font-size: 12px;
  line-height: 1.5;
}

.icon-addon.addon-lg .fa, .icon-addon.addon-lg .glyphicon {
  margin-left: 0;
  font-size: 18px;
  top: 4px;
  left: 11px;
}

.icon-addon.addon-md .form-control, .icon-addon .form-control {
  float: left;
  padding-left: 30px;
  font-weight: normal;
}

.icon-addon.addon-sm .fa, .icon-addon.addon-sm .glyphicon {
  margin-left: 0;
  font-size: 12px;
  top: -1px;
  left: 5px;
}

.icon-addon .form-control:focus + .glyphicon, .icon-addon:hover .glyphicon, .icon-addon .form-control:focus + .fa, .icon-addon:hover .fa {
  color: #2580db;
}

.icon-addon input:disabled + .glyphicon, .icon-addon select:disabled + .glyphicon, .icon-addon input:disabled + .fa, .icon-addon select:disabled + .fa {
  color: #ccc;
}

.progress-micro {
  height: 3px !important;
  line-height: 3px !important;
}

.progress-xs {
  height: 7px !important;
  line-height: 7px !important;
}

.progress-sm {
  height: 14px !important;
  line-height: 14px !important;
}

.progress-lg {
  height: 30px !important;
  line-height: 30px !important;
}

.progress .progress-bar {
  line-height: 20px;
  position: absolute;
  overflow: hidden;
}

.progress .progressbar-back-text {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
}

.progress .progressbar-front-text {
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

.progress.right .progress-bar {
  right: 0;
}

.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}

.progress.vertical {
  width: 25px;
  height: 100%;
  min-height: 150px;
  margin-bottom: 0;
  margin-right: 20px;
  display: inline-block;
}

.progress.wide-bar {
  width: 40px;
}

.progress.vertical.bottom {
  position: relative;
}

.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}

.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  transition: height .6s;
}

.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}

.progress {
  background: #eee;
  border-radius: 0;
  height: 22px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px #0000, inset 0 0 0 1px #e3e3e3;
}

.progress-bar {
  float: left;
  color: #fff;
  text-align: center;
  text-shadow: 0 -1px #00000040;
  background-color: #57889c;
  background-image: url("overlay-pattern.64d20abd.png");
  width: 0;
  height: 100%;
  font-size: 11px;
  font-weight: bold;
  box-shadow: inset 0 -1px #00000026;
}

.progress-striped .progress-bar {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

.progress.active .progress-bar {
  -ms-animation: progress-bar-stripes 2s linear infinite;
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-bar-danger {
  background-color: #a90329;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-success {
  background-color: #739e73;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-warning {
  background-color: #c79121;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-info {
  background-color: #57889c;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-info .bar, .progress .bar-info {
  background: #57889c;
}

.vertical-bars {
  margin: 0;
  padding: 0;
}

.vertical-bars:after {
  content: "";
  clear: both;
  height: 0;
  display: block;
}

.vertical-bars li {
  float: left;
  text-align: center;
  width: 25%;
  padding: 14px 0;
  display: block;
}

.vertical-bars li:first-child {
  border-left: none;
}

.vertical-bars > li > .progress.vertical:first-child {
  margin-left: auto;
}

.vertical-bars > li > .progress.vertical {
  float: none;
  margin: 0 auto;
}

.bar-holder:first-child {
  border-top: none;
}

.bar-holder {
  padding: 18px 15px;
}

.bar-holder .progress {
  margin: 0;
}

[data-progressbar-value] {
  margin-bottom: 0;
  overflow: visible;
  margin-top: 15px !important;
}

.dataTable [data-progressbar-value] {
  min-width: 70px;
}

[data-progressbar-value="100"]:before, [data-progressbar-value="100"]:after, [data-progressbar-value]:before, [data-progressbar-value]:after {
  background: none;
  font-size: 11px;
  position: absolute;
  top: -12px;
}

[data-progressbar-value]:before {
  content: attr(data-progressbar-value);
  left: attr(data-progressbar-value) px;
}

[data-progressbar-value]:after {
  content: "%";
  left: 13px;
}

[data-progressbar-value="1"] > .progress-bar {
  background: #a90329;
  width: 1%;
}

[data-progressbar-value="2"] > .progress-bar {
  background: #a90329;
  width: 2%;
}

[data-progressbar-value="3"] > .progress-bar {
  background: #a90329;
  width: 3%;
}

[data-progressbar-value="4"] > .progress-bar {
  background: #a90329;
  width: 4%;
}

[data-progressbar-value="5"] > .progress-bar {
  background: #a90329;
  width: 5%;
}

[data-progressbar-value="6"] > .progress-bar {
  background: #a90329;
  width: 6%;
}

[data-progressbar-value="7"] > .progress-bar {
  background: #a90329;
  width: 7%;
}

[data-progressbar-value="8"] > .progress-bar {
  background: #a90329;
  width: 8%;
}

[data-progressbar-value="9"] > .progress-bar {
  background: #a90329;
  width: 9%;
}

[data-progressbar-value="10"] > .progress-bar {
  background: #db0435;
  width: 10%;
}

[data-progressbar-value="11"] > .progress-bar {
  background: #c79121;
  width: 11%;
}

[data-progressbar-value="12"] > .progress-bar {
  background: #c79121;
  width: 12%;
}

[data-progressbar-value="13"] > .progress-bar {
  background: #c79121;
  width: 13%;
}

[data-progressbar-value="14"] > .progress-bar {
  background: #c79121;
  width: 14%;
}

[data-progressbar-value="15"] > .progress-bar {
  background: #c79121;
  width: 15%;
}

[data-progressbar-value="16"] > .progress-bar {
  background: #c79121;
  width: 16%;
}

[data-progressbar-value="17"] > .progress-bar {
  background: #c79121;
  width: 17%;
}

[data-progressbar-value="18"] > .progress-bar {
  background: #c79121;
  width: 18%;
}

[data-progressbar-value="19"] > .progress-bar {
  background: #c79121;
  width: 19%;
}

[data-progressbar-value="20"] > .progress-bar {
  background: #c79121;
  width: 20%;
}

[data-progressbar-value="21"] > .progress-bar {
  background: #c79121;
  width: 21%;
}

[data-progressbar-value="22"] > .progress-bar {
  background: #c79121;
  width: 22%;
}

[data-progressbar-value="23"] > .progress-bar {
  background: #c79121;
  width: 23%;
}

[data-progressbar-value="24"] > .progress-bar {
  background: #c79121;
  width: 24%;
}

[data-progressbar-value="25"] > .progress-bar {
  background: #c79121;
  width: 25%;
}

[data-progressbar-value="26"] > .progress-bar {
  background: #c79121;
  width: 26%;
}

[data-progressbar-value="27"] > .progress-bar {
  background: #c79121;
  width: 27%;
}

[data-progressbar-value="28"] > .progress-bar {
  background: #c79121;
  width: 28%;
}

[data-progressbar-value="29"] > .progress-bar {
  background: #c79121;
  width: 29%;
}

[data-progressbar-value="30"] > .progress-bar {
  background: #c79121;
  width: 30%;
}

[data-progressbar-value="31"] > .progress-bar {
  background: #c79121;
  width: 31%;
}

[data-progressbar-value="32"] > .progress-bar {
  background: #c79121;
  width: 32%;
}

[data-progressbar-value="33"] > .progress-bar {
  background: #c79121;
  width: 33%;
}

[data-progressbar-value="34"] > .progress-bar {
  background: #c79121;
  width: 34%;
}

[data-progressbar-value="35"] > .progress-bar {
  background: #c79121;
  width: 35%;
}

[data-progressbar-value="36"] > .progress-bar {
  background: #c79121;
  width: 36%;
}

[data-progressbar-value="37"] > .progress-bar {
  background: #c79121;
  width: 37%;
}

[data-progressbar-value="38"] > .progress-bar {
  background: #c79121;
  width: 38%;
}

[data-progressbar-value="39"] > .progress-bar {
  background: #c79121;
  width: 39%;
}

[data-progressbar-value="40"] > .progress-bar {
  background: #c79121;
  width: 40%;
}

[data-progressbar-value="41"] > .progress-bar {
  background: #404040;
  width: 41%;
}

[data-progressbar-value="42"] > .progress-bar {
  background: #404040;
  width: 42%;
}

[data-progressbar-value="43"] > .progress-bar {
  background: #404040;
  width: 43%;
}

[data-progressbar-value="44"] > .progress-bar {
  background: #404040;
  width: 44%;
}

[data-progressbar-value="45"] > .progress-bar {
  background: #404040;
  width: 45%;
}

[data-progressbar-value="46"] > .progress-bar {
  background: #404040;
  width: 46%;
}

[data-progressbar-value="47"] > .progress-bar {
  background: #404040;
  width: 47%;
}

[data-progressbar-value="48"] > .progress-bar {
  background: #404040;
  width: 48%;
}

[data-progressbar-value="49"] > .progress-bar {
  background: #404040;
  width: 49%;
}

[data-progressbar-value="50"] > .progress-bar {
  background: #404040;
  width: 50%;
}

[data-progressbar-value="51"] > .progress-bar {
  background: #404040;
  width: 51%;
}

[data-progressbar-value="52"] > .progress-bar {
  background: #404040;
  width: 52%;
}

[data-progressbar-value="53"] > .progress-bar {
  background: #404040;
  width: 53%;
}

[data-progressbar-value="54"] > .progress-bar {
  background: #404040;
  width: 54%;
}

[data-progressbar-value="55"] > .progress-bar {
  background: #404040;
  width: 55%;
}

[data-progressbar-value="56"] > .progress-bar {
  background: #404040;
  width: 56%;
}

[data-progressbar-value="57"] > .progress-bar {
  background: #404040;
  width: 57%;
}

[data-progressbar-value="58"] > .progress-bar {
  background: #404040;
  width: 58%;
}

[data-progressbar-value="59"] > .progress-bar {
  background: #404040;
  width: 59%;
}

[data-progressbar-value="60"] > .progress-bar {
  background: #404040;
  width: 60%;
}

[data-progressbar-value="61"] > .progress-bar {
  background: #3276b1;
  width: 61%;
}

[data-progressbar-value="62"] > .progress-bar {
  background: #3276b1;
  width: 62%;
}

[data-progressbar-value="63"] > .progress-bar {
  background: #3276b1;
  width: 63%;
}

[data-progressbar-value="64"] > .progress-bar {
  background: #3276b1;
  width: 64%;
}

[data-progressbar-value="65"] > .progress-bar {
  background: #3276b1;
  width: 65%;
}

[data-progressbar-value="66"] > .progress-bar {
  background: #3276b1;
  width: 66%;
}

[data-progressbar-value="67"] > .progress-bar {
  background: #3276b1;
  width: 67%;
}

[data-progressbar-value="68"] > .progress-bar {
  background: #3276b1;
  width: 68%;
}

[data-progressbar-value="69"] > .progress-bar {
  background: #3276b1;
  width: 69%;
}

[data-progressbar-value="70"] > .progress-bar {
  background: #3276b1;
  width: 70%;
}

[data-progressbar-value="71"] > .progress-bar {
  background: #3276b1;
  width: 71%;
}

[data-progressbar-value="72"] > .progress-bar {
  background: #3276b1;
  width: 72%;
}

[data-progressbar-value="73"] > .progress-bar {
  background: #3276b1;
  width: 73%;
}

[data-progressbar-value="74"] > .progress-bar {
  background: #3276b1;
  width: 74%;
}

[data-progressbar-value="75"] > .progress-bar {
  background: #3276b1;
  width: 75%;
}

[data-progressbar-value="76"] > .progress-bar {
  background: #3276b1;
  width: 76%;
}

[data-progressbar-value="77"] > .progress-bar {
  background: #3276b1;
  width: 77%;
}

[data-progressbar-value="78"] > .progress-bar {
  background: #3276b1;
  width: 78%;
}

[data-progressbar-value="79"] > .progress-bar {
  background: #3276b1;
  width: 79%;
}

[data-progressbar-value="80"] > .progress-bar {
  background: #3276b1;
  width: 80%;
}

[data-progressbar-value="81"] > .progress-bar {
  background: #a0bda0;
  width: 81%;
}

[data-progressbar-value="82"] > .progress-bar {
  background: #a0bda0;
  width: 82%;
}

[data-progressbar-value="83"] > .progress-bar {
  background: #a0bda0;
  width: 83%;
}

[data-progressbar-value="84"] > .progress-bar {
  background: #9dbb9d;
  width: 84%;
}

[data-progressbar-value="85"] > .progress-bar {
  background: #9dbb9d;
  width: 85%;
}

[data-progressbar-value="86"] > .progress-bar {
  background: #9dbb9d;
  width: 86%;
}

[data-progressbar-value="87"] > .progress-bar {
  background: #97b797;
  width: 87%;
}

[data-progressbar-value="88"] > .progress-bar {
  background: #97b797;
  width: 88%;
}

[data-progressbar-value="89"] > .progress-bar {
  background: #91b391;
  width: 89%;
}

[data-progressbar-value="90"] > .progress-bar {
  background: #91b391;
  width: 90%;
}

[data-progressbar-value="91"] > .progress-bar {
  background: #91b391;
  width: 91%;
}

[data-progressbar-value="92"] > .progress-bar {
  background: #8eb18e;
  width: 92%;
}

[data-progressbar-value="93"] > .progress-bar {
  background: #8baf8b;
  width: 93%;
}

[data-progressbar-value="94"] > .progress-bar {
  background: #88ad88;
  width: 94%;
}

[data-progressbar-value="95"] > .progress-bar {
  background: #85ab85;
  width: 95%;
}

[data-progressbar-value="96"] > .progress-bar {
  background: #82a882;
  width: 96%;
}

[data-progressbar-value="97"] > .progress-bar {
  background: #7fa67f;
  width: 97%;
}

[data-progressbar-value="98"] > .progress-bar {
  background: #7ca47c;
  width: 98%;
}

[data-progressbar-value="99"] > .progress-bar {
  background: #79a279;
  width: 99%;
}

[data-progressbar-value="100"] > .progress-bar {
  -ms-animation: none;
  background: #739e73;
  width: 100%;
  animation: none;
  background-image: none !important;
}

[data-progressbar-value="100"]:before {
  content: attr(data-progressbar-value);
  top: -12px;
  left: attr(data-progressbar-value) px;
}

[data-progressbar-value="100"]:after {
  content: "complete";
  color: #739e73;
  left: auto;
  right: 0;
}

html.hidden-menu-mobile-lock {
  overflow-x: hidden;
}

.no-content-padding {
  margin: -10px -14px 0;
}

.no-padding {
  padding: 0 !important;
}

.no-padding > pre {
  margin: 30px;
}

.no-padding > table, .no-padding .dataTables_wrapper table {
  border: none !important;
  border-bottom-width: 0 !important;
  margin-bottom: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-gutter {
  padding: 13px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.no-padding > table tr td:last-child, .no-padding > table tr th:last-child, .no-padding .dataTables_wrapper table tr td:last-child, .no-padding .dataTables_wrapper table tr th:last-child {
  border-right: none !important;
}

.no-padding > table tr td:first-child, .no-padding > table tr th:first-child, .no-padding .dataTables_wrapper table tr td:first-child, .no-padding .dataTables_wrapper table tr th:first-child {
  border-left: none !important;
}

.no-padding .dataTables_wrapper table tbody tr:last-child td {
  border-bottom: 0;
}

.semi-bold {
  font-weight: 400 !important;
}

.ultra-light {
  font-weight: 300 !important;
}

.font-xs {
  font-size: 85% !important;
}

.font-sm {
  font-size: 95% !important;
}

.font-md {
  font-size: 130% !important;
}

.font-lg {
  font-size: 160% !important;
}

.font-xl {
  font-size: 200% !important;
}

.font-400 {
  font-size: 400% !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.no-border, .well[class*=" bg-"].no-border {
  border-width: 0 !important;
}

.no-border-transparent {
  border-color: #0000 !important;
}

.no-border-radius {
  border-radius: 0;
}

.display-inline {
  display: inline-block !important;
}

.display-normal {
  display: inline !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-transparent {
  opacity: .5;
}

.hover-transparent:hover {
  opacity: 1;
}

.text-align-center, .text-center {
  text-align: center !important;
}

.text-align-left, .text-left {
  text-align: left !important;
}

.text-align-right, .text-right {
  text-align: right !important;
}

hr.simple {
  border-style: dashed;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.air {
  z-index: 5;
  position: absolute;
}

.air-bottom {
  bottom: 0;
}

.air-top {
  top: 0;
}

.air-top-right {
  top: 0;
  right: 0;
}

.air-top-left {
  top: 0;
  left: 0;
}

.air-bottom-left {
  bottom: 0;
  left: 0;
}

.air-bottom-right {
  bottom: 0;
  right: 0;
}

.display-none {
  display: none !important;
}

.checkbox input[type="checkbox"].checkbox + span, .checkbox-inline input[type="checkbox"].checkbox + span, .radiobox-inline input[type="radio"].radiobox + span, .radio input[type="radio"].radiobox + span {
  margin-left: -20px;
}

.form-inline .checkbox input[type="checkbox"].checkbox + span, .form-inline .radiobox input[type="radio"].radiobox + span {
  margin-left: 0;
}

.form-inline .checkbox, .form-inline .radiobox {
  padding-left: 0;
}

label input[type="radio"].radiobox + span, input[type="checkbox"].checkbox + span {
  vertical-align: middle;
  line-height: 20px;
}

input[type="checkbox"].checkbox + span, input[type="checkbox"].checkbox + span:after, input[type="checkbox"].checkbox + span:before, input[type="radio"].radiobox + span, input[type="radio"].radiobox + span:after, input[type="radio"].radiobox + span:before {
  box-sizing: content-box !important;
}

input[type="checkbox"].checkbox, input[type="radio"].radiobox {
  visibility: hidden;
  z-index: 20;
  width: 18px;
  height: 18px;
  position: absolute;
}

label input[type="checkbox"].checkbox + span, label input[type="radio"].radiobox + span {
  z-index: 19;
  min-width: 14px;
  min-height: 14px;
  margin: 0 5px 0 0;
  line-height: 17px;
  display: inline-block;
  position: relative;
}

label input[type="checkbox"].checkbox + span:hover, label input[type="radio"].radiobox + span:hover {
  cursor: pointer;
}

label input[type="radio"].radiobox + span:before, label input[type="checkbox"].checkbox + span:before {
  content: " ";
  text-align: center;
  vertical-align: middle;
  background-color: #f4f4f4;
  border: 1px solid #bfbfbf;
  border-radius: 0;
  min-width: 12px;
  height: 12px;
  margin-top: -1px;
  margin-right: 5px;
  padding: 1px;
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  display: inline-block;
}

label input[type="radio"].radiobox + span:before {
  content: " ";
  border-radius: 50%;
  box-shadow: inset 0 2px 3px #0000001a, 0 1px #fff3;
}

label input[type="radio"].radiobox:checked + span {
  font-weight: bold;
}

label input[type="radio"].radiobox:checked + span:before {
  content: "";
  color: #2e7bcc;
}

label input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}

label input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
  font-size: 9px;
}

label input[type="radio"].radiobox.style-2:checked + span {
  font-weight: normal;
}

label input[type="radio"].radiobox.style-1 + span:before {
  text-shadow: none;
  background: #fff;
  border-width: 1px;
  min-width: 13px;
  height: 13px;
  font-size: 9px;
  line-height: 13px;
  box-shadow: none !important;
}

label input[type="radio"].radiobox.style-1:checked + span:before {
  color: #333;
  background: #fff;
  border-color: #333;
  font-size: 10px;
}

label input[type="radio"].radiobox.style-1:checked + span {
  font-weight: normal;
}

label:hover input[type="radio"].radiobox + span:before, label:hover input[type="radio"].radiobox:checked + span:before {
  color: #2e7bcc;
  border-color: #2e7bcc;
}

label:hover input[type="radio"].radiobox.style-3 + span:before, label:hover input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}

label:hover input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
}

label:hover input[type="radio"].radiobox.style-1:checked + span:before, label:hover input[type="radio"].radiobox.style-1 + span:before {
  color: #333;
  background: #fff;
  border-color: #333;
}

label:active input[type="radio"].radiobox + span:before {
  box-shadow: inset 0 2px 3px #0000004d, 0 1px #fff6;
}

label input[type="radio"][disabled].radiobox + span, label input[type="radio"][disabled].radiobox:checked + span, label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  color: #a7a7a7;
  cursor: not-allowed;
}

label input[type="radio"][disabled].radiobox:checked + span:before {
  content: "";
  box-shadow: none;
  color: #a7a7a7;
  background: #eee !important;
  border-color: #ccc !important;
}

label input[type="radio"][disabled].radiobox + span:before, label:active input[type="radio"][disabled].radiobox:checked + span:before, label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  box-shadow: none;
  color: #a7a7a7;
  background: #eee !important;
  border-color: #ccc !important;
}

label input[type="checkbox"].checkbox + span:before {
  content: " ";
}

label input[type="checkbox"].checkbox:checked + span:before {
  content: "";
}

label input[type="checkbox"].checkbox:checked + span {
  font-weight: bold;
}

label input[type="checkbox"].checkbox:checked + span:before {
  color: #2e7bcc;
}

label input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #fff;
  background: #a90329;
  border-color: #a90329;
}

label input[type="checkbox"].checkbox.style-2 + span:before {
  box-shadow: none;
  background: none;
  border-color: #c1c1c1;
}

label input[type="checkbox"].checkbox.style-2:checked + span:before {
  content: "";
  color: #66665e;
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQ0MEM4RUFEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQ0MEM4RUJEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDQwQzhFOERBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDQwQzhFOURBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsD/PVAAAAAQSURBVHjaYv7//78vQIABAAlYA05NMPppAAAAAElFTkSuQmCC");
  border-color: #4d90fe;
}

label input[type="checkbox"].checkbox.style-2:checked + span {
  font-weight: normal;
}

label input[type="checkbox"].checkbox.style-1 + span:before {
  color: #333;
  text-shadow: none;
  background: #fff;
  border-width: 1px;
  min-width: 12px;
  height: 12px;
  line-height: 13px;
  box-shadow: none !important;
}

label input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #333;
  border-color: #333;
}

label input[type="checkbox"].checkbox.style-1:checked + span {
  font-weight: normal;
}

label:active input[type="checkbox"].checkbox + span:before {
  box-shadow: inset 0 2px 3px #0000004d, 0 1px #fff6;
}

label:hover input[type="checkbox"].checkbox + span:before {
  color: #2e7bcc;
  border-color: #2e7bcc;
}

label:hover input[type="checkbox"].checkbox.style-3 + span:before {
  color: #fff;
  border-color: #a90327;
}

label:hover input[type="checkbox"].checkbox.style-2 + span:before {
  box-shadow: inset 0 2px 3px #0000001a, 0 1px #fff3;
}

label:hover input[type="checkbox"].checkbox.style-2:checked + span:before {
  box-shadow: inset 0 2px 3px #0000004d, 0 1px #fff6;
}

label:hover input[type="checkbox"].checkbox.style-1 + span:before {
  border-color: #333;
}

label:active input[type="checkbox"].checkbox:checked + span:before {
  color: #fff;
  background: #2e7bcc;
  border-color: #2e7bcc;
}

label:active input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #a90329;
  background: #fff;
  border-color: #a90329;
}

label:active input[type="checkbox"].checkbox.style-2:checked + span:before, label:active input[type="checkbox"].checkbox.style-2 + span:before {
  color: #66665e;
  background: none;
  border-color: #c1c1c1;
  box-shadow: inset 0 2px 3px #0000004d, 0 1px #fff6;
}

label:active input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #fff;
  background: #333;
  border-color: #333;
}

label input[type="checkbox"][disabled].checkbox + span, label input[type="checkbox"][disabled].checkbox:checked + span, label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  color: #a7a7a7;
  cursor: not-allowed;
}

label input[type="checkbox"][disabled].checkbox:checked + span:before {
  content: "";
  color: #a7a7a7;
}

label input[type="checkbox"][disabled].checkbox.style-2:checked + span:before {
  content: "";
}

label input[type="checkbox"][disabled].checkbox:checked + span:before, label input[type="checkbox"][disabled].checkbox + span:before, label:active input[type="checkbox"][disabled].checkbox:checked + span:before, label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  box-shadow: none;
  color: #a7a7a7;
  box-shadow: none !important;
  background: #eee !important;
  border-color: #ccc !important;
}

.open > .dropdown-menu {
  animation-duration: .4s;
  animation-fill-mode: both;
}

.active + .ajax-dropdown {
  animation-name: flipInY;
  animation-duration: .7s;
  animation-fill-mode: both;
}

.square {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: block;
}

[class^="bg-"], [class*=" bg-"] {
  filter: none !important;
}

.txt-color-blue {
  color: #57889c !important;
}

.txt-color-blueLight {
  color: #92a2a8 !important;
}

.txt-color-blueDark {
  color: #4c4f53 !important;
}

.txt-color-green {
  color: #356e35 !important;
}

.txt-color-greenLight {
  color: #71843f !important;
}

.txt-color-greenDark {
  color: #496949 !important;
}

.txt-color-red {
  color: #a90329 !important;
}

.txt-color-yellow {
  color: #b09b5b !important;
}

.txt-color-orange {
  color: #b19a6b !important;
}

.txt-color-orangeDark {
  color: #a57225 !important;
}

.txt-color-pink {
  color: #ac5287 !important;
}

.txt-color-pinkDark {
  color: #a8829f !important;
}

.txt-color-purple {
  color: #6e587a !important;
}

.txt-color-darken {
  color: #404040 !important;
}

.txt-color-lighten {
  color: #d5e7ec !important;
}

.txt-color-white {
  color: #fff !important;
}

.txt-color-grayDark {
  color: #525252 !important;
}

.txt-color-magenta {
  color: #6e3671 !important;
}

.txt-color-teal {
  color: #568a89 !important;
}

.txt-color-redLight {
  color: #a65858 !important;
}

.bg-color-blue {
  background-color: #57889c !important;
}

.bg-color-blueLight {
  background-color: #92a2a8 !important;
}

.bg-color-blueDark {
  background-color: #4c4f53 !important;
}

.bg-color-green {
  background-color: #356e35 !important;
}

.bg-color-greenLight {
  background-color: #71843f !important;
}

.bg-color-greenDark {
  background-color: #496949 !important;
}

.bg-color-red {
  background-color: #a90329 !important;
}

.bg-color-yellow {
  background-color: #b09b5b !important;
}

.bg-color-orange {
  background-color: #c79121 !important;
}

.bg-color-orangeDark {
  background-color: #a57225 !important;
}

.bg-color-pink {
  background-color: #ac5287 !important;
}

.bg-color-pinkDark {
  background-color: #a8829f !important;
}

.bg-color-purple {
  background-color: #6e587a !important;
}

.bg-color-darken {
  background-color: #404040 !important;
}

.bg-color-lighten {
  background-color: #d5e7ec !important;
}

.bg-color-white {
  background-color: #fff !important;
}

.bg-color-grayDark {
  background-color: #525252 !important;
}

.bg-color-magenta {
  background-color: #6e3671 !important;
}

.bg-color-teal {
  background-color: #568a89 !important;
}

.bg-color-redLight {
  background-color: #a65858 !important;
}

.page-footer {
  background: #2a2725;
  border-top: 1px solid #cecece;
  width: 100%;
  height: 52px;
  padding: 15px 13px 0 233px;
  display: block;
  position: absolute;
  bottom: 0;
}

.fixed-page-footer .page-footer {
  z-index: 901;
  position: fixed;
}

.minified .page-footer {
  padding-left: 58px;
}

.container.fixed-page-footer .page-footer {
  max-width: 1164px;
}

.hidden-menu .page-footer {
  padding-left: 20px;
}

@media (width <= 979px) {
  html.hidden-menu-mobile-lock {
    overflow-x: hidden;
  }

  #main {
    width: 100% !important;
  }

  .page-footer {
    padding: 15px 14px 0;
  }

  .profile-pic {
    float: left;
  }

  .profile-pic > img {
    width: 110px;
    margin-right: 10px;
  }

  .profile-pic + div, .profile-pic + div + div {
    padding-left: 30px;
    padding-right: 30px;
  }

  #fullscreen > :first-child > a {
    display: none !important;
  }

  body.container {
    border: none !important;
    width: 100% !important;
  }

  .inbox-data-message > :first-child {
    height: 50px;
    overflow: hidden;
  }

  .show-stats .show-stat-buttons > :first-child {
    padding-right: 13px;
  }

  .show-stats .show-stat-buttons > :last-child {
    padding-left: 13px;
  }

  .inbox-checkbox-triggered > .btn-group .btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .inbox-body .table-wrap {
    padding: 0 5px !important;
  }

  .inbox-nav-bar {
    padding-left: 5px;
    padding-right: 5px;
  }

  .inbox-data-message > :first-child > :first-child {
    font-size: 14px;
    display: block !important;
  }

  .inbox-table-icon > :first-child {
    margin-top: 12px;
  }

  #inbox-table .inbox-table-icon {
    padding-left: 10px !important;
  }

  .header-search.pull-right {
    margin-left: 0;
  }

  .visible-tablet {
    display: inline-block !important;
  }

  .hidden-tablet, .display-inline.hidden-tablet {
    display: none !important;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 310px;
    overflow: hidden;
  }

  .dropzone .dz-default.dz-message {
    background-size: 100%;
    width: 200px;
    height: 43px;
    margin-top: -23.5px;
    margin-left: -112px;
  }
}

@media (width >= 768px) and (width <= 979px) {
  #main {
    margin-left: 0;
  }

  #left-panel {
    left: -220px;
  }

  .minified #left-panel {
    left: 0;
  }

  #hide-menu > :first-child > a {
    width: 40px;
    margin-top: 9px;
  }

  .btn-header a {
    width: 40px !important;
    margin-top: 9px !important;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 100%;
  }

  .hidden-menu #hide-menu i {
    color: #fff;
  }

  #hide-menu i:before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    width: 100%;
    left: 220px;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
  }

  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}

@media (width >= 768px) and (width <= 880px) {
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  .no-content-padding {
    margin: 0 -14px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  .minified #left-panel {
    left: 0;
  }

  #hide-menu > :first-child > a, .btn-header a {
    width: 40px !important;
    height: 39px !important;
    margin-top: 5px !important;
    line-height: 38px !important;
  }

  #logo {
    margin-top: 10px;
  }

  #logo img {
    width: 127px;
  }

  .btn-header.transparent a {
    background: none;
    margin-left: 0;
    box-shadow: none !important;
    border: none !important;
    width: 25px !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #fff;
  }

  #hide-menu i:before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    width: 100%;
    left: 220px;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
  }

  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}

@media (width <= 767px) {
  .profile-pic > img {
    width: 100px;
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  .show-stat-microcharts > div {
    border-right: none !important;
    margin-top: 0 !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  .minified #left-panel {
    left: 0;
  }

  .btn-header.transparent a {
    background: none;
    margin-left: 0;
    box-shadow: none !important;
    border: none !important;
    width: 25px !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu > :first-child > a, .btn-header a {
    width: 40px !important;
    height: 39px !important;
    margin-top: 5px !important;
    line-height: 38px !important;
  }

  #hide-menu > :first-child > a {
    width: 50px !important;
  }

  #logo {
    margin-top: 10px;
  }

  #logo img {
    width: 127px;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #fff;
  }

  #hide-menu i:before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    width: 100%;
    left: 220px;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  body.fixed-navigation.hidden-menu {
    overflow: hidden !important;
  }

  .fixed-ribbon #content {
    padding-top: 52px;
  }

  .header-search {
    display: none;
  }

  #search-mobile {
    display: block;
  }

  .search-mobile .header-search {
    box-sizing: border-box;
    background: #333;
    width: 100%;
    height: 49px;
    padding: 0 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .search-mobile .header-search > input[type="text"] {
    border-color: #333;
    height: 45px;
    margin-top: 2px;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    background: #ddd;
    height: 29px;
    line-height: 29px;
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
  }
}

@media only screen and (width >= 0) and (width <= 679px) {
  .profile-pic > img {
    width: 90px;
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  .show-stat-microcharts > div {
    border-right: none !important;
    margin-top: 0 !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  .minified #left-panel {
    left: 0;
  }

  #header {
    background: #fff;
  }

  #ribbon {
    background: #f5f5f5;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #cfcfcf;
  }

  #ribbon .breadcrumb a, #ribbon .breadcrumb {
    color: #6d6c6c !important;
  }

  #ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }

  #hide-menu > :first-child > a, .btn-header a {
    width: 40px !important;
    height: 39px !important;
    margin-top: 5px !important;
    line-height: 38px !important;
  }

  #hide-menu > :first-child > a {
    width: 50px !important;
  }

  #logo {
    margin-top: 10px;
  }

  #logo img {
    width: 127px;
  }

  .btn-header.transparent a {
    background: none;
    margin-left: 0;
    box-shadow: none !important;
    border: none !important;
    width: 25px !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #fff;
  }

  #hide-menu i:before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    width: 100%;
    left: 220px;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  .header-search {
    display: none;
  }

  #search-mobile {
    display: block;
  }

  .search-mobile .header-search {
    box-sizing: border-box;
    background: #333;
    width: 100%;
    height: 49px;
    padding: 0 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .search-mobile .header-search > input[type="text"] {
    border-color: #333;
    height: 45px;
    margin-top: 2px;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    background: #ddd;
    height: 29px;
    line-height: 29px;
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  .hidden-tablet {
    display: none !important;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
  }
}

@media only screen and (width >= 320px) and (width <= 479px) {
  .page-title {
    display: none;
  }

  .profile-pic {
    text-align: left;
  }

  .profile-pic > img {
    width: 90px;
    margin-left: 0;
    margin-right: 0;
    top: -25px;
  }

  .profile-pic + div > h1 {
    font-size: 20px;
  }

  .profile-pic + div > h1 small {
    font-size: 16px;
  }

  .profile-carousel .carousel-indicators {
    top: 10px;
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  .show-stat-microcharts > div {
    border-right: none !important;
    margin-top: 0 !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  .minified #left-panel {
    left: 0;
  }

  #header {
    background: #fff;
  }

  #ribbon {
    background: #f5f5f5;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #cfcfcf;
  }

  #ribbon .breadcrumb a, #ribbon .breadcrumb {
    color: #6d6c6c !important;
  }

  #ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }

  #logo {
    width: 135px;
  }

  #logo-group {
    width: 169px !important;
  }

  #sparks {
    text-align: center;
    background: #e0e0e0;
    margin-bottom: 15px;
    padding: 10px 0;
  }

  #sparks li {
    padding: 0 10px 0 20px;
  }

  #hide-menu > :first-child > a, .btn-header a {
    width: 40px !important;
    height: 39px !important;
    margin-top: 5px !important;
    line-height: 38px !important;
  }

  #logo {
    margin-top: 10px;
  }

  #logo img {
    width: 127px;
  }

  .btn-header.transparent a {
    background: none;
    margin-left: 0;
    box-shadow: none !important;
    border: none !important;
    width: 25px !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu > :first-child > a {
    width: 46px !important;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #fff;
  }

  #hide-menu i:before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    width: 100%;
    left: 220px;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  .hidden-mobile {
    display: none !important;
  }

  .visible-mobile {
    display: inline-block !important;
  }

  .ajax-notifications {
    height: 250px;
  }

  .ajax-dropdown {
    width: 299px;
    height: 320px;
    top: 49px;
    left: 0;
  }

  .ajax-dropdown:before {
    margin-left: -14px;
  }

  .ajax-dropdown:after {
    margin-left: -13px;
  }

  .header-search {
    display: none;
  }

  #search-mobile {
    margin-left: 0;
    display: block;
  }

  .search-mobile .header-search {
    box-sizing: border-box;
    background: #333;
    width: 100%;
    height: 49px;
    padding: 0 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .search-mobile .header-search > input[type="text"] {
    border-color: #333;
    height: 45px;
    margin-top: 2px;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    background: #ddd;
    height: 29px;
    line-height: 29px;
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  .jarviswidget header h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@-ms-viewport {
}

#extr-page #main {
  margin-top: 0 !important;
}

#mobile-profile-img {
  display: none;
}

.menu-on-top #mobile-profile-img {
  padding-left: 2px !important;
  padding-right: 2px !important;
  display: block !important;
}

.menu-on-top #mobile-profile-img a.userdropdown img {
  border-radius: 3px;
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border: 1px solid #797979 !important;
}

.menu-on-top .page-footer {
  padding: 15px 13px 0;
}

.menu-on-top.hidden-menu #left-panel {
  left: 0;
}

.menu-on-top #main {
  margin-top: 69px !important;
  margin-left: 0 !important;
}

.menu-on-top .minifyme, .menu-on-top #hide-menu, .menu-on-top #logout {
  display: none !important;
}

.menu-on-top aside#left-panel {
  border-top: 1px solid #333;
  border-bottom: 1px solid #222;
  height: auto;
  overflow: visible;
  box-shadow: 0 2px 4px #00000040;
  width: 100% !important;
  min-height: 0 !important;
}

.menu-on-top aside#left-panel nav ul {
  width: 100%;
  display: block;
}

.menu-on-top aside#left-panel nav ul > li {
  float: left;
  border-left: 1px solid #4e4e4e;
  border-right: 1px solid #222;
  display: inline-block;
  position: relative;
  overflow: visible;
  height: auto !important;
  padding: 0 !important;
}

.menu-on-top aside#left-panel nav ul > li > ul {
  background: #3a3633;
  position: absolute !important;
}

.menu-on-top aside#left-panel nav ul > li > a {
  height: 68px;
  font-size: 13px;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  text-shadow: none !important;
  padding: 10px 9px !important;
}

.menu-on-top aside#left-panel nav ul > li > a > i {
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 1px 0 2px;
  font-size: 24px;
  display: block !important;
}

.menu-on-top aside#left-panel nav ul > li:hover {
  background: -o-linear-gradient(top, #cfcfcf 0%, #fff 66%);
  background: -ms-linear-gradient(top, #cfcfcf 0%, #fff 66%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#cfcfcf", endColorstr= "#ffffff", GradientType= 0);
  background: -moz-linear-gradient(top, #cfcfcf 0%, #fff 66%);
  background: -o-linear-gradient(top, #cfcfcf 0%, #fff 66%);
  background: linear-gradient(#cfcfcf 0%, #fff 66%);
}

.menu-on-top aside#left-panel nav ul > li:hover > ul {
  display: block !important;
}

.menu-on-top aside#left-panel nav ul > li:hover > a {
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  color: #333 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.menu-on-top aside#left-panel nav ul li.active > a:before {
  content: "" !important;
}

.menu-on-top aside#left-panel nav ul > li:first-child {
  margin-left: 0;
}

.menu-on-top li.active > a {
  font-weight: bold !important;
}

.menu-on-top .login-info, .menu-on-top nav ul > li > a b {
  display: none;
}

.menu-on-top .menu-item-parent {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 13pxspan;
  font-size-top: 5px;
  font-size-right: 5px;
  font-size-position: absolute;
  min-width: 50px;
  max-width: 72px;
  overflow: hidden;
  font-size-float: none !important;
}

.menu-on-top nav ul ul li a {
  font-size: 13px;
  padding-left: 10px !important;
}

.menu-on-top nav ul ul li:before, .menu-on-top nav ul > li > ul:before {
  border: none;
}

.menu-on-top nav ul ul li a, .menu-on-top nav ul ul ul li a {
  color: #4c4f53;
}

.menu-on-top nav ul ul li a:hover, .menu-on-top nav ul ul ul li a:hover, .menu-on-top nav ul ul li:hover > a {
  color: #fff;
  background-color: #4c4f53;
}

.menu-on-top nav ul ul ul {
  border-top: 1px solid #c7c7c7 !important;
}

.menu-on-top nav ul ul li {
  overflow: visible;
}

.menu-on-top nav ul ul, .menu-on-top nav ul ul ul {
  box-shadow: none;
  border: 1px solid #c7c7c7;
  border-top: none;
  border-bottom-color: #bd2a2a;
  border-radius: 0;
  width: 200px;
  margin-left: 0;
  padding: 3px;
  -webkit-box-shadow: 0 6px 12px #0000002d;
  -moz-box-shadow: 0 6px 12px #0000002d;
  box-shadow: 0 6px 12px #0000002d;
  background: #fff !important;
  display: none !important;
}

.menu-on-top nav ul ul li:hover > ul {
  z-index: 999;
  background: #333;
  position: absolute;
  top: -8px;
  left: 100%;
  display: block !important;
}

.menu-on-top nav ul > li > a:after {
  left: 46%;
  content: "" !important;
  color: #636363 !important;
  top: 76% !important;
}

.menu-on-top nav ul > li > a:after, .menu-on-top nav ul ul > li a:after, .menu-on-top nav ul ul li:hover > a:after, .menu-on-top nav ul ul > li a:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  color: #333;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 9px;
  right: 4px;
  display: block !important;
}

.menu-on-top nav ul ul li:hover > a:after, .menu-on-top nav ul ul > li a:hover:after {
  color: #fff;
}

.menu-on-top nav ul ul > li a:only-child:after, .menu-on-top nav ul > li > a:only-child:after {
  content: "" !important;
}

.menu-on-top nav ul ul .active > a {
  color: #333 !important;
}

.menu-on-top nav ul ul .active > a:hover, .menu-on-top nav ul ul li.active:hover > a {
  color: #fff !important;
}

.menu-on-top .slimScrollDiv, .menu-on-top nav {
  overflow: visible !important;
}

body.menu-on-top.fixed-header {
  padding-top: 49px;
}

body.menu-on-top.fixed-header.fixed-navigation nav ul {
  width: auto;
  height: auto;
  padding-right: 0;
  position: relative;
  overflow: visible;
}

body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
  top: 118px;
  left: 0;
}

@media (width <= 979px) {
  .menu-on-top #mobile-profile-img a.userdropdown img {
    border-radius: 3px;
    width: 39px;
    margin-top: 0;
    margin-left: 2px;
    margin-right: -3px;
  }

  .menu-on-top .btn-header.pull-right {
    margin-left: 0 !important;
  }

  .menu-on-top #main {
    margin-top: 0 !important;
  }

  .menu-on-top #left-panel {
    display: none;
    left: 0;
  }

  body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
    top: 49px;
  }

  .menu-on-top.hidden-menu #left-panel {
    display: block;
  }

  .menu-on-top.hidden-menu #main {
    left: 0 !important;
  }

  .menu-on-top #hide-menu {
    display: block !important;
  }

  .menu-on-top aside#left-panel nav {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    height: 300px !important;
    overflow-x: hidden !important;
  }

  .menu-on-top .menu-item-parent {
    white-space: inherit;
    text-overflow: inherit;
    text-align: left;
    min-width: 85%;
    max-width: 85%;
    padding-left: 5px;
    overflow: visible;
  }

  .menu-on-top .menu-item-parent + span {
    margin-top: -8px;
    top: 50%;
  }

  .menu-on-top nav li.active > ul {
    display: block;
  }

  .menu-on-top nav ul > li.active {
    background: -o-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -ms-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -moz-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -o-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: linear-gradient(#cfcfcf 0, #fff 66%);
  }

  .menu-on-top nav ul > li.active > a {
    color: #333 !important;
  }

  .menu-on-top nav ul ul li:hover > a:after, .menu-on-top nav ul ul > li a:after, .menu-on-top nav ul ul > li a:hover:after, .menu-on-top nav ul > li > a:after {
    margin-top: -8px;
    font-size: 15px;
    top: 50%;
    right: 15px;
    content: "" !important;
  }

  .menu-on-top nav ul ul > li a:only-child:after, .menu-on-top nav ul > li > a:only-child:after {
    content: "" !important;
  }

  .menu-on-top nav ul > li > a:after {
    margin-top: -14px;
    font-size: 20px;
    left: auto;
    top: 50% !important;
  }

  .menu-on-top nav ul ul, .menu-on-top nav ul ul ul {
    border: none;
    padding: 0;
  }

  .menu-on-top nav ul ul ul {
    border-bottom: 2px solid #333;
  }

  .menu-on-top aside#left-panel nav ul > li {
    width: 100%;
  }

  .menu-on-top aside#left-panel nav ul > li > a {
    border-bottom: 1px dotted #585858;
    height: auto;
    display: block;
    padding: 8px 9px !important;
  }

  .menu-on-top nav ul > li > ul > li > a, .menu-on-top nav ul > li > ul > li > ul > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .menu-on-top aside#left-panel nav ul > li > a > i {
    display: inline !important;
  }

  .menu-on-top aside#left-panel nav ul li ul {
    width: 100%;
    top: 0;
    left: 0;
    position: relative !important;
  }

  .menu-on-top aside#left-panel nav ul > li:active > ul, .menu-on-top aside#left-panel nav ul > li:focus > ul {
    display: block !important;
  }
}

.menu-on-top .top-menu-invisible, .no-menu #left-panel, .no-menu #hide-menu, .no-menu #logout {
  display: none !important;
}

.no-menu #main {
  margin: 0 !important;
}

.no-menu #mobile-profile-img {
  display: block !important;
}

.no-menu #mobile-profile-img a.userdropdown img {
  border-radius: 3px;
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border: 1px solid #797979 !important;
}
/*# sourceMappingURL=_login.5ec0ae04.css.map */
